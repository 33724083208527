@import './colors.scss';

/*****************************************************************************
    Variables
******************************************************************************/

$font-family-dm-sans: 'DM Sans', sans-serif;

/* dm-sans-100 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 100;
    src: url('./../../fonts/dm-sans-v15-latin-ext-100.woff2') format('woff2');
}

/* dm-sans-200 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 200;
    src: url('./../../fonts/dm-sans-v15-latin-ext-200.woff2') format('woff2');
}

/* dm-sans-300 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./../../fonts/dm-sans-v15-latin-ext-300.woff2') format('woff2');
}

/* dm-sans-regular - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./../../fonts/dm-sans-v15-latin-ext-regular.woff2') format('woff2');
}

/* dm-sans-500 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./../../fonts/dm-sans-v15-latin-ext-500.woff2') format('woff2');
}

/* dm-sans-600 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./../../fonts/dm-sans-v15-latin-ext-600.woff2') format('woff2');
}

/* dm-sans-700 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./../../fonts/dm-sans-v15-latin-ext-700.woff2') format('woff2');
}

/* dm-sans-800 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./../../fonts/dm-sans-v15-latin-ext-800.woff2') format('woff2');
}

/* dm-sans-900 - latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    src: url('./../../fonts/dm-sans-v15-latin-ext-900.woff2') format('woff2');
}

* {
    font-family: $font-family-dm-sans;
}

/* ********************************************************************************************
    Button
*********************************************************************************************** */

.btn {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 21.7px;
}

a {
    text-decoration: underline;
    color: rgba(45, 62, 80, 1);
}

.btn {
    height: auto;
    padding: 0.8rem;
    line-height: 0;
    vertical-align: middle;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    font-family: $font-family-dm-sans;

    &.btn-primary {
        border-radius: 4px;
    }
}

.btn-group.btn-group-toggle {
    margin-top: 0.5em;

    .btn-secondary {
        cursor: pointer;
        border: 0 !important;
        padding: 0.5em;
        border-radius: 4px;
        border: 1px solid #fff;

        input {
            margin-right: 0.5em;
        }
    }
}

.btn.btn-secondary {
    background: transparent !important;
    border: 1px solid black !important;

    &.show,
    &:hover {
        background-color: $primary !important;
        border: 1px solid $primary !important;
        color: white !important;

        img {
            filter: brightness(0) saturate(100%) invert(100%);
        }
    }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    background-image: url(./../../icons/arrow-down-black.svg) !important;
}

.ng-select.fill .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-opened .ng-arrow {
    background-image: url(./../../icons/arrow-down-white.svg) !important;
}

.ng-select.ng-select-opened:not(.ng-select-multiple) .ng-value-label {
    color: white;
}

app-finance-list {
    .ng-value-container {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    app-finance-list {
        .filter .search-group-wrap>div {
            flex-wrap: wrap;
            row-gap: 20px;

            &>div.m-l-20 {
                margin-left: 0 !important;
            }
        }
    }
}

/* ********************************************************************************************
    Login
*********************************************************************************************** */

.login-box,
.register-box {
    margin-left: auto;
    margin-right: 0;
}

/* ********************************************************************************************
    Form (input, select, checkbox)
*********************************************************************************************** */

.form-control,
ng-select,
.input-group {
    border-radius: 4px !important;
    font-size: 14px;
    padding: 6px 0 6px 0px;
    background-color: #F8F9FA !important;
    border: 1px solid #CED4DA !important;
}

.input-group {
    padding: 0;

    input.form-control {
        border: 0 !important;
    }

    .input-group-append img {
        width: 18px;
    }
}

.form-control {
    text-indent: 0em;
    padding-left: 0.5em !important;
    padding-right: 0.5em;
}

ng-select {
    padding-top: 0;
    padding-bottom: 0;
    text-indent: 0;

    &.ng-select-opened {
        background-color: $primary !important;
        cursor: pointer;
    }

    .ng-select-container {
        padding-left: 1em !important;
    }
}

.ng-select .ng-select-container {
    border: 0 !important;
    padding: 6px 0 6px 0px;

    &.ng-has-value {
        border: 0 !important;
    }
}

.ng-select .ng-select-container {
    border-bottom: 0 !important;
}

.form-group label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21.7px;
    text-align: left;
    color: rgba(45, 62, 80, 1);
}

.form-control:focus {
    box-shadow: none;
}

.form-group,
.input-group {

    .input-group-text,
    .input-group-append span.input-group-text {
        border: 0 !important;
    }
}

.form-group ng-select {
    min-height: 40px;
}

.dropdown-menu input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.control.control-outline {
    margin-bottom: 15px !important;
    cursor: pointer !important;
}

.control.control-outline.control-primary input:checked~.control__indicator {
    border: 1px solid $primary;
    background-color: $primary !important;
}

.control.control-outline.control-primary.control--checkbox .control__indicator:after {
    border-color: white !important;
}

.control.control-outline .control__indicator {
    border-radius: 2px;
    border: 1px solid $primary;
    height: 20px;
    width: 20px;
    top: 0px;
}

.input-group-append span.input-group-text {
    border-bottom: 0 !important;
}

input[mode="range"].flatpickr-input {
    width: 300px !important;
}

input[type="radio"] {
    accent-color: $dark;
}

/* ********************************************************************************************
    FIXME: Remove after removal of main.bundle.scss
*********************************************************************************************** */

.w-100 {
    width: 100% !important;
}

.z-2 {
    z-index: 2;
}

.flexible-width-select {
    .ng-dropdown-panel {
        min-width: max-content;
    }
}

/* ********************************************************************************************
    Containers
*********************************************************************************************** */

.bg-radial {
    background: radial-gradient(84.57% 65.48% at 85.79% 52.15%, rgba(233, 240, 251, 0.6) 0%, rgba(220, 241, 240, 0.6) 59%, rgba(255, 255, 255, 0.6) 100%);
    min-height: calc(100vh - 140px);
}

@media screen and (max-width: 767px) {
    .bg-radial {
        height: auto;
    }

    .content {
        padding-top: 145px !important;
    }
}

/* ********************************************************************************************
    Colors
*********************************************************************************************** */
.bg-grey {
    background-color: #8DA0B4 !important;
    color: white;
}

/* ********************************************************************************************
    Sidebar
*********************************************************************************************** */
body {
    &.mini-sidebar {
        .dropdown-icon {
            display: none;
        }
    }

    &.aside-left-open {
        .dropdown-icon {
            display: block;
        }

        .sidebar.sidebar-left .sidebar-content {
            width: 300px !important;
        }
    }
}

/* ********************************************************************************************
    Select
*********************************************************************************************** */
.custom-select-all {
    background-color: #fff;
}

/* ********************************************************************************************
    Table
*********************************************************************************************** */
.table img.preview-image {
    filter: none !important;
}

/* ********************************************************************************************
    Card
*********************************************************************************************** */

.card.tag-card .card-body {
    border: 1px solid #CED4DA;
    border-radius: 4px;
}

/* ********************************************************************************************
    Typography
*********************************************************************************************** */

@media screen and (max-width: 767px) {
    .breadcrumb-item a {
        font-size: 13px;
    }
}

/* ********************************************************************************************
    Date Picker Overrides
*********************************************************************************************** */
.date-picker-popover.popover {
    width: 100% !important;

    input[mode=range].flatpickr-input {
        width: 100% !important;
    }
}

.date-picker-container {
    .p-3 {
        padding: 1rem !important;
    }

    .border-top {
        padding: 0.75rem !important;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
    }
}