@charset "UTF-8";
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav.metismenu,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}
.btn,
.noUi-target,
.noUi-target *,
a:link.btn {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .attach-list a,
.box {
  word-wrap: break-word;
}
html {
  -webkit-tap-highlight-color: transparent;
}
.hljs-emphasis,
.token.italic {
  font-style: italic;
}
.m-t-0 {
  margin-top: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-5 {
  padding: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-10 {
  padding: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-15 {
  padding: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-25 {
  padding: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-30 {
  padding: 30px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-l-40,
.qt-loader.qt-loader-left {
  padding-left: 40px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-40 {
  padding: 40px !important;
}
.m-t-45 {
  margin-top: 45px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.m-b-45 {
  margin-bottom: 45px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}
.p-l-45 {
  padding-left: 45px !important;
}
.m-r-45 {
  margin-right: 45px !important;
}
.p-r-45 {
  padding-right: 45px !important;
}
.p-45 {
  padding: 45px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.p-r-50 {
  padding-right: 50px !important;
}
.p-50 {
  padding: 50px !important;
}
.m-t-75 {
  margin-top: 75px !important;
}
.p-t-75 {
  padding-top: 75px !important;
}
.m-b-75 {
  margin-bottom: 75px !important;
}
.p-b-75 {
  padding-bottom: 75px !important;
}
.m-l-75 {
  margin-left: 75px !important;
}
.p-l-75 {
  padding-left: 75px !important;
}
.m-r-75 {
  margin-right: 75px !important;
}
.p-r-75 {
  padding-right: 75px !important;
}
.p-75 {
  padding: 75px !important;
}
.m-t-100 {
  margin-top: 100px !important;
}
.p-t-100 {
  padding-top: 100px !important;
}
.m-b-100 {
  margin-bottom: 100px !important;
}
.p-b-100 {
  padding-bottom: 100px !important;
}
.m-l-100 {
  margin-left: 100px !important;
}
.p-l-100 {
  padding-left: 100px !important;
}
.m-r-100 {
  margin-right: 100px !important;
}
.p-r-100 {
  padding-right: 100px !important;
}
.p-100 {
  padding: 100px !important;
}
.m-t-125 {
  margin-top: 125px !important;
}
.p-t-125 {
  padding-top: 125px !important;
}
.m-b-125 {
  margin-bottom: 125px !important;
}
.p-b-125 {
  padding-bottom: 125px !important;
}
.m-l-125 {
  margin-left: 125px !important;
}
.p-l-125 {
  padding-left: 125px !important;
}
.m-r-125 {
  margin-right: 125px !important;
}
.p-r-125 {
  padding-right: 125px !important;
}
.p-125 {
  padding: 125px !important;
}
.m-t-150 {
  margin-top: 150px !important;
}
.p-t-150 {
  padding-top: 150px !important;
}
.m-b-150 {
  margin-bottom: 150px !important;
}
.p-b-150 {
  padding-bottom: 150px !important;
}
.m-l-150 {
  margin-left: 150px !important;
}
.p-l-150 {
  padding-left: 150px !important;
}
.m-r-150 {
  margin-right: 150px !important;
}
.p-r-150 {
  padding-right: 150px !important;
}
.p-150 {
  padding: 150px !important;
}
.m-t-175 {
  margin-top: 175px !important;
}
.p-t-175 {
  padding-top: 175px !important;
}
.m-b-175 {
  margin-bottom: 175px !important;
}
.p-b-175 {
  padding-bottom: 175px !important;
}
.m-l-175 {
  margin-left: 175px !important;
}
.p-l-175 {
  padding-left: 175px !important;
}
.m-r-175 {
  margin-right: 175px !important;
}
.p-r-175 {
  padding-right: 175px !important;
}
.p-175 {
  padding: 175px !important;
}
.m-t-200 {
  margin-top: 200px !important;
}
.p-t-200 {
  padding-top: 200px !important;
}
.m-b-200 {
  margin-bottom: 200px !important;
}
.p-b-200 {
  padding-bottom: 200px !important;
}
.m-l-200 {
  margin-left: 200px !important;
}
.p-l-200 {
  padding-left: 200px !important;
}
.m-r-200 {
  margin-right: 200px !important;
}
.p-r-200 {
  padding-right: 200px !important;
}
.p-200 {
  padding: 200px !important;
}
.m-t-250 {
  margin-top: 250px !important;
}
.p-t-250 {
  padding-top: 250px !important;
}
.m-b-250 {
  margin-bottom: 250px !important;
}
.p-b-250 {
  padding-bottom: 250px !important;
}
.m-l-250 {
  margin-left: 250px !important;
}
.p-l-250 {
  padding-left: 250px !important;
}
.m-r-250 {
  margin-right: 250px !important;
}
.p-r-250 {
  padding-right: 250px !important;
}
.p-250 {
  padding: 250px !important;
}
.m-t-300 {
  margin-top: 300px !important;
}
.p-t-300 {
  padding-top: 300px !important;
}
.m-b-300 {
  margin-bottom: 300px !important;
}
.p-b-300 {
  padding-bottom: 300px !important;
}
.m-l-300 {
  margin-left: 300px !important;
}
.p-l-300 {
  padding-left: 300px !important;
}
.m-r-300 {
  margin-right: 300px !important;
}
.p-r-300 {
  padding-right: 300px !important;
}
.p-300 {
  padding: 300px !important;
}
.m-t-350 {
  margin-top: 350px !important;
}
.p-t-350 {
  padding-top: 350px !important;
}
.m-b-350 {
  margin-bottom: 350px !important;
}
.p-b-350 {
  padding-bottom: 350px !important;
}
.m-l-350 {
  margin-left: 350px !important;
}
.p-l-350 {
  padding-left: 350px !important;
}
.m-r-350 {
  margin-right: 350px !important;
}
.p-r-350 {
  padding-right: 350px !important;
}
.p-350 {
  padding: 350px !important;
}
.m-t-400 {
  margin-top: 400px !important;
}
.p-t-400 {
  padding-top: 400px !important;
}
.m-b-400 {
  margin-bottom: 400px !important;
}
.p-b-400 {
  padding-bottom: 400px !important;
}
.m-l-400 {
  margin-left: 400px !important;
}
.p-l-400 {
  padding-left: 400px !important;
}
.m-r-400 {
  margin-right: 400px !important;
}
.p-r-400 {
  padding-right: 400px !important;
}
.p-400 {
  padding: 400px !important;
}
.m-t-450 {
  margin-top: 450px !important;
}
.p-t-450 {
  padding-top: 450px !important;
}
.m-b-450 {
  margin-bottom: 450px !important;
}
.p-b-450 {
  padding-bottom: 450px !important;
}
.m-l-450 {
  margin-left: 450px !important;
}
.p-l-450 {
  padding-left: 450px !important;
}
.m-r-450 {
  margin-right: 450px !important;
}
.p-r-450 {
  padding-right: 450px !important;
}
.p-450 {
  padding: 450px !important;
}
.m-t-500 {
  margin-top: 500px !important;
}
.p-t-500 {
  padding-top: 500px !important;
}
.m-b-500 {
  margin-bottom: 500px !important;
}
.p-b-500 {
  padding-bottom: 500px !important;
}
.m-l-500 {
  margin-left: 500px !important;
}
.p-l-500 {
  padding-left: 500px !important;
}
.m-r-500 {
  margin-right: 500px !important;
}
.p-r-500 {
  padding-right: 500px !important;
}
.p-500 {
  padding: 500px !important;
}
.w-0 {
  width: 0 !important;
}
.w-5 {
  width: 5px !important;
}
.w-10 {
  width: 10px !important;
}
.w-15 {
  width: 15px !important;
}
.w-20 {
  width: 20px !important;
}
.w-25 {
  width: 25px !important;
}
.w-30 {
  width: 30px !important;
}
.w-35 {
  width: 35px !important;
}
.w-40 {
  width: 40px !important;
}
.w-45 {
  width: 45px !important;
}
.w-50 {
  width: 50px !important;
}
.w-75 {
  width: 75px !important;
}
.w-100 {
  width: 100px !important;
}
.w-125 {
  width: 125px !important;
}
.w-150 {
  width: 150px !important;
}
.w-175 {
  width: 175px !important;
}
.w-200 {
  width: 200px !important;
}
.w-250 {
  width: 250px !important;
}
.w-300 {
  width: 300px !important;
}
.w-350 {
  width: 350px !important;
}
.w-400 {
  width: 400px !important;
}
.w-450 {
  width: 450px !important;
}
.w-500 {
  width: 500px !important;
}
.h-0 {
  height: 0 !important;
}
.h-5 {
  height: 5px !important;
}
.h-10 {
  height: 10px !important;
}
.h-15 {
  height: 15px !important;
}
.h-20 {
  height: 20px !important;
}
.h-25 {
  height: 25px !important;
}
.h-30 {
  height: 30px !important;
}
.h-35 {
  height: 35px !important;
}
.h-40 {
  height: 40px !important;
}
.h-45 {
  height: 45px !important;
}
.h-50 {
  height: 50px !important;
}
.h-75 {
  height: 75px !important;
}
.h-100 {
  height: 100px !important;
}
.h-125 {
  height: 125px !important;
}
.h-150 {
  height: 150px !important;
}
.h-175 {
  height: 175px !important;
}
.h-200 {
  height: 200px !important;
}
.h-250 {
  height: 250px !important;
}
.h-300 {
  height: 300px !important;
}
.h-350 {
  height: 350px !important;
}
.h-400 {
  height: 400px !important;
}
.h-450 {
  height: 450px !important;
}
.h-500 {
  height: 500px !important;
}
.min-w-0 {
  min-width: 0 !important;
}
.min-w-5 {
  min-width: 5px !important;
}
.min-w-10 {
  min-width: 10px !important;
}
.min-w-15 {
  min-width: 15px !important;
}
.min-w-20 {
  min-width: 20px !important;
}
.min-w-25 {
  min-width: 25px !important;
}
.min-w-30 {
  min-width: 30px !important;
}
.min-w-35 {
  min-width: 35px !important;
}
.min-w-40 {
  min-width: 40px !important;
}
.min-w-45 {
  min-width: 45px !important;
}
.min-w-50 {
  min-width: 50px !important;
}
.min-w-75 {
  min-width: 75px !important;
}
.min-w-100 {
  min-width: 100px !important;
}
.min-w-125 {
  min-width: 125px !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-175 {
  min-width: 175px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
.min-w-250 {
  min-width: 250px !important;
}
.min-w-300 {
  min-width: 300px !important;
}
.min-w-350 {
  min-width: 350px !important;
}
.min-w-400 {
  min-width: 400px !important;
}
.min-w-450 {
  min-width: 450px !important;
}
.min-w-500 {
  min-width: 500px !important;
}
.min-h-0 {
  min-height: 0 !important;
}
.min-h-5 {
  min-height: 5px !important;
}
.min-h-10 {
  min-height: 10px !important;
}
.min-h-15 {
  min-height: 15px !important;
}
.min-h-20 {
  min-height: 20px !important;
}
.min-h-25 {
  min-height: 25px !important;
}
.min-h-30 {
  min-height: 30px !important;
}
.min-h-35 {
  min-height: 35px !important;
}
.min-h-40 {
  min-height: 40px !important;
}
.min-h-45 {
  min-height: 45px !important;
}
.min-h-50 {
  min-height: 50px !important;
}
.min-h-75 {
  min-height: 75px !important;
}
.min-h-100 {
  min-height: 100px !important;
}
.min-h-125 {
  min-height: 125px !important;
}
.min-h-150 {
  min-height: 150px !important;
}
.min-h-175 {
  min-height: 175px !important;
}
.min-h-200 {
  min-height: 200px !important;
}
.min-h-250 {
  min-height: 250px !important;
}
.min-h-300 {
  min-height: 300px !important;
}
.min-h-350 {
  min-height: 350px !important;
}
.min-h-400 {
  min-height: 400px !important;
}
.min-h-450 {
  min-height: 450px !important;
}
.min-h-500 {
  min-height: 500px !important;
}
.max-w-0 {
  max-width: 0 !important;
}
.max-w-5 {
  max-width: 5px !important;
}
.max-w-10 {
  max-width: 10px !important;
}
.max-w-15 {
  max-width: 15px !important;
}
.max-w-20 {
  max-width: 20px !important;
}
.max-w-25 {
  max-width: 25px !important;
}
.max-w-30 {
  max-width: 30px !important;
}
.max-w-35 {
  max-width: 35px !important;
}
.max-w-40 {
  max-width: 40px !important;
}
.max-w-45 {
  max-width: 45px !important;
}
.max-w-50 {
  max-width: 50px !important;
}
.max-w-75 {
  max-width: 75px !important;
}
.max-w-100 {
  max-width: 100px !important;
}
.max-w-125 {
  max-width: 125px !important;
}
.max-w-150 {
  max-width: 150px !important;
}
.max-w-175 {
  max-width: 175px !important;
}
.max-w-200 {
  max-width: 200px !important;
}
.max-w-250 {
  max-width: 250px !important;
}
.max-w-300 {
  max-width: 300px !important;
}
.max-w-350 {
  max-width: 350px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-450 {
  max-width: 450px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-h-0 {
  max-height: 0 !important;
}
.max-h-5 {
  max-height: 5px !important;
}
.max-h-10 {
  max-height: 10px !important;
}
.max-h-15 {
  max-height: 15px !important;
}
.max-h-20 {
  max-height: 20px !important;
}
.max-h-25 {
  max-height: 25px !important;
}
.max-h-30 {
  max-height: 30px !important;
}
.max-h-35 {
  max-height: 35px !important;
}
.max-h-40 {
  max-height: 40px !important;
}
.max-h-45 {
  max-height: 45px !important;
}
.max-h-50 {
  max-height: 50px !important;
}
.max-h-75 {
  max-height: 75px !important;
}
.max-h-100 {
  max-height: 100px !important;
}
.max-h-125 {
  max-height: 125px !important;
}
.max-h-150 {
  max-height: 150px !important;
}
.max-h-175 {
  max-height: 175px !important;
}
.max-h-200 {
  max-height: 200px !important;
}
.max-h-250 {
  max-height: 250px !important;
}
.max-h-300 {
  max-height: 300px !important;
}
.max-h-350 {
  max-height: 350px !important;
}
.max-h-400 {
  max-height: 400px !important;
}
.max-h-450 {
  max-height: 450px !important;
}
.max-h-500 {
  max-height: 500px !important;
}
.min-h-100vh {
  min-height: 100vh !important;
}
.min-w-100vh {
  min-width: 100vh !important;
}
.h-100vh {
  height: 100vh;
}
.w-100vh {
  width: 100vh;
}
.list-reset,
.no-bullets {
  list-style: none;
}
.bullets-inside {
  list-style: inside;
}
.list-reset {
  margin: 0;
  padding: 0;
}
.list-inline-block li {
  display: inline-block;
}
.hide,
.mobile-only {
  display: none !important;
}
.f-w-300,
.font-w-300 {
  font-weight: 300 !important;
}
.f-w-400,
.font-w-400 {
  font-weight: 400 !important;
}
.f-w-500,
.font-weight-500 {
  font-weight: 500 !important;
}
.f-w-600,
.font-weight-600 {
  font-weight: 600 !important;
}
.f-w-700,
.font-weight-700 {
  font-weight: 700 !important;
}
.f-w-800,
.font-weight-800 {
  font-weight: 800 !important;
}
.f-w-900,
.font-weight-900 {
  font-weight: 900 !important;
}
.h1,
.h2,
.h3,
body,
h1,
h2,
h3,
html {
  font-weight: 300;
}
.p-absolute {
  position: absolute !important;
}
.p-relative,
.tint {
  position: relative !important;
}
.font-size-10 {
  font-size: 0.625rem !important;
}
.font-size-12 {
  font-size: 0.75rem !important;
}
.font-size-14 {
  font-size: 0.875rem !important;
}
.font-size-16 {
  font-size: 1rem !important;
}
.font-size-18 {
  font-size: 1.125rem !important;
}
.font-size-20 {
  font-size: 1.25rem !important;
}
.font-size-22 {
  font-size: 1.375rem !important;
}
.font-size-24 {
  font-size: 1.5rem !important;
}
.font-size-26 {
  font-size: 1.625rem !important;
}
.font-size-28 {
  font-size: 1.75rem !important;
}
.font-size-30 {
  font-size: 1.875rem !important;
}
.font-size-32 {
  font-size: 2rem !important;
}
.font-size-34 {
  font-size: 2.125rem !important;
}
.font-size-36 {
  font-size: 2.25rem !important;
}
.font-size-38 {
  font-size: 2.375rem !important;
}
.font-size-40 {
  font-size: 2.5rem !important;
}
.font-size-45 {
  font-size: 2.8125rem !important;
}
.font-size-50 {
  font-size: 3.125rem !important;
}
.font-size-60 {
  font-size: 3.75rem !important;
}
.font-size-70 {
  font-size: 2.9375rem !important;
}
.font-size-80 {
  font-size: 5rem !important;
}
.font-size-90 {
  font-size: 5.625rem !important;
}
.font-size-100 {
  font-size: 6.25rem !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-line-through {
  text-decoration: line-through !important;
}
.transparent {
  background: 0 0 !important;
}
.no-shadow {
  box-shadow: none !important;
}
.border-none,
.no-border {
  border: none !important;
}
.border {
  border: 1px solid #eaf3fd !important;
}
.border-right-none {
  border-right: none !important;
}
.border-left-none {
  border-left: none !important;
}
.border-top-none {
  border-top: none !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.border-right {
  border-right: 1px solid #eaf3fd !important;
}
.border-left {
  border-left: 1px solid #eaf3fd !important;
}
.border-top {
  border-top: 1px solid #eaf3fd !important;
}
.border-bottom {
  border-bottom: 1px solid #eaf3fd !important;
}
.b-warning {
  border-color: #ffce67 !important;
}
.b-danger {
  border-color: #ff5c75 !important;
}
.b-info {
  border-color: #399af2 !important;
}
.b-success {
  border-color: #2fbfa0 !important;
}
.b-r-0 {
  border-radius: 0 !important;
}
.row[class*='col-border-'] > div {
  border-right: none;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
}
.row[class*='col-border-'] > div:last-child {
  border-bottom: none;
}
@media (min-width: 1200px) {
  .row.col-border-xl > div {
    border-bottom: none;
    border-right: 1px solid rgba(234, 243, 253, 0.9);
  }
  .row.col-border-xl > div:last-child {
    border-right: none;
  }
}
.blockquote-footer,
.text-muted {
  color: #839bb3 !important;
}
.flex {
  flex: 1 !important;
}
.rotate-90 {
  -webkit-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}
.rotate-180 {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.append-percent:after {
  content: '%' !important;
}
.prepend-currency:before {
  content: '$' !important;
}
.tint:before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.v-align-middle {
  vertical-align: middle !important;
}
.v-align-text-bottom {
  vertical-align: text-bottom !important;
}
.nowrap {
  white-space: nowrap !important;
}
@media (max-width: 991.98px) {
  .mobile-only {
    display: flex !important;
  }
}
.sm-gutter .row > [class*='col-'],
.sm-gutter .row > [class^='col-'] {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .card-group.card-group-lg {
    display: block;
    width: 100%;
    margin-bottom: 0.75rem;
    table-layout: fixed;
    border-spacing: 1.25rem 0;
  }
  .card-group.card-group-lg .card {
    display: block;
    margin-bottom: 0.75rem;
    vertical-align: top;
  }
  .card-group.card-group-lg .card:not(:first-child) {
    margin-left: 0;
  }
}
.bg-secondary {
  background-color: #788db4 !important;
}
.bg-secondary .bg-lt,
.bg-secondary.bg-lt {
  background-color: #889bbd !important;
}
.bg-secondary .bg-dk,
.bg-secondary.bg-lt {
  background-color: #687fab !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #6c757d !important;
}
.text-secondary {
  color: #788db4 !important;
}
.bg-success {
  background-color: #2fbfa0 !important;
}
.bg-success .bg-lt,
.bg-success.bg-lt {
  background-color: #39ceae !important;
}
.bg-success .bg-dk,
.bg-success.bg-lt {
  background-color: #2aab8f !important;
}
a.bg-success:focus,
a.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #399af2 !important;
}
.bg-info .bg-lt,
.bg-info.bg-lt {
  background-color: #51a6f4 !important;
}
.bg-info .bg-dk,
.bg-info.bg-lt {
  background-color: #218ef0 !important;
}
a.bg-info:focus,
a.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffce67 !important;
}
.bg-warning .bg-lt,
.bg-warning.bg-lt {
  background-color: #ffd681 !important;
}
.bg-warning .bg-dk,
.bg-warning.bg-lt {
  background-color: #ffc64e !important;
}
a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #ff5c75 !important;
}
.bg-danger .bg-lt,
.bg-danger.bg-lt {
  background-color: #ff768b !important;
}
.bg-danger .bg-dk,
.bg-danger.bg-lt {
  background-color: #ff435f !important;
}
a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-dark {
  background-color: #15182b !important;
}
.bg-light {
  background-color: #f0f6ff !important;
}
.bg-light[href]:focus,
.bg-light[href]:hover {
  text-decoration: none;
  background-color: rgba(240, 246, 255, 0.85) !important;
}
.c-white,
.text-white,
.white-text {
  color: #fff !important;
}
.brand-success {
  background-color: #2fbfa0 !important;
}
.brand-danger {
  background-color: #ff5c75 !important;
}
.brand-warning {
  background-color: #ffce67 !important;
}
.brand-info {
  background-color: #399af2 !important;
}
.brand-grey {
  background-color: #718599 !important;
}
.bg-dark-grey,
.brand-dark-grey {
  background-color: #4d5a68 !important;
}
.text-success {
  color: #2fbfa0 !important;
}
.text-danger {
  color: #ff5c75 !important;
}
.text-warning {
  color: #ffce67 !important;
}
.text-info {
  color: #399af2 !important;
}
.text-grey {
  color: #718599 !important;
}
.bg-pink-orange {
  background: #f54fa1;
  background: linear-gradient(to right, #f54fa1 0, #fd6f7d 100%);
}
.bg-gradient-purple {
  background: #663996;
  background: linear-gradient(45deg, #663996 0, #663996 0, #6e4b93 39%, #d4619f 100%);
}
html {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
}
body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body,
html {
  font-size: 100%;
  line-height: 1.5;
  letter-spacing: 0.02rem;
}
h6,
ol li,
p,
ul li {
  font-size: 0.875rem;
}
a,
a:link,
a:visited {
  text-decoration: none;
  outline: 0;
  transition: all 0.3s ease;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
*,
:active,
:focus {
  outline: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.02em;
}
.h4,
.h5,
.h6,
h4,
h5,
h6,
label {
  font-weight: 400;
}
p {
  line-height: 1.7;
}
label {
  font-size: 0.875rem !important;
}
::selection {
  background: #fff9cc;
}
::-moz-selection {
  background: #fff9cc;
}
code {
  font-size: 87.5%;
  word-break: break-word;
}
pre {
  white-space: pre-wrap;
}
figure,
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
strong {
  font-weight: 500;
}
@media (min-width: 576px) {
  h1 {
    font-size: 1.375rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3,
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.375rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.375rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.375rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 1rem;
}
.accordion > .accordion-item {
  border-radius: 5px !important;
  position: relative;
}
.accordion > .accordion-item > .accordion-header {
  height: 50px;
}
.accordion > .accordion-item > .accordion-header .btn-link {
  color: #556579;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 6px 17px 6px 20px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  text-align: left;
}
.accordion > .accordion-item > .accordion-header .btn-link:after {
  position: absolute;
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: initial;
  right: 1.3em;
  -webkit-transform-origin: top;
  transform-origin: top;
  top: 44%;
  transition: all 0.3s ease-out;
}
.accordion > .accordion-item > .accordion-header .btn-link.collapsed:after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}
.accordion > .accordion-item > .accordion-header .btn-link:after {
  -webkit-transform: rotate(-315deg) translate(0, -50%);
  transform: rotate(-315deg) translate(0, -50%);
}
.nav .nav-item .nav-link {
  color: #718eb1;
  transition: all 0.15s ease;
}
.nav.sub-nav.v-sub-nav {
  border: 0;
}
.nav.sub-nav.v-sub-nav .nav-item .nav-link {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  outline: 0 !important;
  vertical-align: middle;
  padding: 8px 0 8px 20px;
  border: 0;
}
.nav.sub-nav.v-sub-nav .nav-item:hover {
  background-color: #f0f6ff;
}
.nav.metismenu {
  padding: 0.625rem 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column;
}
.nav.metismenu > li,
.nav.metismenu > li > a {
  display: block;
  position: relative;
}
.nav.metismenu > li > a {
  border-radius: 0 !important;
  font-size: 0.8125rem;
  font-weight: 300;
  border-left: solid 3px transparent;
  padding: 12px 15px 5px 20px;
  width: 100%;
  position: relative;
}
.nav.metismenu > li > a i {
  margin-right: 10px;
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
}
.nav.metismenu .open > a,
.nav.metismenu .open > a:focus,
.nav.metismenu .open > a:hover,
.nav.metismenu > li > a:active,
.nav.metismenu > li > a:hover {
  opacity: 1;
  width: 100%;
}
.nav.metismenu > li.active > a,
.nav.metismenu > li.active > a:focus,
.nav.metismenu > li.active > a:hover {
  width: 100%;
}
.nav.metismenu > li + li {
  margin-top: 0;
  margin-left: 0;
}
.nav.metismenu .nav-dropdown .nav-sub {
  list-style: none;
  padding: 0;
  position: relative;
}
.nav.metismenu .nav-dropdown .nav-sub li:last-child {
  padding: 0 0 10px;
}
.nav.metismenu .nav-sub > li > a {
  font-size: 0.8125rem;
  font-weight: 300;
  padding: 8px 0 8px 60px;
  display: block;
  position: relative;
}
.nav.metismenu .nav.metismenu .nav-dropdown.active > .nav-sub,
.nav.metismenu .nav.metismenu .nav-dropdown.active > a {
  border-left: solid 0.1875rem;
}
.nav.metismenu > li > .nav-sub > li > .nav-sub > li > a,
.nav.metismenu > li > .nav-sub > li > .nav-sub > li > div.radio {
  padding-left: 4.6875rem;
}
.nav.metismenu > li > .nav-sub > li > .nav-sub > li > div.radio label {
  font-weight: 300;
}
.nav.metismenu li .badge,
.nav.metismenu li .label {
  display: block;
  position: absolute;
  right: 1.25rem;
  bottom: 0.5625rem;
}
.nav.metismenu .has-arrow::after {
  right: 25px;
}
.block-el {
  position: relative;
}
.block-el .qt-block-ui {
  position: absolute;
}
.qt-block-ui {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}
.btn-block-ui:before,
.qt-block-ui:before {
  content: '';
  box-sizing: border-box;
  background: 0 0;
  opacity: 0.75;
}
.qt-block-ui:before {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.6rem;
  -webkit-animation: clockwise 0.5s linear infinite;
  animation: clockwise 0.5s linear infinite;
}
.btn-block-ui:before,
.qt-loader:before {
  border: 2px solid #fff;
  -webkit-animation: clockwise 0.5s linear infinite;
}
.qt-block-ui .text {
  color: #fff;
  text-align: center;
  position: relative;
  top: 30%;
}
.btn-block-ui {
  padding-left: 40px;
}
.btn-block-ui:before {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  left: 20px;
  margin-left: 10px;
  animation: clockwise 0.5s linear infinite;
}
.avatar {
  width: 100px;
  height: 100px;
}
.avatar.circle {
  border-radius: 50%;
}
.avatar.x-small {
  width: 50px;
  height: 50px;
}
.avatar.small {
  width: 75px;
  height: 75px;
}
.avatar.large {
  width: 150px;
  height: 150px;
}
.dropdown,
.dropleft,
.dropright,
.dropup,
.input-group {
  position: relative;
}
.dropdown .dropdown-menu,
.dropleft .dropdown-menu,
.dropright .dropdown-menu,
.dropup .dropdown-menu,
.input-group .dropdown-menu {
  margin: 5px 0 0;
  border: none;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
  font-size: 0.875rem;
  width: 250px;
  position: absolute;
  z-index: 1000;
  top: 75% !important;
}
.dropdown .dropdown-menu .dropdown-header,
.dropleft .dropdown-menu .dropdown-header,
.dropright .dropdown-menu .dropdown-header,
.dropup .dropdown-menu .dropdown-header,
.input-group .dropdown-menu .dropdown-header {
  display: block;
  padding: 15px;
  margin-bottom: 0;
  font-size: 0.875rem;
  white-space: nowrap;
}
.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item,
.input-group .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  padding: 10px 20px;
  font-size: 0.875rem;
  border-left: solid 2px transparent;
  position: relative;
}
.dropdown .dropdown-menu .dropdown-item i,
.dropleft .dropdown-menu .dropdown-item i,
.dropright .dropdown-menu .dropdown-item i,
.dropup .dropdown-menu .dropdown-item i,
.input-group .dropdown-menu .dropdown-item i {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9375rem;
}
.dropdown .dropdown-menu.dropdown-menu-right,
.dropleft .dropdown-menu.dropdown-menu-right,
.dropright .dropdown-menu.dropdown-menu-right,
.dropup .dropdown-menu.dropdown-menu-right,
.input-group .dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.dropdown .dropdown-menu.dropdown-menu-left,
.dropleft .dropdown-menu.dropdown-menu-left,
.dropright .dropdown-menu.dropdown-menu-left,
.dropup .dropdown-menu.dropdown-menu-left,
.input-group .dropdown-menu.dropdown-menu-left {
  left: 0 !important;
  right: auto !important;
}
.dropdown .dropdown-menu.dropdown-icon-menu,
.dropleft .dropdown-menu.dropdown-icon-menu,
.dropright .dropdown-menu.dropdown-icon-menu,
.dropup .dropdown-menu.dropdown-icon-menu,
.input-group .dropdown-menu.dropdown-icon-menu {
  top: 100% !important;
}
.dropdown > .dropdown-icon > i,
.dropleft > .dropdown-icon > i,
.dropright > .dropdown-icon > i,
.dropup > .dropdown-icon > i,
.input-group > .dropdown-icon > i {
  color: #788db4;
  font-size: 1.5rem;
  position: relative;
  width: 35px;
  height: 35px;
  min-width: 35px;
  max-height: 35px;
}
.dropdown > .dropdown-icon.dropdown-border,
.dropleft > .dropdown-icon.dropdown-border,
.dropright > .dropdown-icon.dropdown-border,
.dropup > .dropdown-icon.dropdown-border,
.input-group > .dropdown-icon.dropdown-border {
  width: 35px;
  height: 35px;
  min-width: 35px;
  max-height: 35px;
  text-align: center;
  vertical-align: middle;
}
.dropdown > .dropdown-icon.dropdown-border:hover,
.dropleft > .dropdown-icon.dropdown-border:hover,
.dropright > .dropdown-icon.dropdown-border:hover,
.dropup > .dropdown-icon.dropdown-border:hover,
.input-group > .dropdown-icon.dropdown-border:hover {
  background-color: #e3ebf4;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.dropdown > .dropdown-icon.dropdown-border:before,
.dropleft > .dropdown-icon.dropdown-border:before,
.dropright > .dropdown-icon.dropdown-border:before,
.dropup > .dropdown-icon.dropdown-border:before,
.input-group > .dropdown-icon.dropdown-border:before {
  content: '';
  position: absolute;
  border: 1px solid rgba(120, 141, 180, 0.3);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  left: 0;
  top: -2px;
  z-index: 0;
}
.dropdown > .dropdown-icon.dropdown-border i,
.dropleft > .dropdown-icon.dropdown-border i,
.dropright > .dropdown-icon.dropdown-border i,
.dropup > .dropdown-icon.dropdown-border i,
.input-group > .dropdown-icon.dropdown-border i {
  z-index: 5;
}
.dropdown.show > .dropdown-icon.dropdown-border:before,
.dropleft.show > .dropdown-icon.dropdown-border:before,
.dropright.show > .dropdown-icon.dropdown-border:before,
.dropup.show > .dropdown-icon.dropdown-border:before,
.input-group.show > .dropdown-icon.dropdown-border:before {
  background-color: #e3ebf4;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.dropdown:has-arrow,
.dropleft:has-arrow,
.dropright:has-arrow,
.dropup:has-arrow,
.input-group:has-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown .dropdown-menu {
  -webkit-animation-name: slideUpIn;
  animation-name: slideUpIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.dropup .dropdown-menu {
  top: -15% !important;
}
.dropdown a i,
.dropleft a i,
.dropright a i,
.dropup a i {
  font-size: 1.375rem;
}
.dropdown.show .dropdown-icon.dropdown-secondary i {
  color: #788db4;
}
.dropdown.show .dropdown-icon.dropdown-secondary:before {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(120, 141, 180, 0.4);
  left: 0;
  top: -2px;
}
.dropdown-menu > .dropdown-menu.show {
  position: absolute;
  left: 100%;
  margin-top: -1px;
  top: 0;
}
.dropdown-menu .form-filter {
  max-height: 340px;
  position: relative;
}
.dropdown-menu .form-filter .filter-input {
  width: 90%;
  margin: 10px;
  background: #f0f6ff;
  border: none;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}
.dropdown-menu .form-filter .clear-filter {
  right: 15px;
  top: 8px;
  position: absolute;
}
.dropdown-menu .form-filter .filter-list {
  max-height: 300px;
  overflow: auto;
}
.dropdown .dropdown-menu .card {
  box-shadow: none;
  margin: 0;
}
.dropdown .dropdown-menu .card .card-header {
  padding: 10px 20px;
}
.dropdown .dropdown-menu .card .card-header .card-title {
  font-size: 1.125rem;
  margin: 0 0 8px;
}
@-webkit-keyframes slideUpIn {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
@keyframes slideUpIn {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
.dropdown-menu .dropdown-menu-grid {
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.dropdown-menu .dropdown-menu-grid .menu-grid-row {
  display: table-row;
}
.dropdown-menu .dropdown-menu-grid .menu-grid-row > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.dropdown-menu .dropdown-menu-grid .menu-grid-row > div a {
  vertical-align: middle;
  text-align: center;
  padding: 35px 5px;
}
.dropdown-menu .dropdown-menu-grid .menu-grid-row > div a i {
  font-size: 2rem;
  text-align: center;
}
.dropdown-menu .dropdown-menu-grid .menu-grid-row > div a span {
  display: block;
  font-weight: 400;
}
.card .card-header,
.card .card-header .card-title {
  font-weight: 300;
  font-size: 1rem;
}
.dropdown-menu-lg .dropdown-menu {
  min-width: 320px;
  min-height: 200px;
}
.modal button.close {
  color: #788db4;
  text-shadow: none;
}
.modal button.close:active,
.modal button.close:focus {
  outline: 0;
}
.modal[data-modal='scroll'] .modal-body {
  max-height: 450px;
  overflow-y: auto;
}
.modal .modal-content {
  border: 0;
}
.modal.modal-top-left-corner .modal-dialog {
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 30px;
}
.modal.modal-top-right-corner .modal-dialog {
  position: fixed;
  top: 0;
  right: 30px;
  bottom: auto;
  left: auto;
}
.modal.modal-bottom-left-corner .modal-dialog {
  position: fixed;
  top: auto;
  right: auto;
  bottom: 0;
  left: 30px;
}
.modal.modal-bottom-right-corner .modal-dialog {
  position: fixed;
  top: auto;
  right: 30px;
  bottom: 0;
  left: auto;
}
.modal.modal-left-side {
  border-radius: 0;
}
.modal.modal-left-side .modal-dialog {
  position: fixed;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  border-radius: 0;
  margin: 0;
}
.modal.modal-left-side .modal-content {
  border-radius: 0;
  width: 300px;
  height: 100vh;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.modal.modal-right-side {
  border-radius: 0;
}
.modal.modal-right-side .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  border-radius: 0;
  margin: 0;
}
.modal.modal-right-side .modal-content {
  border-radius: 0;
  width: 300px;
  height: 100vh;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.modal.modal-top-frame {
  border-radius: 0;
}
.modal.modal-top-frame .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  border-radius: 0;
  margin: 0;
  max-width: none;
}
.modal.modal-top-frame .modal-content {
  border-radius: 0;
  width: 100%;
  height: 300px;
  max-width: none;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.modal.modal-bottom-frame {
  border-radius: 0;
}
.modal.modal-bottom-frame .modal-dialog {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  margin: 0;
  max-width: none;
}
.modal.modal-bottom-frame .modal-content {
  border-radius: 0;
  width: 100%;
  height: 300px;
  max-width: none;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.carousel-control-next-icon:after,
.carousel-control-prev-icon:after {
  font-family: LineAwesome;
  font-size: 30px;
  right: 1.5625rem;
  top: 50%;
  margin-top: -0.625rem;
  transition: all 'all' '250ms';
  color: #fff;
  position: absolute;
}
.modal-xl .modal-dialog {
  max-width: 1200px;
}
.carousel-control-next,
.carousel-control-prev {
  width: 10%;
}
.carousel-control-prev-icon:after {
  content: '\f111';
}
.carousel-control-next-icon:after {
  content: '\f112';
}
.nav-tabs {
  border-bottom: 1px solid rgba(210, 221, 234, 0.5);
  margin-bottom: 20px;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-item .nav-link {
  border: none;
  color: #718599;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  border-bottom: 1px solid transparent;
  border-radius: 0;
}
.nav.nav-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #353a40;
  color: #353a40;
}
.nav.nav-tabs .nav-item .nav-link.active.show,
.nav.nav-tabs .nav-item .nav-link:hover,
.nav.nav-tabs .nav-item.show .nav-link {
  color: #353a40;
}
.nav.nav-tabs .nav-item.show .nav-link,
.nav.nav-tabs .nav-link.active {
  border-color: #353a40;
}
.nav-tabs.secondary-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #788db4;
  color: #788db4;
}
.nav-tabs.secondary-tabs .nav-item .nav-link.active.show,
.nav-tabs.secondary-tabs .nav-item .nav-link:hover,
.nav-tabs.secondary-tabs .nav-item.show .nav-link {
  color: #788db4;
}
.nav-tabs.secondary-tabs .nav-item.show .nav-link,
.nav-tabs.secondary-tabs .nav-link.active {
  border-color: #788db4;
}
.nav.nav-tabs.success-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #2fbfa0;
  color: #2fbfa0;
}
.nav.nav-tabs.success-tabs .nav-item .nav-link.active.show,
.nav.nav-tabs.success-tabs .nav-item .nav-link:hover,
.nav.nav-tabs.success-tabs .nav-item.show .nav-link {
  color: #2fbfa0;
}
.nav.nav-tabs.success-tabs .nav-item.show .nav-link,
.nav.nav-tabs.success-tabs .nav-link.active {
  border-color: #2fbfa0;
}
.nav.nav-tabs.info-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #399af2;
  color: #399af2;
}
.nav.nav-tabs.info-tabs .nav-item .nav-link.active.show,
.nav.nav-tabs.info-tabs .nav-item .nav-link:hover,
.nav.nav-tabs.info-tabs .nav-item.show .nav-link {
  color: #399af2;
}
.nav.nav-tabs.info-tabs .nav-item.show .nav-link,
.nav.nav-tabs.info-tabs .nav-link.active {
  border-color: #399af2;
}
.card.card-tabs .card-header {
  border: none;
  padding: 0;
}
.card.card-tabs .card-header .card-title {
  position: absolute;
  left: 20px;
  top: 20px;
  margin: 0;
}
.card.card-tabs .card-header .nav.nav-tabs {
  margin-bottom: 5px;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  font-size: 0.875rem;
  height: 60px;
  padding: 1.4rem 1.5rem 1.3rem;
}
.nav-pills .nav-link {
  color: #788db4;
  border-radius: 80px;
  font-size: 0.875rem;
  padding: 10px 25px;
}
.nav-pills .nav-link i {
  vertical-align: middle;
  line-height: 0;
  display: inline-block;
  margin-right: 7px;
  font-size: 17px;
  margin-top: -4px;
}
.nav-pills .nav-link.active i {
  color: #fff;
}
.nav-pills .nav-link.disabled {
  color: #b1c6e0;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active {
  color: #fff;
}
.nav-pills .nav-link.disabled:hover {
  cursor: not-allowed;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e7f1fd;
}
.nav-pills .nav-link.btn-floating {
  border-radius: 50px;
  padding: 10px 25px;
}
.nav-pills .nav-link.btn-floating.active {
  box-shadow: 0 5px 10px rgba(113, 106, 202, 0.4) !important;
}
.card.card-pills .card-header {
  padding: 15px 20px;
}
.card.card-pills .card-header .card-title {
  position: absolute;
  left: 20px;
  top: 20px;
  margin: 0;
}
.card .card-toolbar {
  position: absolute;
}
.card[data-widget='dropzone'] .card-body,
.search-wrapper {
  position: relative;
}
.card .card-toolbar.top-right {
  right: 20px;
  top: 17px;
}
.card .card-toolbar.top-left {
  left: 20px;
  top: 17px;
}
.card .card-toolbar .nav-pills .nav-link {
  font-size: 0.875rem;
  padding: 7px 16px;
}
.nav-pills.nav-pills-danger .nav-link.btn-floating,
.nav-pills.nav-pills-info .nav-link.btn-floating,
.nav-pills.nav-pills-success .nav-link.btn-floating,
.nav-pills.nav-pills-warning .nav-link.btn-floating {
  border-radius: 50px;
  padding: 10px 25px;
}
.card.card-overlay.card-overlay-top {
  margin-top: 50px;
}
.card.card-overlay.card-overlay-top .card {
  margin: -50px 15px 0;
}
.card.card-overlay.card-overlay-bottom {
  margin-bottom: 50px;
}
.card.card-overlay.card-overlay-bottom .card {
  margin: 0 15px -50px;
}
.nav-pills.nav-pills-success .nav-link.active,
.nav-pills.nav-pills-success .show > .nav-link {
  background-color: #2fbfa0;
}
.nav-pills.nav-pills-success .nav-link.btn-floating.active {
  box-shadow: 0 5px 10px rgba(47, 191, 160, 0.4) !important;
}
.nav-pills.nav-pills-info .nav-link.active,
.nav-pills.nav-pills-info .show > .nav-link {
  background-color: #399af2;
}
.nav-pills.nav-pills-info .nav-link.btn-floating.active {
  box-shadow: 0 5px 10px rgba(57, 154, 242, 0.4) !important;
}
.nav-pills.nav-pills-warning .nav-link.active,
.nav-pills.nav-pills-warning .show > .nav-link {
  background-color: #ffce67;
}
.nav-pills.nav-pills-warning .nav-link.btn-floating.active {
  box-shadow: 0 5px 10px rgba(255, 206, 103, 0.4) !important;
}
.nav-pills.nav-pills-danger .nav-link.active,
.nav-pills.nav-pills-danger .show > .nav-link {
  background-color: #ff5c75;
}
.nav-pills.nav-pills-danger .nav-link.btn-floating.active {
  box-shadow: 0 5px 10px rgba(255, 92, 117, 0.4) !important;
}
.nav-pills.nav-pills-light .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.nav-pills.nav-pills-light .nav-item .nav-link.active,
.nav-pills.nav-pills-light .nav-item .nav-link:hover {
  color: #fff;
}
.nav-pills.nav-pills-light .nav-link.active,
.nav-pills.nav-pills-light .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.1);
}
.tooltip {
  opacity: 1 !important;
}
.bs-tooltip-top .arrow {
  bottom: 1px;
}
.bs-tooltip-top .arrow::before {
  border-top-color: #151726 !important;
}
.bs-tooltip-right .arrow {
  left: 1px;
}
.bs-tooltip-right .arrow::before {
  border-right-color: #151726 !important;
}
.bs-tooltip-left .arrow {
  right: 1px;
}
.bs-tooltip-left .arrow::before {
  border-left-color: #151726 !important;
}
.bs-tooltip-bottom .arrow {
  top: 1px;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #151726 !important;
}
.tooltip-inner {
  background-color: #151726;
  color: #e7f1fd;
  box-shadow: 0 1px 3px 0 rgba(108, 158, 195, 0.35);
  font-size: 0.6875rem;
  border-radius: 30px;
  padding: 7px 15px;
  text-align: center;
  min-width: 80px;
}
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-1,
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-2,
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-3 {
  font-size: 100px;
  display: block;
  text-align: center;
}
.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem -7px;
}
.popover .popover-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bs-popover-auto[x-placement^='top'] .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.7rem 0.7rem 0;
}
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::before {
  border-top-color: transparent;
}
.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-bottom .arrow::before,
.bs-popover-top .arrow::before {
  border-bottom-color: transparent;
}
.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.bs-popover-bottom .arrow::after {
  border-width: 0 0.7rem 0.7rem;
  top: -5px;
}
.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::before {
  border-left-color: transparent;
}
.bs-popover-auto[x-placement^='left'] .arrow::after,
.bs-popover-left .arrow::after {
  right: -22px;
  border-width: 0.7rem;
  top: -9px;
}
.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::before {
  border-right-color: transparent;
}
.bs-popover-auto[x-placement^='right'] .arrow::after,
.bs-popover-right .arrow::after {
  left: -22px;
  border-width: 0.7rem;
  top: -9px;
}
.card[data-widget='dropzone'] .card-body .dropzone {
  margin: 50px;
  background: #f0f6ff;
  transition: all 0.3s ease;
}
.card[data-widget='dropzone'] .card-body .dropzone#singleFileUpload {
  border: 2px dashed #7468bd;
}
.card[data-widget='dropzone'] .card-body .dropzone#multiFileUpload {
  border: 2px dashed #f64a91;
}
.card[data-widget='dropzone'] .card-body .dropzone#fileTypeValidation {
  border: 2px dashed #42a4f4;
}
.card[data-widget='dropzone'] .card-body .dropzone:hover {
  margin: 20px;
  cursor: pointer;
}
.card[data-widget='dropzone'] .card-body .dropzone:hover .btn {
  margin-top: 0;
}
.card[data-widget='dropzone'] .card-body .dz-message {
  background: #f0f6ff;
  padding: 20px;
  transition: all 0.3s ease;
  border-radius: 5px;
}
.card[data-widget='dropzone'] .card-body .dz-message .btn {
  margin-top: 15px;
}
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-1:before {
  background: #5c84e8;
  background: linear-gradient(135deg, #5c84e8 1%, #7468bd 65%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-2:before {
  background: #640aef;
  background: linear-gradient(135deg, #640aef 0, #f64a91 82%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card[data-widget='dropzone'] .card-body .dz-message i.gradient-3:before {
  background: #2ec7b1;
  background: linear-gradient(135deg, #2ec7b1 1%, #42a4f4 65%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search-wrapper .search-button-submit {
  padding: 0;
  margin: 0;
  border: 0;
  background: 0 0;
  position: absolute;
  top: 11px;
  left: 16px;
  opacity: 0.6;
}
.search-wrapper .search-button-submit i {
  font-size: 22px;
}
.search-wrapper .search-input {
  width: 100%;
  border: 0;
  background-color: #f0f6ff;
  border-radius: 30px;
  height: 47px;
  text-indent: 45px;
  transition: width 0.3s linear;
}
.search-wrapper .close-search-button i {
  position: absolute;
  top: 12px;
  font-size: 22px;
  right: 18px;
  display: none;
}
.search-wrapper.page-search .search-input {
  display: block;
  width: 100%;
  padding: 1.0625rem 5rem 1.0625rem 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #43464c;
  background-image: none;
  background-clip: padding-box;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
  transition: all, 0.25s;
  border-radius: 30px;
  height: auto;
  background-color: #fff;
  text-indent: 25px;
}
.search-wrapper.page-search .search-input:active,
.search-wrapper.page-search .search-input:focus {
  box-shadow: 0 16px 32px 0 rgba(100, 143, 195, 0.28), 0 0 0 transparent;
}
.search-wrapper.page-search .search-button-submit {
  top: 16px;
  left: 18px;
}
.draggable:hover {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.card {
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border: none;
  margin-bottom: 1.875rem;
  transition: all 0.3s ease;
}
.card .card-header {
  padding: 15px 20px;
  position: relative;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
  background-color: transparent;
  min-height: 45px;
}
.card .card-header.text-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.card .card-footer {
  padding: 1rem 1.25rem;
  font-size: 1.1rem;
  font-weight: 400;
  background-color: transparent;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
}
.card .card-block {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card .shadow-on-hover {
  box-shadow: none;
  transition: all 0.3s ease;
}
.card .shadow-on-hover:hover {
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
  cursor: pointer;
}
.card.card-elevate {
  transition: box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  will-change: transform, box-shadow;
}
.card.card-elevate:hover {
  -webkit-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
  box-shadow: 0 16px 32px 0 rgba(100, 143, 195, 0.28), 0 0 0 transparent;
  cursor: pointer;
}
.card.card-overlay {
  border: thin solid #e7f0ff;
}
.card.card-danger .card-header,
.card.card-dark .card-header,
.card.card-info .card-header,
.card.card-success .card-header,
.card.card-warning .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card.card-danger .card-footer,
.card.card-dark .card-footer,
.card.card-info .card-footer,
.card.card-success .card-footer,
.card.card-warning .card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.card.card-overlay .card-img-top {
  position: absolute;
  z-index: 0;
}
.card.card-overlay .card-body {
  z-index: 5;
  background: #fff;
  margin-top: 130px;
  padding: 10px;
}
.card .card-link {
  font-size: 0.875rem;
}
.card.card-success {
  background: #2fbfa0;
}
.card.card-success .card-header .actions div > a > i,
.card.card-success .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-success .card-body p,
.card.card-success .card-body span,
.card.card-success .card-footer p,
.card.card-success .card-footer span,
.card.card-success .card-header,
.card.card-success .card-header .actions div > a > i:hover,
.card.card-success .card-header .actions li > a > i:hover,
.card.card-success .card-title {
  color: #fff;
}
.card.card-info {
  background: #399af2;
}
.card.card-info .card-header .actions div > a > i,
.card.card-info .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-info .card-body p,
.card.card-info .card-body span,
.card.card-info .card-footer p,
.card.card-info .card-footer span,
.card.card-info .card-header,
.card.card-info .card-header .actions div > a > i:hover,
.card.card-info .card-header .actions li > a > i:hover,
.card.card-info .card-title {
  color: #fff;
}
.card.card-warning {
  background: #ffce67;
}
.card.card-warning .card-header .actions div > a > i,
.card.card-warning .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-warning .card-body p,
.card.card-warning .card-body span,
.card.card-warning .card-footer p,
.card.card-warning .card-footer span,
.card.card-warning .card-header,
.card.card-warning .card-header .actions div > a > i:hover,
.card.card-warning .card-header .actions li > a > i:hover,
.card.card-warning .card-title {
  color: #fff;
}
.card.card-danger {
  background: #ff5c75;
}
.card.card-danger .card-header .actions div > a > i,
.card.card-danger .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-danger .card-body p,
.card.card-danger .card-body span,
.card.card-danger .card-footer p,
.card.card-danger .card-footer span,
.card.card-danger .card-header,
.card.card-danger .card-header .actions div > a > i:hover,
.card.card-danger .card-header .actions li > a > i:hover,
.card.card-danger .card-title {
  color: #fff;
}
.card.card-dark {
  background: #151726;
}
.card.card-dark .card-header .actions div > a > i,
.card.card-dark .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-border-success .card-header .card-title,
.card.card-dark .card-body p,
.card.card-dark .card-body span,
.card.card-dark .card-footer p,
.card.card-dark .card-footer span,
.card.card-dark .card-header,
.card.card-dark .card-header .actions div > a > i:hover,
.card.card-dark .card-header .actions li > a > i:hover,
.card.card-dark .card-title {
  color: #fff;
}
.card.card-border-success {
  border: 1px solid #2fbfa0;
}
.card.card-border-success .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #2fbfa0;
  color: #fff;
}
.card.card-border-success .card-header .actions div > a > i,
.card.card-border-success .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-border-info .card-header .card-title,
.card.card-border-success .card-header .actions div > a > i:hover,
.card.card-border-success .card-header .actions li > a > i:hover {
  color: #fff;
}
.card.card-border-info {
  border: 1px solid #399af2;
}
.card.card-border-info .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #399af2;
  color: #fff;
}
.card.card-border-info .card-header .actions div > a > i,
.card.card-border-info .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-border-info .card-header .actions div > a > i:hover,
.card.card-border-info .card-header .actions li > a > i:hover,
.card.card-border-warning .card-header .card-title {
  color: #fff;
}
.card.card-border-warning {
  border: 1px solid #ffce67;
}
.card.card-border-warning .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffce67;
  color: #fff;
}
.card.card-border-warning .card-header .actions div > a > i,
.card.card-border-warning .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-border-danger .card-header .card-title,
.card.card-border-warning .card-header .actions div > a > i:hover,
.card.card-border-warning .card-header .actions li > a > i:hover {
  color: #fff;
}
.card.card-border-danger {
  border: 1px solid #ff5c75;
}
.card.card-border-danger .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #ff5c75;
  color: #fff;
}
.actions li > a:hover,
.dropdown .dropdown-menu .actions li a.btn-fab:hover {
  background-color: transparent;
}
.card.card-border-danger .card-header .actions div > a > i,
.card.card-border-danger .card-header .actions li > a > i {
  color: rgba(255, 255, 255, 0.5);
}
.card.card-border-danger .card-header .actions div > a > i:hover,
.card.card-border-danger .card-header .actions li > a > i:hover {
  color: #fff;
}
.actions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 5;
}
.actions > .dropdown > a.btn-fab,
.actions > a.btn-fab,
.actions > li > a.btn-fab {
  text-align: center;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  padding-top: 5px;
}
.actions > .dropdown > a.btn-fab > i,
.actions > a.btn-fab > i,
.actions > li > a.btn-fab > i {
  font-size: 1.125rem;
}
.actions > li {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
}
.actions.top-left {
  left: 20px;
  top: 15px;
}
.actions.top-right {
  right: 20px;
  top: 15px;
}
.actions.bottom-left {
  left: 20px;
  bottom: 15px;
}
.actions.bottom-right {
  right: 20px;
  bottom: 15px;
}
.actions li a i,
.actions li button i {
  vertical-align: middle;
  font-size: 1.125rem;
}
.dropdown .dropdown-menu .actions li a.btn-fab {
  display: inline;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}
.line-wrap,
.line-wrap .line {
  width: 18px;
  -o-transition: all;
}
.actions > a.btn-fab > i,
.actions > li > a.btn-fab > i {
  font-size: 1.125rem;
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
}
.actions.open > a:before,
.actions > li.open > a:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.actions.open {
  z-index: 4;
}
.action-header {
  padding: 25px 30px;
  line-height: 100%;
  position: relative;
  z-index: 1;
  min-height: 65px;
}
.action-header .actions {
  position: absolute;
  top: 18px;
  right: 17px;
  z-index: 10;
}
.lg-header .actions {
  position: absolute;
  top: 5px;
  right: 10px;
}
.card.card-expand {
  transition: all 0.3s ease;
  display: block;
  position: fixed;
  z-index: 10001;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
}
.card.card-expand .card-header .actions [data-q-action='card-collapsed'] {
  display: none;
}
.card.card-expand .card-body {
  height: calc(100vh - 65px);
}
.card .card-header .actions i {
  transition: all 0.2s ease;
}
.card .card-header .actions i.collapsedRotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin-top: -17px;
  transition: all 0.2s ease;
}
.swatch,
.swatch:hover {
  transition: all 0.3s ease;
}
.card.card-notification .card-body {
  overflow: hidden;
  padding: 20px;
}
.card.card-notification .card-body .card-container-wrapper {
  width: 720px;
  overflow: hidden;
}
.card.card-notification .card-body .card-container-wrapper .card-container {
  width: 290px;
  float: left;
  padding: 10px;
  left: 0;
  position: relative;
}
.card.card-notification .card-body .card-container-wrapper .card-container .title {
  font-size: 1rem;
  display: block;
  color: #7c99ba;
}
.badge-xs:empty,
.badge:empty,
.breadcrumb-wrapper {
  display: inline-block;
}
.card.card-notification .card-body .card-container-wrapper .card-container .title i {
  font-size: 1.25rem;
  margin-right: 10px;
  vertical-align: top;
  color: #7c99ba;
}
#traffic-month,
#traffic-week,
#traffic-year {
  position: relative;
}
#traffic-month .ct-series-a .ct-line,
#traffic-week .ct-series-a .ct-line,
#traffic-year .ct-series-a .ct-line {
  stroke: #399af2;
  stroke-width: 1px;
}
#traffic-month .ct-series-a .ct-point,
#traffic-week .ct-series-a .ct-point,
#traffic-year .ct-series-a .ct-point {
  stroke: #399af2;
  stroke-width: 5px;
}
#traffic-month .ct-series-b .ct-line,
#traffic-week .ct-series-b .ct-line,
#traffic-year .ct-series-b .ct-line {
  stroke: #2fbfa0;
  stroke-width: 1px;
}
#traffic-month .ct-series-b .ct-point,
#traffic-week .ct-series-b .ct-point,
#traffic-year .ct-series-b .ct-point {
  stroke: #2fbfa0;
  stroke-width: 5px;
}
#traffic-month .ct-series-a .ct-area,
#traffic-week .ct-series-a .ct-area,
#traffic-year .ct-series-a .ct-area {
  fill: #399af2;
  fill-opacity: 0.2;
}
#traffic-month .ct-series-b .ct-area,
#traffic-week .ct-series-b .ct-area,
#traffic-year .ct-series-b .ct-area {
  fill: #2fbfa0;
  fill-opacity: 0.2;
}
.card.card-gauage #month-budget-gauage {
  position: absolute;
}
.card-ecom-sale:after {
  content: '';
  position: absolute;
  top: 40%;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #ac589a;
  z-index: 5;
}
.swatch {
  padding: 12px;
  border-radius: 100px;
  position: relative;
  margin-right: 5px;
  border: 1px solid #fff;
}
.swatch:hover {
  cursor: pointer;
}
.swatch:hover:before {
  content: ' ';
  position: absolute;
  border: 2px solid #93acc6;
  top: -3px;
  left: -3px;
  right: 0;
  bottom: 0;
  padding: 13px;
  border-radius: 100px;
}
.swatch:active,
.swatch:focus {
  outline: 0;
}
.swatch.swatch-color_grey-200 {
  background-color: #cbdaea;
}
.swatch.swatch-color_danger {
  background-color: #ff5c75;
}
.swatch.swatch-color_info {
  background-color: #399af2;
}
.swatch.swatch-color_warning {
  background-color: #ffce67;
}
.swatch.swatch-color_success {
  background-color: #2fbfa0;
}
.swatch.swatch-color_black {
  background-color: #000;
}
.date-picker-widget {
  position: relative;
}
.date-picker-widget input {
  box-shadow: 0 3px 20px 0 rgba(18, 106, 211, 0.1);
  border-color: transparent !important;
  background-color: #fff;
  border-radius: 30px;
  width: auto;
  text-indent: 10px;
  color: #788db4;
  font-weight: 400;
  padding: 8px 20px 8px 12px;
}
.date-picker-widget input:focus {
  color: #788db4;
  font-weight: 400;
}
.date-picker-widget i.cal-icon {
  position: absolute;
  right: 17px;
  color: #788db4;
  font-size: 1.1875rem;
  top: 8px;
}
.date-picker-widget:hover {
  cursor: pointer;
}
.card.card-menu .metismenu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.card.card-menu .metismenu .nav-sub > li > a {
  padding: 8px 0 8px 40px;
}
.card.card-menu .metismenu .nav-dropdown .nav-sub li:last-child {
  padding: 0;
}
.alert {
  color: #fff;
  font-size: 0.875rem;
}
.alert .alert-link {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  border-bottom: dotted 1px rgba(255, 255, 255, 0.85);
  transition: all 0.15s ease;
}
.alert .alert-link:hover {
  color: #fff;
  border-bottom: none;
}
.alert p {
  color: #fff;
}
.alert.alert-dismissible .close {
  text-shadow: none;
  padding: 9px 15px;
  color: rgba(255, 255, 255, 0.75);
}
.alert.alert-dismissible .close span {
  font-size: 1.125rem;
}
.alert.alert-dismissible .close:hover {
  cursor: pointer;
  color: #fff;
}
.alert.alert-dismissible .close:hover:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.125);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 11px;
}
.alert.alert-dismissible .close:active,
.alert.alert-dismissible .close:focus {
  outline: 0;
}
.alert.alert-rounded {
  border-radius: 1.875rem;
}
.alert .icon {
  position: absolute;
  left: 15px;
  top: 8px;
}
.alert.alert-danger.alert-outline.alert-dismissible .close:hover:before,
.alert.alert-info.alert-outline.alert-dismissible .close:hover:before,
.alert.alert-secondary.alert-outline.alert-dismissible .close:hover:before,
.alert.alert-success.alert-outline.alert-dismissible .close:hover:before,
.alert.alert-warning.alert-outline.alert-dismissible .close:hover:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 0;
  left: 0;
  margin: 11px;
  right: 0;
  bottom: 0;
}
.alert .icon i {
  font-size: 1.875rem;
}
.alert .text {
  padding-left: 40px;
}
.alert.alert-secondary {
  background-color: #788db4;
  border-color: #788db4;
  color: #fff;
}
.alert.alert-secondary.alert-outline {
  background-color: #fff;
  color: #788db4;
}
.alert.alert-secondary.alert-outline .alert-link {
  color: #788db4;
  border-bottom: dotted 1px rgba(120, 141, 180, 0.85);
}
.alert.alert-secondary.alert-outline .alert-link:hover {
  color: #788db4;
  border-bottom: none;
}
.alert.alert-secondary.alert-outline .icon i {
  color: #788db4;
}
.alert.alert-secondary.alert-outline.alert-dismissible .close {
  color: rgba(120, 141, 180, 0.85);
}
.alert.alert-secondary.alert-outline.alert-dismissible .close:hover {
  color: #788db4;
}
.alert.alert-secondary.alert-outline.alert-dismissible .close:hover:before {
  background-color: rgba(120, 141, 180, 0.125);
}
.alert.alert-success {
  background-color: #2fbfa0;
  border-color: #2fbfa0;
  color: #fff;
}
.alert.alert-success.alert-outline {
  background-color: #fff;
  color: #2fbfa0;
}
.alert.alert-success.alert-outline .alert-link {
  color: #2fbfa0;
  border-bottom: dotted 1px rgba(47, 191, 160, 0.85);
}
.alert.alert-success.alert-outline .alert-link:hover {
  color: #2fbfa0;
  border-bottom: none;
}
.alert.alert-success.alert-outline.alert-dismissible .close {
  color: rgba(47, 191, 160, 0.85);
}
.alert.alert-success.alert-outline.alert-dismissible .close:hover {
  color: #2fbfa0;
}
.alert.alert-success.alert-outline.alert-dismissible .close:hover:before {
  background-color: rgba(47, 191, 160, 0.125);
}
.alert.alert-info {
  background-color: #399af2;
  border-color: #399af2;
  color: #fff;
}
.alert.alert-info.alert-outline {
  background-color: #fff;
  color: #399af2;
}
.alert.alert-info.alert-outline .alert-link {
  color: #399af2;
  border-bottom: dotted 1px rgba(57, 154, 242, 0.85);
}
.alert.alert-info.alert-outline .alert-link:hover {
  color: #399af2;
  border-bottom: none;
}
.alert.alert-info.alert-outline.alert-dismissible .close {
  color: rgba(57, 154, 242, 0.85);
}
.alert.alert-info.alert-outline.alert-dismissible .close:hover {
  color: #399af2;
}
.alert.alert-info.alert-outline.alert-dismissible .close:hover:before {
  background-color: rgba(57, 154, 242, 0.125);
}
.alert.alert-warning {
  background-color: #ffce67;
  border-color: #ffce67;
  color: #fff;
}
.alert.alert-warning.alert-outline {
  background-color: #fff;
  color: #ffce67;
}
.alert.alert-warning.alert-outline .alert-link {
  color: #ffce67;
  border-bottom: dotted 1px rgba(255, 206, 103, 0.85);
}
.alert.alert-warning.alert-outline .alert-link:hover {
  color: #ffce67;
  border-bottom: none;
}
.alert.alert-warning.alert-outline.alert-dismissible .close {
  color: rgba(255, 206, 103, 0.85);
}
.alert.alert-warning.alert-outline.alert-dismissible .close:hover {
  color: #ffce67;
}
.alert.alert-warning.alert-outline.alert-dismissible .close:hover:before {
  background-color: rgba(255, 206, 103, 0.125);
}
.alert.alert-danger {
  background-color: #ff5c75;
  border-color: #ff5c75;
  color: #fff;
}
.alert.alert-danger.alert-outline {
  background-color: #fff;
  color: #ff5c75;
}
.alert.alert-danger.alert-outline .alert-link {
  color: #ff5c75;
  border-bottom: dotted 1px rgba(255, 92, 117, 0.85);
}
.alert.alert-danger.alert-outline .alert-link:hover {
  color: #ff5c75;
  border-bottom: none;
}
.alert.alert-danger.alert-outline.alert-dismissible .close {
  color: rgba(255, 92, 117, 0.85);
}
.alert.alert-danger.alert-outline.alert-dismissible .close:hover {
  color: #ff5c75;
}
.alert.alert-danger.alert-outline.alert-dismissible .close:hover:before {
  background-color: rgba(255, 92, 117, 0.125);
}
.badge.up,
.btn-round,
.btn.btn-fab,
.file-upload {
  position: relative;
}
.badge {
  font-weight: 400;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5;
  padding: 4px 6px 2px;
}
.badge.badge-secondary {
  background-color: #788db4;
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-secondary[href]:focus,
.badge.badge-secondary[href]:hover {
  text-decoration: none;
  background-color: #889bbd !important;
  color: #fff;
}
.badge.badge-success {
  background-color: #2fbfa0;
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-success[href]:focus,
.badge.badge-success[href]:hover {
  text-decoration: none;
  background-color: #39ceae !important;
  color: #fff;
}
.badge.badge-danger {
  background-color: #ff5c75;
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-danger[href]:focus,
.badge.badge-danger[href]:hover {
  text-decoration: none;
  background-color: #ff768b !important;
  color: #fff;
}
.badge.badge-warning {
  background-color: #ffce67;
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-warning[href]:focus,
.badge.badge-warning[href]:hover {
  text-decoration: none;
  background-color: #ffd681 !important;
  color: #fff;
}
.badge.badge-info {
  background-color: #399af2;
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-info[href]:focus,
.badge.badge-info[href]:hover {
  text-decoration: none;
  background-color: #51a6f4 !important;
  color: #fff;
}
.badge.badge-dark {
  color: rgba(255, 255, 255, 0.85);
}
.badge.badge-dark[href]:focus,
.badge.badge-dark[href]:hover {
  color: #fff;
}
.badge.badge-light {
  background-color: rgba(234, 243, 253, 0.65) !important;
}
.badge.badge-light[href]:focus,
.badge.badge-light[href]:hover {
  background-color: #eaf3fd !important;
}
.badge.badge-circle {
  border-radius: 100%;
  width: 23px;
  height: 23px;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.6;
  padding: 4px 5px;
  vertical-align: baseline;
}
.badge.badge-clear {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.85);
}
.badge .rounded-circle {
  padding: 0;
  border-radius: 100%;
}
.badge.up {
  padding: 0.1565rem 0.245rem;
  top: -0.75rem;
  left: -0.65rem;
  margin-right: -1rem;
  font-size: 70%;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background-clip: padding-box;
  min-width: 1rem;
}
.badge.no-bg {
  background-color: transparent;
  color: inherit;
}
.badge-sm {
  padding: 0.175em 0.35em;
  min-width: 0.85rem;
}
.badge-xs {
  padding: 0 !important;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  min-width: 0 !important;
}
.badge-xs.md {
  width: 0.75rem !important;
  height: 0.75rem !important;
}
.badge-xs.lg {
  width: 1rem;
  height: 1rem;
}
.badge-o {
  border: 2px solid;
}
.btn,
a:link.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 20px;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease;
  height: 38px;
  vertical-align: middle;
  font-weight: 300;
  cursor: pointer;
}
.btn.btn-rounded,
a:link.btn.btn-rounded {
  border-radius: 50px;
  padding: 8px 25px;
}
.btn.btn-sm,
a:link.btn.btn-sm {
  padding: 0.35rem 0.75rem;
  font-size: 0.75rem;
  height: 32px;
  line-height: 1;
}
.btn.btn-lg,
a:link.btn.btn-lg {
  padding: 25px 50px !important;
  font-size: 1rem;
  line-height: 0.2;
  height: auto;
}
.btn.btn-lg i,
a:link.btn.btn-lg i {
  vertical-align: top;
  margin: -10px 0 0 5px;
  font-size: 20px;
}
.btn i,
a:link.btn i {
  vertical-align: middle !important;
  margin: -1px 4px 0;
}
.btn.btn.focus,
.btn.btn:focus,
a:link.btn.btn.focus,
a:link.btn.btn:focus {
  box-shadow: none;
}
.btn-secondary,
a:link.btn-secondary {
  background-color: #fff;
  border: 1px solid #d8e3ef;
  color: #788db4;
}
.btn-secondary:hover,
a:link.btn-secondary:hover {
  background-color: #6e85af !important;
  border: 1px solid #6e85af !important;
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.4) !important;
  color: #fff;
}
.btn-secondary:active,
a:link.btn-secondary:active {
  background-color: #7b90b6 !important;
  border: 1px solid #7b90b6 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-secondary:focus,
a:link.btn-secondary:focus {
  background-color: #788db4;
  border: 1px solid #788db4;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-secondary.btn-floating,
a:link.btn-secondary.btn-floating {
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.4) !important;
}
.btn-secondary.btn-floating:active,
.btn-secondary.btn-floating:hover,
a:link.btn-secondary.btn-floating:active,
a:link.btn-secondary.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(120, 141, 180, 0.4) !important;
}
.btn-secondary.btn-outline,
a:link.btn-secondary.btn-outline {
  background-color: #fff;
  color: #788db4;
}
.btn-secondary.btn-outline:focus,
a:link.btn-secondary.btn-outline:focus {
  background-color: #fff !important;
  color: #788db4 !important;
}
.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline:hover,
a:link.btn-secondary.btn-outline:active,
a:link.btn-secondary.btn-outline:hover {
  color: #fff !important;
  background-color: #788db4 !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: rgba(141, 166, 195, 0.8);
  background-color: transparent;
  border-color: #d8e3ef;
}
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:active,
.btn-secondary:disabled:focus,
.btn-secondary:disabled:hover {
  color: rgba(141, 166, 195, 0.8) !important;
  background: 0 0 !important;
  border-color: #d8e3ef !important;
  box-shadow: none !important;
}
.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  border: 1px solid #788db4;
}
.btn-success {
  background-color: #2fbfa0;
  border: 1px solid #2fbfa0;
  color: rgba(255, 255, 255, 0.85);
}
.btn-success:hover {
  background-color: #2cb396 !important;
  border: 1px solid #2cb396 !important;
  box-shadow: 0 5px 10px rgba(47, 191, 160, 0.4) !important;
  color: #fff;
}
.btn-success:active,
.btn-success:focus {
  color: #fff !important;
  box-shadow: none !important;
}
.btn-success:active {
  background-color: #30c3a3 !important;
  border: 1px solid #30c3a3 !important;
}
.btn-success:focus {
  background-color: #2fbfa0;
  border: 1px solid #2fbfa0;
}
.btn-success.btn-floating {
  box-shadow: 0 5px 10px rgba(47, 191, 160, 0.4) !important;
}
.btn-success.btn-floating:active,
.btn-success.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(47, 191, 160, 0.4) !important;
}
.btn-success.btn-outline {
  background-color: #fff;
  color: #2fbfa0;
}
.btn-success.btn-outline:focus {
  background-color: #fff !important;
  color: #2fbfa0 !important;
}
.btn-success.btn-outline:active,
.btn-success.btn-outline:hover {
  color: #fff !important;
  background-color: #2fbfa0 !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: rgba(47, 191, 160, 0.85);
  border-color: rgba(47, 191, 160, 0.85);
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #2fbfa0;
  border: 1px solid #2fbfa0;
  color: rgba(255, 255, 255, 0.85);
}
.btn-info,
a:link.btn-info {
  background-color: #399af2;
  border: 1px solid #399af2;
  color: rgba(255, 255, 255, 0.85);
}
.btn-info:hover,
a:link.btn-info:hover {
  background-color: #2b93f1 !important;
  border: 1px solid #2b93f1 !important;
  box-shadow: 0 5px 10px rgba(57, 154, 242, 0.4) !important;
  color: #fff;
}
.btn-info:active,
a:link.btn-info:active {
  background-color: #3e9cf2 !important;
  border: 1px solid #3e9cf2 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-info:focus,
a:link.btn-info:focus {
  background-color: #399af2;
  border: 1px solid #399af2;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-info.btn-floating,
a:link.btn-info.btn-floating {
  box-shadow: 0 5px 10px rgba(57, 154, 242, 0.4) !important;
}
.btn-info.btn-floating:active,
.btn-info.btn-floating:hover,
a:link.btn-info.btn-floating:active,
a:link.btn-info.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(57, 154, 242, 0.4) !important;
}
.btn-info.btn-outline,
a:link.btn-info.btn-outline {
  background-color: #fff;
  color: #399af2;
}
.btn-info.btn-outline:focus,
a:link.btn-info.btn-outline:focus {
  background-color: #fff !important;
  color: #399af2 !important;
}
.btn-info.btn-outline:active,
.btn-info.btn-outline:hover,
a:link.btn-info.btn-outline:active,
a:link.btn-info.btn-outline:hover {
  color: #fff !important;
  background-color: #399af2 !important;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: rgba(57, 154, 242, 0.85);
  border-color: rgba(57, 154, 242, 0.85);
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: #399af2;
  border: 1px solid #399af2;
  color: rgba(255, 255, 255, 0.85);
}
.btn-warning,
a:link.btn-warning {
  background-color: #ffce67;
  border: 1px solid #ffce67;
  color: rgba(255, 255, 255, 0.85);
}
.btn-warning:hover,
a:link.btn-warning:hover {
  background-color: #ffc958 !important;
  border: 1px solid #ffc958 !important;
  box-shadow: 0 5px 10px rgba(255, 206, 103, 0.4) !important;
  color: #fff;
}
.btn-warning:active,
a:link.btn-warning:active {
  background-color: #ffd06c !important;
  border: 1px solid #ffd06c !important;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-warning:focus,
a:link.btn-warning:focus {
  background-color: #ffce67;
  border: 1px solid #ffce67;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-warning.btn-floating,
a:link.btn-warning.btn-floating {
  box-shadow: 0 5px 10px rgba(255, 206, 103, 0.4) !important;
}
.btn-warning.btn-floating:active,
.btn-warning.btn-floating:hover,
a:link.btn-warning.btn-floating:active,
a:link.btn-warning.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(255, 206, 103, 0.4) !important;
}
.btn-warning.btn-outline,
a:link.btn-warning.btn-outline {
  background-color: #fff;
  color: #ffce67;
}
.btn-warning.btn-outline:focus,
a:link.btn-warning.btn-outline:focus {
  background-color: #fff !important;
  color: #ffce67 !important;
}
.btn-warning.btn-outline:active,
.btn-warning.btn-outline:hover,
a:link.btn-warning.btn-outline:active,
a:link.btn-warning.btn-outline:hover {
  color: #fff !important;
  background-color: #ffce67 !important;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: rgba(255, 206, 103, 0.85);
  border-color: rgba(255, 206, 103, 0.85);
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  background-color: #ffce67;
  border: 1px solid #ffce67;
  color: rgba(255, 255, 255, 0.85);
}
.btn-danger,
a:link.btn-danger {
  background-color: #ff5c75;
  border: 1px solid #ff5c75;
  color: rgba(255, 255, 255, 0.85);
}
.btn-danger:hover,
a:link.btn-danger:hover {
  background-color: #ff4d68 !important;
  border: 1px solid #ff4d68 !important;
  box-shadow: 0 5px 10px rgba(255, 92, 117, 0.4) !important;
  color: #fff;
}
.btn-danger:active,
a:link.btn-danger:active {
  background-color: #ff6179 !important;
  border: 1px solid #ff6179 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-danger:focus,
a:link.btn-danger:focus {
  background-color: #ff5c75;
  border: 1px solid #ff5c75;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-danger.btn-floating,
a:link.btn-danger.btn-floating {
  box-shadow: 0 5px 10px rgba(255, 92, 117, 0.4) !important;
}
.btn-danger.btn-floating:active,
.btn-danger.btn-floating:hover,
a:link.btn-danger.btn-floating:active,
a:link.btn-danger.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(255, 92, 117, 0.4) !important;
}
.btn-danger.btn-outline,
a:link.btn-danger.btn-outline {
  background-color: #fff;
  color: #ff5c75;
}
.btn-danger.btn-outline:focus,
a:link.btn-danger.btn-outline:focus {
  background-color: #fff !important;
  color: #ff5c75 !important;
}
.btn-danger.btn-outline:active,
.btn-danger.btn-outline:hover,
a:link.btn-danger.btn-outline:active,
a:link.btn-danger.btn-outline:hover {
  color: #fff !important;
  background-color: #ff5c75 !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: rgba(255, 92, 117, 0.85);
  border-color: rgba(255, 92, 117, 0.85);
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: #ff5c75;
  border: 1px solid #ff5c75;
  color: rgba(255, 255, 255, 0.85);
}
.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #6987ab;
  border-color: #6987ab;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(105, 135, 171, 0.14), 0 3px 1px -2px rgba(105, 135, 171, 0.2), 0 1px 5px 0 rgba(105, 135, 171, 0.12);
}
.btn-light.btn-floating,
.btn-light:hover,
a:link.btn-light.btn-floating,
a:link.btn-light:hover {
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.2) !important;
}
.btn-light,
a:link.btn-light {
  background-color: #fff;
  border-color: rgba(141, 166, 195, 0.2);
  color: #839bb3 !important;
}
.btn-light i,
a:link.btn-light i {
  color: rgba(141, 166, 195, 0.6);
}
.btn-light:hover,
a:link.btn-light:hover {
  background-color: #f0f6ff !important;
  border-color: rgba(141, 166, 195, 0.2);
}
.btn-light:hover i,
a:link.btn-light:hover i {
  color: rgba(141, 166, 195, 0.6) !important;
}
.btn-light:active,
a:link.btn-light:active {
  background-color: rgba(199, 217, 238, 0.4) !important;
  border-color: rgba(141, 166, 195, 0.2) !important;
}
.btn-light.btn-floating:active,
.btn-light.btn-floating:hover,
a:link.btn-light.btn-floating:active,
a:link.btn-light.btn-floating:hover {
  box-shadow: 0 15px 20px rgba(120, 141, 180, 0.2) !important;
}
.btn-round {
  border-radius: 100%;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  fill: #fff;
}
.btn-pill {
  border-radius: 60px;
}
.btn-more {
  margin: 40px auto;
  text-align: center;
}
.btn-link {
  background-color: transparent;
  border: none;
  padding: 6px 17px;
}
.btn-link.active,
.btn-link.disabled,
.btn-link:active,
.btn-link:focus,
.btn-link:hover,
.btn-link[disabled] {
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: 0;
}
.btn.btn-icon {
  padding: 7px 16px;
}
.custom-file-label.btn-icon,
.custom-file-label.btn-icon::after {
  padding: 10px 16px;
}
.btn.btn-tranparent {
  background-color: transparent;
  border: 0;
  height: 30px;
  width: 30px;
  padding: 3px 5px;
  margin: 0 7px;
}
.btn.btn-tranparent i {
  font-size: 1.25rem;
}
.btn.btn-tranparent:hover i {
  color: #353a40;
}
.btn.btn-tranparent:active,
.btn.btn-tranparent:focus {
  outline: 0;
  box-shadow: none;
}
.btn.btn-fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  width: 56px;
  padding: 0;
  line-height: normal;
}
.file-upload {
  display: inline-block;
}
.file-upload__label {
  display: block;
  padding: 1em 2em;
  color: #fff;
  background: #222;
  border-radius: 0.4em;
  transition: background 0.3s;
}
.file-upload__label:hover {
  cursor: pointer;
  background: #000;
}
.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}
.icon {
  display: inline-block;
}
i {
  color: #788db4;
}
.icon-rounded {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  text-align: center;
}
.icon-rounded i {
  font-size: 24px;
  padding-top: 12px;
}
.icon-rounded.icon-rounded-success {
  background-color: rgba(47, 191, 160, 0.4);
}
.icon-rounded.icon-rounded-success i {
  color: #2fbfa0;
}
.icon-rounded.icon-rounded-info {
  background-color: rgba(57, 154, 242, 0.4);
}
.icon-rounded.icon-rounded-info i {
  color: #399af2;
}
.list-group-item {
  border: 1px solid rgba(210, 221, 234, 0.3);
  font-size: 0.875rem;
}
.list-group-item.list-group-item-secondary {
  border: 1px solid rgba(120, 141, 180, 0.5);
}
.list-group-item.list-group-item-danger,
.list-group-item.list-group-item-dark,
.list-group-item.list-group-item-info,
.list-group-item.list-group-item-light,
.list-group-item.list-group-item-success,
.list-group-item.list-group-item-warning {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item-secondary {
  color: #ebeef4;
  background-color: #788db4;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: rgba(210, 221, 234, 0.5);
}
.list-group-item-success {
  color: #b4ede0;
  background-color: #2fbfa0;
}
.list-group-item-danger {
  color: #fff;
  background-color: #ff5c75;
}
.list-group-item-warning {
  color: #fff;
  background-color: #ffce67;
}
.list-group-item-info {
  color: #e1effd;
  background-color: #399af2;
}
.list-group-item-light {
  color: #4d5a68;
  background-color: #b6cade;
}
.list-group-item-dark {
  color: #b6cade;
  background-color: #4d5a68;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #b1c6e0;
  background-color: #eff5fd;
}
.list-group-item.disabled:hover,
.list-group-item:disabled:hover {
  cursor: not-allowed;
}
.list-group-flush {
  padding-bottom: 15px;
}
.list-group.list-group-no-border li {
  border: none !important;
  padding: 10px 20px;
}
.timeline,
.timeline .timeline-list {
  position: relative;
  border-color: #e5ebf8;
}
.timeline .timeline-list {
  padding-bottom: 1px;
}
.timeline .timeline-list:before {
  display: table;
  content: ' ';
}
.timeline .timeline-list:after {
  display: table;
  position: absolute;
  top: 18px;
  left: 0;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  content: '';
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 0 3px #e5ebf8 inset;
}
.timeline-secondary:after {
  box-shadow: 0 0 0 3px #788db4 inset !important;
}
.timeline .timeline-list .timeline-info {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 0.875rem;
}
.timeline-border {
  border-left: 1px solid #e5ebf8;
}
.timeline-list.timeline-border {
  margin-left: -1px;
}
.timeline-secondary {
  border-color: #788db4 !important;
}
.timeline-success {
  border-color: #2fbfa0 !important;
}
.timeline-success:after {
  box-shadow: 0 0 0 3px #2fbfa0 inset !important;
}
.timeline-warning {
  border-color: #ffce67 !important;
}
.timeline-warning:after {
  box-shadow: 0 0 0 3px #ffce67 inset !important;
}
.timeline-info {
  border-color: #399af2 !important;
}
.timeline-info:after {
  box-shadow: 0 0 0 3px #399af2 inset !important;
}
.timeline-danger {
  border-color: #ff5c75 !important;
}
.timeline-danger:after {
  box-shadow: 0 0 0 3px #ff5c75 inset !important;
}
.list-group.user-group .list-group-item {
  display: inline;
  position: relative;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
}
.list-group.user-group .list-group-item .remove-guests {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: -36px;
  left: -18px;
  cursor: pointer;
  color: #f44336;
  z-index: 99;
}
.list-group.user-group .list-group-item .remove-guests i {
  font-size: 20px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition-delay: 0.2s;
}
.user-group .list-group-item:hover .remove-guests {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
}
.user-group .list-group-item:hover .remove-guests i {
  font-size: 20px;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.list-group.user-group .list-group-item.active,
.list-group.user-group .list-group-item:focus,
.list-group.user-group .list-group-item:hover {
  background-color: #fff;
  cursor: default;
}
.control.control-outline,
.custom-file-label::after:hover,
.input-group-addon.action {
  cursor: pointer;
}
.user-group .list-group-item:not(.dropdown):hover img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  cursor: pointer;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.user-group .list-group-item img {
  position: relative;
  margin-left: -13px;
  border: 2px solid #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.social-links li a {
  -webkit-border-radius: 50%;
  background: #f0f6ff;
  border-radius: 50%;
  color: #788db4;
  display: inline-block;
  height: 35px;
  line-height: 3;
  text-align: center;
  width: 35px;
}
.social-links li a i {
  font-size: 1.25rem;
}
.preloader {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}
.preloader:not([class*='pl-']) {
  width: 40px;
}
.preloader:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.box,
.media {
  display: flex;
}
.preloader.pl-xxs {
  width: 25px;
}
.preloader.pl-xs {
  width: 50px;
}
.preloader.pl-sm {
  width: 75px;
}
.preloader.pl-lg {
  width: 100px;
}
.preloader.pl-xl {
  width: 150px;
}
.preloader.pl-xxl {
  width: 200px;
}
.preloader:not([class*='pls-']) .plc-path {
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
}
.preloader[class*='pls-'] .plc-path {
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}
.preloader.pls-danger .plc-path {
  stroke: #ff5c75;
}
.preloader.pls-info .plc-path {
  stroke: #399af2;
}
.preloader.pls-success .plc-path {
  stroke: #2fbfa0;
}
.preloader.pls-warning .plc-path {
  stroke: #ffce67;
}
.preloader.pls-white .plc-path {
  stroke: #fff;
}
.pl-circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.qt-loader.qt-loader-mini:before,
.qt-loader:before {
  content: '';
  top: 57%;
  background: 0 0;
  margin-top: -0.6rem;
  opacity: 0.75;
  left: 0;
  box-sizing: border-box;
}
.plc-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #ff5c75;
  }
  25% {
    stroke: #2fbfa0;
  }
  50% {
    stroke: #ffce67;
  }
  100% {
    stroke: #399af2;
  }
}
@keyframes color {
  0% {
    stroke: #ff5c75;
  }
  25% {
    stroke: #2fbfa0;
  }
  50% {
    stroke: #ffce67;
  }
  100% {
    stroke: #399af2;
  }
}
.qt-loader {
  position: relative;
  z-index: 999;
}
.qt-loader:before {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  animation: clockwise 0.5s linear infinite;
}
.qt-loader.qt-loader-mini:before {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  -webkit-animation: clockwise 0.5s linear infinite;
  animation: clockwise 0.5s linear infinite;
}
.qt-loader.qt-loader-left:before {
  right: auto;
  left: 0;
  margin-left: 13px;
}
.qt-loader.qt-loader-right {
  padding-right: 40px;
}
.qt-loader.qt-loader-right:before {
  left: auto;
  right: 0;
  margin-right: 13px;
}
.qt-loader.qt-loader-secondary:before {
  border: 2px solid #788db4;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader.qt-loader-success:before {
  border: 2px solid #2fbfa0;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader:hover.qt-loader-success:before {
  border: 2px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader.qt-loader-danger:before {
  border: 2px solid #ff5c75;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader:hover.qt-loader-danger:before {
  border: 2px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader.qt-loader-info:before {
  border: 2px solid #399af2;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader:hover.qt-loader-info:before,
.qt-loader:hover.qt-loader-secondary:before {
  border: 2px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader.qt-loader-warning:before {
  border: 2px solid #ffce67;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader:hover.qt-loader-warning:before {
  border: 2px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.qt-loader.qt-loader-center {
  position: absolute;
  left: 50%;
  top: 50%;
}
@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes clockwise {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes donut-rotate {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-140deg);
    transform: rotate(-140deg);
  }
}
@keyframes donut-rotate {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-140deg);
    transform: rotate(-140deg);
  }
}
.progress {
  border-radius: 30px;
}
.progress.active .progress-bar {
  transition: none !important;
}
.media {
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.media-body p {
  font-size: 0.875rem;
  padding: 0;
}
.media-body span {
  font-size: 0.75rem;
  font-weight: 400;
}
.tile-secondary:hover {
  background-color: #788db4 !important;
  box-shadow: 0 15px 20px rgba(120, 141, 180, 0.4) !important;
  color: #fff !important;
}
.tile-secondary:hover i {
  color: #fff !important;
}
.box {
  position: relative;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
}
.box .box-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
}
.box .box-header h3 {
  font-size: 1rem;
  margin: 0;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-file-label,
.custom-file-label:after,
.form-control,
.form-control-plaintext,
.form-control:focus,
.table {
  font-size: 0.875rem;
}
.box .box-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.box .box-footer {
  padding: 0.75rem 1.25rem;
}
.table thead th {
  border-top: none;
  font-weight: 500;
}
.table td,
.table th,
.table thead th {
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f0f6ff;
}
.table.table-bordered,
.table.table-bordered td,
.table.table-bordered th::after,
.table.table-bordered thead th {
  border: 1px solid rgba(234, 243, 253, 0.9);
}
.table.v-align-middle tr td {
  vertical-align: middle;
}
.table.table-borderless td,
.table.table-borderless th,
.table.table-borderless thead th {
  border: none !important;
}
table.dataTable {
  border-collapse: collapse !important;
}
.dataTables_info,
.dataTables_paginate {
  margin: 25px 0 15px !important;
}
.pagination .page-item.disabled .page-link,
.pagination .page-link {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #7468bd;
  border-color: #7468bd;
  border-radius: 100%;
  box-shadow: none;
  outline: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 0 0;
}
.pagination a:link,
.pagination a:visited {
  color: #353a40;
  line-height: 1;
}
.pagination a:hover {
  color: #7468bd;
}
.custom-file-label,
.custom-file-label:after,
.form-control {
  color: #839bb3;
}
.pagination a:active,
.pagination a:focus {
  box-shadow: none;
  outline: 0;
}
.table-bordered.dataTable,
.table-bordered.dataTable td,
.table-bordered.dataTable th {
  border: 1px solid rgba(234, 243, 253, 0.9);
}
table tfoot tr th,
table thead tr th {
  font-weight: 400;
}
table tbody tr td {
  font-weight: 300;
}
.form-control {
  border: 1px solid transparent;
}
.form-control:focus {
  box-shadow: none;
}
.form-control.no-focus-border:focus {
  border: 0;
}
.form-control.input-rounded {
  border-radius: 20px;
}
.form-horizontal .form-group .control-label {
  margin-top: 7px;
}
.custom-file-input:lang(en) ~ .custom-file-label.file-icon::after,
.custom-file-label.file-icon::after {
  font-family: Material-Design-Iconic-Font;
  content: '' !important;
  padding: 5px 16px;
  font-size: 1rem;
}
.sign-in-form .custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}
.sign-in-form .custom-control .custom-control-label {
  padding-top: 1px;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  font-size: 0.6875rem;
}
.input-group-prepend .input-group-addon {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-prepend .input-group-text .control.control-outline .control__indicator {
  position: absolute;
  top: -5px;
  left: 4px;
}
.input-group-addon {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f6ff;
  border: 1px solid #dfe7f3;
  color: #96a9c6;
  border-radius: 0.25rem;
}
.input-group-text {
  font-size: 0.875rem;
}
.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
.input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon.join-addons {
  border-radius: 0;
  border-right: 0;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single,
.select2-container--default .wizard > .content > .body input {
  border: 1px solid #dfe7f3;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #323a48;
  position: relative;
  padding: 0.65rem 1rem;
  line-height: 1.25;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 0.8rem;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  min-height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 7px;
  right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  top: 1px;
  right: 15px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default.select2-container--disabled .select2-selection--single {
  border: 1px solid #dfe7f3;
  background-color: #f0f6ff;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #dfe7f3;
  outline: 0;
}
.select2-container--default .select2-results__option[aria-selected='true'] {
  color: #292b2c;
  background-color: #f0f6ff;
}
.select2-container--default .select2-container,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  outline: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba(120, 141, 180, 0.7) !important;
}
.select2-dropdown {
  border: none;
  box-shadow: 0 10px 20px rgba(194, 207, 228, 0.7);
}
.custom-file-input.is-valid ~ .custom-file-label,
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-file-input:valid ~ .custom-file-label,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #2fbfa0;
}
.invalid-tooltip,
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 0.2rem;
  max-width: 100%;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2fbfa0;
}
.valid-tooltip {
  display: none;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(47, 191, 160, 0.8);
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.form-check-input.is-valid ~ .form-check-label,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #2fbfa0;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #2fbfa0;
  box-shadow: 0 0 0 0.2rem rgba(47, 191, 160, 0.25);
}
.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #71dd8a;
}
.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 191, 160, 0.25);
}
.custom-file-input.is-valid ~ .custom-file-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label:before {
  border-color: inherit;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #ff5c75;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(47, 191, 160, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff5c75;
}
.invalid-tooltip {
  display: none;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(255, 92, 117, 0.8);
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.form-check-input.is-invalid ~ .form-check-label,
.form-group.has-error .help-block,
.form-group.has-error label.control-label,
.has-error .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #ff5c75;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ff5c75;
  box-shadow: 0 0 0 0.2rem rgba(255, 92, 117, 0.25);
}
.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #efa2a9;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 92, 117, 0.25);
}
.custom-file-input.is-invalid ~ .custom-file-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label:before {
  border-color: inherit;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 92, 117, 0.25);
}
.has-error .form-control {
  border: 1px solid #ff5c75;
}
.has-error .custom-control-label:before {
  background-color: #efa2a9;
}
.form-check {
  margin-bottom: 8px;
}
.form-check.custom-control {
  margin-bottom: 6px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-label {
  vertical-align: middle;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #839bb3;
}
.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e0ecff;
}
.custom-checkbox.checkbox-secondary .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox.checkbox-secondary .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-radio.radio-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #788db4;
}
.custom-checkbox.checkbox-success .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox.checkbox-success .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-radio.radio-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2fbfa0;
}
.custom-checkbox.checkbox-warning .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox.checkbox-warning .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-radio.radio-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffce67;
}
.custom-checkbox.checkbox-info .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox.checkbox-info .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-radio.radio-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #399af2;
}
.custom-checkbox.checkbox-danger .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox.checkbox-danger .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-radio.radio-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ff5c75;
}
.control.control-outline input:focus ~ .control__indicator,
.control.control-outline:hover input ~ .control__indicfator,
control-group {
  background: #fff;
}
control-group {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}
.control.control-outline {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 9px;
}
.control.control-outline input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control.control-outline .control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #e5e9ec;
  border-radius: 3px;
  background: #fff;
}
.control.control-outline input:checked ~ .control__indicator {
  border: 1px solid #788db4;
  background: 0 0;
}
.control.control-outline input:checked:focus ~ .control__indicator,
.control.control-outline:hover input:not([disabled]):checked ~ .control__indicator {
  background: #fff;
}
.control.control-outline input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control.control-outline .control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control.control-outline input:checked ~ .control__indicator:after {
  display: block;
}
.control.control-outline.control--checkbox .control__indicator:after {
  top: 3px;
  left: 7px;
  width: 5px;
  height: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid #788db4;
  border-width: 0 2px 2px 0;
}
.control.control-outline.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #c7d9ee;
}
.control.control-outline.control--radio .control__indicator {
  border-radius: 50%;
}
.control.control-outline.control--radio .control__indicator:after {
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #788db4;
}
.control.control-outline.control--radio input:disabled ~ .control__indicator:after {
  background: #c7d9ee;
}
.control.control-outline.control-secondary input:checked ~ .control__indicator {
  border: 1px solid #788db4;
}
.control.control-outline.control-secondary.control--checkbox .control__indicator:after {
  border: solid #788db4;
}
.control.control-outline.control-secondary.control--radio .control__indicator:after {
  background: #788db4;
}
.control.control-outline.control-success input:checked ~ .control__indicator {
  border: 1px solid #2fbfa0;
}
.control.control-outline.control-success.control--checkbox .control__indicator:after {
  border: solid #2fbfa0;
}
.control.control-outline.control-success.control--radio .control__indicator:after {
  background: #2fbfa0;
}
.control.control-outline.control-warning input:checked ~ .control__indicator {
  border: 1px solid #ffce67;
}
.control.control-outline.control-warning.control--checkbox .control__indicator:after {
  border: solid #ffce67;
}
.control.control-outline.control-warning.control--radio .control__indicator:after {
  background: #ffce67;
}
.control.control-outline.control-info input:checked ~ .control__indicator {
  border: 1px solid #399af2;
}
.control.control-outline.control-info.control--checkbox .control__indicator:after {
  border: solid #399af2;
}
.control.control-outline.control-info.control--radio .control__indicator:after {
  background: #399af2;
}
.control.control-outline.control-danger input:checked ~ .control__indicator {
  border: 1px solid #ff5c75;
}
.control.control-outline.control-danger.control--checkbox .control__indicator:after {
  border: solid #ff5c75;
}
.control.control-outline.control-danger.control--radio .control__indicator:after {
  background: #ff5c75;
}
.control.control-outline.control-danger.control--checkbox .control__indicator:after,
.control.control-outline.control-info.control--checkbox .control__indicator:after,
.control.control-outline.control-secondary.control--checkbox .control__indicator:after,
.control.control-outline.control-success.control--checkbox .control__indicator:after,
.control.control-outline.control-warning.control--checkbox .control__indicator:after {
  border-width: 0 2px 2px 0;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.pace,
.tgl + .tgl-btn {
  -moz-user-select: none;
}
.noUi-target {
  position: relative;
  direction: ltr;
  background: #eef5f9;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.noUi-horizontal {
  height: 4px;
}
.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  left: -7px;
  top: -7px;
  background-color: #345dbb;
}
.noUi-background {
  background: #d6d7d9;
}
.noUi-connect {
  background: #345dbb;
  transition: background 450ms;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-origin,
.noUi-target {
  border-radius: 2px;
}
.noUi-draggable {
  cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}
.noUi-handle {
  cursor: default;
  box-sizing: content-box !important;
}
.noUi-handle:active {
  border: 8px solid #345dbb;
  border: 8px solid rgba(53, 93, 187, 0.38);
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #b8b8b8;
}
[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed;
}
.noUi-vertical {
  width: 4px;
  display: inline-block;
  height: 100%;
}
.slider.slider-info .noUi-connect,
.slider.slider-info.noUi-connect {
  background-color: #399af2;
}
.slider.slider-info .noUi-handle {
  border-color: #399af2;
}
.slider.slider-success .noUi-connect,
.slider.slider-success.noUi-connect {
  background-color: #2fbfa0;
}
.slider.slider-success .noUi-handle {
  border-color: #2fbfa0;
}
.slider.slider-warning .noUi-connect,
.slider.slider-warning.noUi-connect {
  background-color: #ffce67;
}
.slider.slider-warning .noUi-handle {
  border-color: #ffce67;
}
.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
  background-color: #ff5c75;
}
.slider.slider-danger .noUi-handle {
  border-color: #ff5c75;
}
.slider .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  left: -6px;
}
.slider .noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.slider .noUi-handle.noUi-active {
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1);
}
.tgl {
  display: none;
}
.tgl,
.tgl *,
.tgl :after,
.tgl :before,
.tgl + .tgl-btn,
.tgl:after,
.tgl:before {
  box-sizing: border-box;
}
.tgl ::-moz-selection,
.tgl :after::-moz-selection,
.tgl :before::-moz-selection,
.tgl + .tgl-btn::-moz-selection,
.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection {
  background: 0 0;
}
.tgl ::selection,
.tgl :after::selection,
.tgl :before::selection,
.tgl + .tgl-btn::selection,
.tgl::selection,
.tgl:after::selection,
.tgl:before::selection {
  background: 0 0;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl-flip + .tgl-btn:after,
.tgl-flip + .tgl-btn:before,
.tgl-skewed + .tgl-btn:after,
.tgl-skewed + .tgl-btn:before {
  display: inline-block;
  width: 100%;
  line-height: 2em;
  font-weight: 700;
  color: #fff;
  position: absolute;
  text-align: center;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}
.tgl-light + .tgl-btn {
  background: #d2deec;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.tgl-ios + .tgl-btn,
.tgl-light[disabled] + .tgl-btn {
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #9fd6ae;
}
.tgl-light[disabled] + .tgl-btn {
  background: #f0f6ff;
}
.tgl-light[disabled] + .tgl-btn:hover {
  cursor: not-allowed;
}
.tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border: 1px solid #e8eae9;
}
.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.tgl-ios:checked + .tgl-btn {
  background: #86d993;
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}
.tgl-skewed + .tgl-btn {
  overflow: hidden;
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: #888;
}
.tgl-skewed + .tgl-btn:after,
.tgl-skewed + .tgl-btn:before {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
  transition: all 0.2s ease;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
  background: #888;
}
.tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
  background: #86d993;
}
.tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}
.tgl-flat + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid #f2f2f2;
  border-radius: 2em;
}
.tgl-flat + .tgl-btn:after {
  transition: all 0.2s ease;
  background: #f2f2f2;
  content: '';
  border-radius: 1em;
}
.tgl-flat:checked + .tgl-btn {
  border: 4px solid #7fc6a6;
}
.tgl-flat:checked + .tgl-btn:after {
  left: 50%;
  background: #7fc6a6;
}
.tgl-flip + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  font-family: sans-serif;
  -webkit-perspective: 100px;
  perspective: 100px;
}
.tgl-flip + .tgl-btn:after,
.tgl-flip + .tgl-btn:before {
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}
.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #02c66f;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.tgl-flip + .tgl-btn:before {
  background: #ff3a19;
  content: attr(data-tg-off);
}
.tgl-flip + .tgl-btn:active:before {
  -webkit-transform: rotateY(-20deg);
  transform: rotateY(-20deg);
}
.tgl-flip:checked + .tgl-btn:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.tgl-flip:checked + .tgl-btn:after {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  left: 0;
  background: #7fc6a6;
}
.tgl-flip:checked + .tgl-btn:active:after {
  -webkit-transform: rotateY(20deg);
  transform: rotateY(20deg);
}
.tgl-flat.tgl-flat-info:checked + .tgl-btn:after,
.tgl-info:checked + .tgl-btn {
  background: #399af2;
}
.tgl-flat.tgl-flat-success:checked + .tgl-btn:after,
.tgl-success:checked + .tgl-btn {
  background: #2fbfa0;
}
.tgl-flat.tgl-flat-warning:checked + .tgl-btn:after,
.tgl-warning:checked + .tgl-btn {
  background: #ffce67;
}
.tgl-danger:checked + .tgl-btn,
.tgl-flat.tgl-flat-danger:checked + .tgl-btn:after {
  background: #ff5c75;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover,
.ranges li {
  background-color: #f0f6ff;
}
.datepicker td,
.datepicker th {
  font-weight: 400;
  font-size: 14px;
  color: #617182;
}
.datepicker table tr td.new,
.datepicker table tr td.old,
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  color: #ccd9e9;
}
.datepicker-dropdown {
  margin: 0.5rem 0 0;
  border: none;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
  font-size: 0.875rem;
}
.datepicker-dropdown:before {
  border-bottom-color: #fff;
}
.datepicker td,
.datepicker th {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 4px;
  border: none;
}
.datepicker-inline,
.note-editor.note-frame {
  border: 1px solid #f0f6ff;
}
.datepicker,
.datetimepicker {
  z-index: 12000;
  width: 260px;
  padding: 10px;
}
.datepicker table,
.datetimepicker table,
.dt-cont {
  width: 100%;
}
.dt-cont {
  position: relative;
  height: 400px;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
}
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td.active,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover,
.datetimepicker table tr td.active:hover {
  background-image: none;
  text-shadow: none;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover,
.datepicker tfoot tr th:hover,
.datetimepicker table tr td span:hover,
.datetimepicker table tr td.day:hover,
.datetimepicker tfoot tr:first-child th:hover,
.datetimepicker thead tr:first-child th:hover {
  background: #f0f6ff;
}
.bootstrap-timepicker table td a:hover {
  border-color: #f0f6ff;
  border-radius: 4px;
  background-color: #f0f6ff;
}
.bootstrap-timepicker table td input {
  width: 35px;
  border: 1px solid #f0f6ff;
}
.daterangepicker td,
.daterangepicker th {
  font-size: 0.875rem;
}
.daterangepicker .input-mini {
  border: 1px solid rgba(141, 166, 195, 0.3);
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  border: 1px solid #dfe7f3;
  background: #f0f6ff;
  color: #7d93b7;
  font-size: 12px;
  padding: 5px 10px;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  height: 25px;
}
.daterangepicker.dropdown-menu {
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
  border: 1px solid rgba(141, 166, 195, 0.3);
}
.ranges li {
  border: 1px solid #f0f6ff;
}
@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes pulse {
  0%,
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes pulse {
  0%,
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.dropzone,
.dropzone * {
  box-sizing: border-box;
}
.dropzone {
  min-height: 150px;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-preview.dz-file-preview .dz-details,
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #f0f6ff;
  background: linear-gradient(to bottom, #f0f6ff, #b6cade);
}
.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 5px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}
.dropzone .dz-preview .dz-image img {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #ff5c75;
  padding: 0.5em 1.2em;
  color: #fff;
}
.dropzone .dz-preview .dz-error-message:after {
  content: '';
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ff5c75;
}
.chartist:after,
.chartist:before {
  content: '';
}
.tabcontrol,
.wizard {
  display: block;
  width: 100%;
  overflow: hidden;
}
.tabcontrol a,
.wizard a {
  outline: 0;
}
.tabcontrol ul,
.wizard ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
.tabcontrol > .content > .body ul,
.wizard > .content > .body ul {
  list-style: disc !important;
}
.tabcontrol ul > li,
.wizard ul > li {
  display: block;
  padding: 0;
}
.tabcontrol > .content > .title,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps .number {
  font-size: 1.429em;
}
.wizard > .steps > ul > li {
  width: 25%;
}
.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}
.wizard > .actions > ul > li,
.wizard > .steps > ul > li {
  float: left;
}
.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  height: 45px;
  text-decoration: none;
  border-radius: 5px;
}
.wizard > .steps .current a,
.wizard > .steps .current a:active,
.wizard > .steps .current a:hover,
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover {
  cursor: pointer;
}
.wizard > .actions a[role='menuitem'] {
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  height: 38px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 30px;
  padding: 8px 25px;
}
.pace {
  -webkit-user-select: none;
}
.wizard > .actions a[role='menuitem']:hover {
  border-radius: 30px;
  padding: 8px 25px;
}
.wizard > .steps .error a .number,
.wizard > .steps a .number {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 0.875rem;
  line-height: 3.2;
  margin: 0 10px 0 0;
  border-radius: 5px 0 0 5px;
}
.wizard > .content {
  display: block;
  margin: 0.5em;
  min-height: 35em;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: 30px;
}
.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em;
  width: 65%;
}
.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.wizard > .content > .body ul > li {
  display: list-item;
}
.wizard > .content > .body > iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
.wizard > .content > .body input {
  display: block;
}
.wizard > .content > .body input[type='checkbox'] {
  display: inline-block;
}
.wizard > .content > .body input.error {
  background: rgba(255, 92, 117, 0.1);
  border: 1px solid #ff5c75;
  color: #ff5c75;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}
.wizard > .content > .body label.error {
  color: #ff5c75;
  display: inline-block;
  margin-left: 0;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}
.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}
.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}
.wizard > .actions > ul > li {
  margin: 0 0.5em;
}
.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}
.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 30px;
}
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  background: #eee;
  color: #323a48;
}
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0;
  top: 1px;
  z-index: 1;
}
.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}
.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px;
  margin-top: 0;
}
.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}
.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}
.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px;
}
.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}
.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.tabcontrol > .content > .body ul > li {
  display: list-item;
}
@media (max-width: 600px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
  .wizard > .steps a,
  .wizard > .steps a:active,
  .wizard > .steps a:hover {
    margin-top: 0.5em;
  }
  .wizard.vertical > .actions,
  .wizard.vertical > .steps {
    display: block;
    float: none;
    width: 100%;
  }
  .wizard.vertical > .content {
    display: block;
    float: none;
    margin: 0 0.5em 0.5em;
    width: auto;
  }
}
@media (max-width: 480px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
}
.cke {
  border: 1px solid #e4eaf3 !important;
}
.cke .cke_inner .cke_top {
  background: #eef4ff;
  border-bottom: 1px solid #e4eaf3;
}
.cke .cke_inner .cke_bottom {
  background: #f0f6ff;
  border-top: 1px solid #e4eaf3;
}
.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
  color: #617182 !important;
}
#website-stats .legend {
  position: absolute;
  top: -15px;
}
#website-stats .legend .legendColorBox > div {
  border: none !important;
}
#website-stats .legend .legendColorBox > div div {
  border-radius: 50%;
}
#website-stats .legend .legendLabel {
  padding: 0 5px;
  font-size: em(13);
  font-weight: 400;
}
.fc-title,
.flotTip {
  font-weight: 500;
}
.chartist-wrapper {
  width: 100%;
  position: relative;
}
.card.sparkline-card #sparkline-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card.sparkline-card #sparkline-bar canvas {
  z-index: 1;
  width: 100% !important;
  height: 50px !important;
}
.card.sparkline-card #sparkline-line {
  position: absolute;
  bottom: -3px;
  width: 100%;
}
.card.sparkline-card #sparkline-line canvas {
  display: inline-block;
  width: 100% !important;
  height: 45px !important;
  vertical-align: top;
}
.card.sparkline-card .card-footer {
  padding: 20px 0 0;
  min-height: initial;
}
#chartBarPie .chart-bar .ct-bar {
  stroke: #665aa8;
  stroke-width: 3%;
}
#totalVisitsChart .ct-series-a .ct-bar,
#totalVisitsChart .ct-series-a .ct-line,
#totalVisitsChart .ct-series-a .ct-point,
#totalVisitsChart .ct-series-a .ct-slice-donut {
  stroke: #5c57b1;
}
#totalUniqueVisitsChart .ct-series-a .ct-bar,
#totalUniqueVisitsChart .ct-series-a .ct-line,
#totalUniqueVisitsChart .ct-series-a .ct-point,
#totalUniqueVisitsChart .ct-series-a .ct-slice-donut {
  stroke: #e23b7b;
}
.flotTip {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.8125rem;
  color: #fff;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #5867c3;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #5867c3;
}
.chartist {
  display: block;
  position: relative;
  width: 100%;
}
.chartist:before {
  display: block;
  float: left;
  width: 0;
  height: 0;
  padding-bottom: 30%;
}
.chartist div,
.chartist svg {
  height: 100%;
  width: 100%;
}
.chartist:after {
  display: table;
  clear: both;
}
.chartist svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-double-octave:before,
.ct-major-eleventh:before,
.ct-major-second:before,
.ct-major-seventh:before,
.ct-major-sixth:before,
.ct-major-tenth:before,
.ct-major-third:before,
.ct-major-twelfth:before,
.ct-minor-second:before,
.ct-minor-seventh:before,
.ct-minor-sixth:before,
.ct-minor-third:before,
.ct-octave:before,
.ct-perfect-fifth:before,
.ct-perfect-fourth:before,
.ct-square:before {
  float: left;
  height: 0;
  content: '';
}
.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1;
}
.ct-chart-bar .ct-label,
.ct-chart-line .ct-label {
  display: block;
  display: flex;
}
.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}
.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}
.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}
.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}
.ct-line {
  fill: none;
  stroke-width: 1px;
}
.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}
.ct-bar {
  fill: none;
  stroke-width: 10px;
}
.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #1c86bf;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #1c86bf;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #28bebd;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #28bebd;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #399af2;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #399af2;
}
.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
  stroke: #ffce67;
}
.ct-series-e .ct-area,
.ct-series-e .ct-slice-pie {
  fill: #ffce67;
}
.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
  stroke: #ff5c75;
}
.ct-series-f .ct-area,
.ct-series-f .ct-slice-pie {
  fill: #ff5c75;
}
.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
  stroke: #839bb3;
}
.ct-series-g .ct-area,
.ct-series-g .ct-slice-pie {
  fill: #2fbfa0;
}
.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392;
}
.ct-series-h .ct-area,
.ct-series-h .ct-slice-pie {
  fill: #6b0392;
}
.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut {
  stroke: #5c56cf;
}
.ct-series-i .ct-area,
.ct-series-i .ct-slice-pie {
  fill: #5c56cf;
}
.ct-series-j .ct-bar,
.ct-series-j .ct-line,
.ct-series-j .ct-point,
.ct-series-j .ct-slice-donut {
  stroke: #dda458;
}
.ct-series-j .ct-area,
.ct-series-j .ct-slice-pie {
  fill: #dda458;
}
.ct-series-k .ct-bar,
.ct-series-k .ct-line,
.ct-series-k .ct-point,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d;
}
.ct-series-k .ct-area,
.ct-series-k .ct-slice-pie {
  fill: #eacf7d;
}
.ct-series-l .ct-bar,
.ct-series-l .ct-line,
.ct-series-l .ct-point,
.ct-series-l .ct-slice-donut {
  stroke: #86797d;
}
.ct-series-l .ct-area,
.ct-series-l .ct-slice-pie {
  fill: #86797d;
}
.ct-series-m .ct-bar,
.ct-series-m .ct-line,
.ct-series-m .ct-point,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326;
}
.ct-series-m .ct-area,
.ct-series-m .ct-slice-pie {
  fill: #b2c326;
}
.ct-series-n .ct-bar,
.ct-series-n .ct-line,
.ct-series-n .ct-point,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}
.ct-series-n .ct-area,
.ct-series-n .ct-slice-pie {
  fill: #6188e2;
}
.ct-series-o .ct-bar,
.ct-series-o .ct-line,
.ct-series-o .ct-point,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}
.ct-series-o .ct-area,
.ct-series-o .ct-slice-pie {
  fill: #a748ca;
}
.ct-square {
  display: block;
  position: relative;
  width: 100%;
}
.ct-square:before {
  display: block;
  width: 0;
  padding-bottom: 100%;
}
.ct-square:after {
  content: '';
  display: table;
  clear: both;
}
.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-second:before {
  display: block;
  width: 0;
  padding-bottom: 93.75%;
}
.ct-minor-second:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-second:before {
  display: block;
  width: 0;
  padding-bottom: 88.8888888889%;
}
.ct-major-second:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-third:before {
  display: block;
  width: 0;
  padding-bottom: 83.3333333333%;
}
.ct-minor-third:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-third:before {
  display: block;
  width: 0;
  padding-bottom: 80%;
}
.ct-major-third:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fourth:before {
  display: block;
  width: 0;
  padding-bottom: 75%;
}
.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fifth:before {
  display: block;
  width: 0;
  padding-bottom: 66.6666666667%;
}
.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-sixth:before {
  display: block;
  width: 0;
  padding-bottom: 62.5%;
}
.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}
.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%;
}
.ct-golden-section:after {
  content: '';
  display: table;
  clear: both;
}
.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-sixth:before {
  display: block;
  width: 0;
  padding-bottom: 60%;
}
.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-seventh:before {
  display: block;
  width: 0;
  padding-bottom: 56.25%;
}
.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-seventh:before {
  display: block;
  width: 0;
  padding-bottom: 53.3333333333%;
}
.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-octave:before {
  display: block;
  width: 0;
  padding-bottom: 50%;
}
.ct-octave:after {
  content: '';
  display: table;
  clear: both;
}
.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-tenth:before {
  display: block;
  width: 0;
  padding-bottom: 40%;
}
.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-eleventh:before {
  display: block;
  width: 0;
  padding-bottom: 37.5%;
}
.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-twelfth:before {
  display: block;
  width: 0;
  padding-bottom: 33.3333333333%;
}
.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-double-octave:before {
  display: block;
  width: 0;
  padding-bottom: 25%;
}
.ct-double-octave:after {
  content: '';
  display: table;
  clear: both;
}
.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.fc-unthemed .fc-day-grid td:not(.fc-axis) {
  padding: 0.5rem;
}
a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: inherit;
}
.fc-unthemed .fc-toolbar .fc-button {
  background: #fff;
  border: 1px solid #d8e3ef;
  color: #788db4;
  text-shadow: none !important;
  height: 2.75rem;
  padding: 0 1.25rem;
  outline: 0 !important;
  font-size: 0.9rem;
  box-shadow: none !important;
}
.fc-unthemed .fc-toolbar .fc-button.fc-state-disabled {
  color: rgba(141, 166, 195, 0.8);
  background-color: transparent;
  border-color: #d8e3ef;
}
.fc-unthemed .fc-toolbar .fc-button.fc-state-disabled:active,
.fc-unthemed .fc-toolbar .fc-button.fc-state-disabled:focus,
.fc-unthemed .fc-toolbar .fc-button.fc-state-disabled:hover {
  color: rgba(141, 166, 195, 0.8) !important;
  background: 0 0 !important;
  border-color: #d8e3ef !important;
  box-shadow: none !important;
}
.fc-time,
.qt-fc-event-accent .fc-title,
.qt-fc-event-danger .fc-title,
.qt-fc-event-info .fc-title,
.qt-fc-event-primary .fc-title,
.qt-fc-event-success .fc-title {
  color: #fff;
}
.fc-unthemed .fc-toolbar .fc-button.fc-state-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  border: 0;
  box-shadow: none;
  text-shadow: none;
}
.fc-unthemed .fc-toolbar h2 {
  margin-top: 0.7rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
}
.fc-event,
.fc-event-dot {
  background-color: #b6cade;
}
.fc-event {
  border: 1px solid #b6cade;
}
.qt-fc-event-primary {
  border: none;
}
.qt-fc-event-success {
  border: none;
  background: #2fbfa0;
}
.qt-fc-event-danger {
  border: none;
  background: #ff5c75;
}
.qt-fc-event-warning {
  border: none;
  background: #ffce67;
}
.qt-fc-event-info {
  border: none;
  background: #399af2;
}
.fc-ltr .fc-h-event.fc-not-start {
  padding-left: 10px;
}
.fc-day-grid-event {
  padding: 10px;
}
.fc-popover .fc-header {
  padding: 6px;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  margin-top: 6px;
  font-size: 0.9em;
}
.fc-event-container a {
  margin-bottom: 3px;
}
.fc-popover {
  box-shadow: none;
}
@media (max-width: 992px) {
  .fc-unthemed .fc-toolbar .fc-button {
    height: 2rem;
  }
  .fc-unthemed th.fc-day-header {
    padding: 0.3rem 0.5rem;
  }
  .fc-unthemed .fc-toolbar {
    margin-bottom: 1.5rem;
  }
  .fc-unthemed .fc-toolbar .fc-center,
  .fc-unthemed .fc-toolbar .fc-left,
  .fc-unthemed .fc-toolbar .fc-right {
    display: block;
    float: none;
    margin-bottom: 1rem;
    text-align: center;
  }
  .fc-unthemed .fc-toolbar .fc-center h2,
  .fc-unthemed .fc-toolbar .fc-left h2,
  .fc-unthemed .fc-toolbar .fc-right h2 {
    float: none;
    text-align: center;
  }
  .fc-unthemed .fc-toolbar .fc-center > .fc-button-group,
  .fc-unthemed .fc-toolbar .fc-left > .fc-button-group,
  .fc-unthemed .fc-toolbar .fc-right > .fc-button-group {
    display: inline-block;
    float: none;
  }
  .fc-unthemed .fc-toolbar .fc-center > .fc-button,
  .fc-unthemed .fc-toolbar .fc-center > .fc-button-group > .fc-button,
  .fc-unthemed .fc-toolbar .fc-left > .fc-button,
  .fc-unthemed .fc-toolbar .fc-left > .fc-button-group > .fc-button,
  .fc-unthemed .fc-toolbar .fc-right > .fc-button,
  .fc-unthemed .fc-toolbar .fc-right > .fc-button-group > .fc-button {
    float: none;
  }
}
.event-tag > span {
  width: 30px;
  height: 30px;
  margin: 0 0 3px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.event-tag span {
  display: inline-block !important;
  border-radius: 50%;
}
.event-tag > span.color-tag__default {
  border: 1px solid #eee;
  background-color: #fff !important;
}
.event-tag > span.color-tag__default > i:before {
  color: #333;
}
.event-tag > span,
.event-tag > span > i {
  transition: all;
  transition-duration: 0.2s;
}
.event-tag > span > input[type='radio'] {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.event-tag > span > input[type='radio']:checked + i {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.event-tag > span > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px 0 0 5px;
  line-height: 10px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.event-tag > span > i:before {
  content: '\f26b';
  font-family: Material-Design-Iconic-Font;
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  z-index: 1;
}
#mail-wrapper > .card {
  overflow: hidden;
  height: calc(100vh - 130px);
}
#mail-wrapper .toolbar {
  height: 55px;
}
#mail-wrapper .toolbar .actions.top-left,
#mail-wrapper .toolbar .actions.top-right {
  top: 18px;
}
#mail-wrapper .toolbar .actions.top-left i,
#mail-wrapper .toolbar .actions.top-right i {
  font-size: 1.125rem;
}
#mail-wrapper .scroll-y {
  height: calc(100vh - 200px);
}
#mail-wrapper .scroll-y > [data-scroll='minimal-dark'] {
  height: calc(100vh - 150px);
}
#mail-wrapper .aside-left {
  padding: 30px;
  min-width: 250px;
  transition: all 250ms ease;
  background: #fff;
}
#mail-wrapper .aside-left .list-group {
  margin: 40px 0 0;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}
#mail-wrapper .aside-left .list-group .list-group-item {
  border: none;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.75rem 0.25rem;
}
#mail-wrapper .aside-left .list-group .list-group-item a:link {
  color: #788db4;
  font-size: 15px;
}
#mail-wrapper .aside-left .list-group .list-group-item a:link:hover,
#mail-wrapper .aside-left .list-group .list-group-item a:link:hover i,
#mail-wrapper .aside-left .list-group .list-group-item.active a,
#mail-wrapper .aside-left .list-group .list-group-item.active i {
  color: #7468bd;
}
#mail-wrapper .aside-left .list-group .list-group-item a:link i {
  font-size: 20px;
  width: 30px;
}
#mail-wrapper .aside-left .list-group .list-group-item.active {
  background-color: transparent;
  border: none;
}
#mail-wrapper .aside-left .nav-icon {
  float: left;
  margin-right: 7px;
  margin-left: 5px;
  font-size: 1rem;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  align-items: center;
}
@media (max-width: 670px) {
  #mail-wrapper .aside-left {
    position: absolute;
    width: 240px;
    left: -255px;
    min-height: 100vh;
    z-index: 1001;
  }
  #mail-wrapper .aside-left.open {
    left: 0;
  }
}
#mail-wrapper .center-content {
  min-width: 300px;
  flex: 1 0 100%;
  position: relative;
  border-left: 1px solid rgba(234, 243, 253, 0.7);
  border-right: 1px solid rgba(234, 243, 253, 0.7);
}
#mail-wrapper .center-content .toolbar {
  position: relative;
}
#mail-wrapper .center-content .toolbar #mobile-aside-menu-trigger {
  display: none;
}
#mail-wrapper .center-content .toolbar .custom-control-label:after,
#mail-wrapper .center-content .toolbar .custom-control-label:before {
  top: 1px;
}
@media (max-width: 670px) {
  #mail-wrapper .center-content .toolbar .actions.top-left {
    left: 11px;
    top: 13px;
  }
  #mail-wrapper .center-content .toolbar .actions.top-left #mobile-aside-menu-trigger {
    display: block;
  }
}
#mail-wrapper .center-content .search-wrapper {
  padding: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
#mail-wrapper .center-content .search-wrapper .search-input {
  width: 100%;
  border: 0;
  background-color: #fff;
  border-radius: 0;
  height: 55px;
  text-indent: 45px;
  transition: width 0.3s linear;
  margin: 0;
  padding-top: 9px;
  border-bottom: 1px solid #eaf3fd !important;
}
#mail-wrapper .center-content .search-wrapper .search-button-submit {
  top: 18px;
  left: 14px;
}
#mail-wrapper .center-content .search-wrapper .search-button-submit i {
  font-size: 20px;
}
#mail-wrapper .center-content .search-wrapper .search-button-submit:focus {
  outline: 0;
  box-shadow: none;
}
#mail-wrapper .center-content .search-wrapper i.close-search {
  height: 30px;
  cursor: pointer;
  top: 16px;
  line-height: 30px;
  margin-right: 0;
  font-size: 1.375em;
  position: absolute;
  right: 15px;
  display: none;
}
@media (max-width: 670px) {
  #mail-wrapper .center-content .search-wrapper {
    left: 75px;
    border-left: 1px solid #eaf3fd !important;
  }
  #mail-wrapper .center-content .search-wrapper .toolbar .actions.top-left {
    left: 11px;
    top: 12px;
  }
  #mail-wrapper .center-content .search-wrapper .toolbar .actions.top-left #mobile-aside-menu-trigger {
    display: block;
  }
}
#mail-wrapper .center-content .list-group {
  max-height: 100%;
  overflow-y: auto;
}
#mail-wrapper .center-content .list-group .list-group-item {
  border: none;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 0;
  display: flex;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
  border-left: solid 3px transparent;
  z-index: 0;
}
#mail-wrapper .center-content .list-group .list-group-item .item,
#mail-wrapper .center-content .list-group .list-group-item .item .row-content {
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}
#mail-wrapper .center-content .list-group .list-group-item .list-group-item-text {
  margin: 0;
  line-height: 1.3;
  font-weight: 300;
  font-size: 0.75rem;
}
#mail-wrapper .center-content .list-group .list-group-item .avatar {
  margin: 0 10px 10px 0;
  min-width: 40px;
  height: auto;
  width: auto;
}
#mail-wrapper .center-content .list-group .list-group-item .list-group-item-title {
  margin: 0;
  padding: 3px 0;
  font-size: 0.8125rem;
}
#mail-wrapper .center-content .list-group .list-group-item .list-group-item-heading {
  font-size: 0.875rem;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 400;
}
#mail-wrapper .center-content .list-group .list-group-item:last-child {
  border-bottom: none;
}
#mail-wrapper .center-content .list-group .list-group-item:hover {
  border-left: solid 3px #7468bd !important;
  background-color: #f7faff;
  border-radius: 0;
}
#mail-wrapper .center-content .list-group .list-group-item.active {
  border: none;
  border-left: solid 3px #7468bd !important;
  background-color: #f7faff;
  color: #353a40;
}
#mail-wrapper .center-content .list-group .list-group-item.active a,
#mail-wrapper .center-content .list-group .list-group-item.active i {
  color: #7468bd;
}
#mail_compose_wrapper .tippy-top ul.card-actions.icons li a,
.contact-list .contact-item .contact a:hover i {
  color: #fff;
}
#mail-wrapper .aside-right {
  transition: all 250ms ease;
}
#mail-wrapper .aside-right .toolbar {
  position: relative;
}
@media (max-width: 1280px) {
  #mail-wrapper .aside-right {
    position: absolute;
    width: 320px;
    right: -320px;
    min-height: 100vh;
    background: #fff;
  }
  #mail-wrapper .aside-right.open {
    right: 0;
    z-index: 1001;
  }
}
@media (max-width: 670px) {
  #mail-wrapper {
    padding: 0 !important;
  }
  #mail-wrapper > .card {
    overflow: hidden;
    height: calc(100vh - 65px);
    margin: 0;
  }
  #mail-wrapper .scroll-y {
    height: calc(100vh - 30px);
  }
  #mail-wrapper .scroll-y > [data-scroll='minimal-dark'] {
    height: calc(100vh - 150px);
  }
}
.layout-vertical #mail-wrapper > .card {
  overflow: hidden;
  height: calc(100vh - 200px);
}
.mail-compose {
  order: 3;
  display: block !important;
  z-index: 1005;
  position: fixed;
  bottom: -605px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 600px;
  height: 600px;
  right: 30px;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 5px 5px 0 0;
  padding: 0;
}
body.mail-compose-open {
  overflow: hidden;
}
body.mail-compose-open .mail-compose {
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  bottom: 0;
}
@media (max-width: 576px) {
  .mail-compose {
    height: 400px;
    bottom: -405px;
    width: 93%;
    margin: 0 auto;
    left: 10px;
    right: 10px;
  }
}
#mail_compose_wrapper {
  background: #fff;
}
#mail_compose_wrapper .tippy-top {
  background: #151726;
  height: 40px;
}
#mail_compose_wrapper .tippy-top ul.card-actions.icons.left-top {
  left: 10px;
  top: 8px;
}
#mail_compose_wrapper .tippy-top ul.card-actions.icons.right-top {
  right: 10px;
  top: 5px;
}
#mail_compose_wrapper .compose-form .form-group .form-control {
  border-radius: 0;
  padding: 10px 12px;
}
#mail_compose_wrapper .compose-form .form-group .form-control:focus {
  border-color: rgba(141, 166, 195, 0.3);
}
#mail_compose_wrapper .compose-form .form-group textarea {
  background-image: none;
}
#mail_compose_wrapper.open {
  bottom: 0;
}
#mail_compose_wrapper.min {
  bottom: -500px;
  width: 275px;
}
#mail_compose_wrapper.expand {
  bottom: 10%;
  top: 10%;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 80%;
  z-index: 199;
}
@media (max-width: 670px) {
  #mail_compose_wrapper {
    padding: 0 !important;
  }
}
.contact-list .contact-item .card-title {
  margin: 15px 0 0;
}
.contact-list .contact-item .contact a {
  transition: all 0.2s ease;
}
.contact-list .contact-item .contact a:hover {
  background-color: #736cc7;
}
.contact-list .contact-item .card-footer > div > div:first-child > div > a {
  border-radius: 0 0 0 5px;
}
.contact-list .contact-item .card-footer > div > div:last-child > div > a {
  border-radius: 0 0 5px;
}
#message-wrapper > .card {
  overflow: hidden;
  height: calc(100vh - 130px);
}
#message-wrapper .aside-left {
  padding: 0;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  transition: all 250ms ease;
  background: #fff;
}
#message-wrapper .aside-left .contact-list {
  overflow: auto;
}
#message-wrapper .aside-left .search-wrapper .search-button-submit {
  top: 27px;
  left: 33px;
}
#message-wrapper .aside-left .search-wrapper .search-button-submit i {
  font-size: 20px;
}
#message-wrapper .aside-left .list-group {
  margin-top: 15px;
  height: calc(100vh - 220px);
}
#message-wrapper .aside-left .list-group .list-group-item {
  border: none;
  border-radius: none;
  padding: 10px;
  margin-bottom: 0;
  display: flex;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
  border-left: solid 3px transparent;
  z-index: 0;
}
#message-wrapper .aside-left .list-group .list-group-item .item,
#message-wrapper .aside-left .list-group .list-group-item .item .row-content {
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}
#message-wrapper .aside-left .list-group .list-group-item .list-group-item-text {
  margin: 0;
  line-height: 1.3;
  font-weight: 300;
  font-size: 0.75rem;
}
#message-wrapper .aside-left .list-group .list-group-item .avatar {
  margin: 0 10px 10px 0;
  min-width: 40px;
  height: auto;
  width: auto;
}
#message-wrapper .aside-left .list-group .list-group-item .list-group-item-title {
  margin: 0;
  padding: 0;
  font-weight: 400;
}
#message-wrapper .aside-left .list-group .list-group-item .list-group-item-heading {
  font-size: 0.9375rem;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
#message-wrapper .aside-left .list-group .list-group-item:last-child {
  border-bottom: none;
}
#message-wrapper .aside-left .list-group .list-group-item:hover {
  border-left: solid 3px #7468bd !important;
  background-color: #f7faff;
  border-radius: 0;
}
#message-wrapper .aside-left .list-group .list-group-item.active {
  border: none;
  border-left: solid 3px #7468bd !important;
  background-color: #f7faff;
  border-radius: 0;
}
#message-wrapper .aside-left .list-group .list-group-item.active a,
#message-wrapper .aside-left .list-group .list-group-item.active i {
  color: #7468bd;
}
@media (max-width: 670px) {
  #message-wrapper .aside-left {
    position: absolute;
    width: 300px;
    left: -300px;
    min-height: 100vh;
    z-index: 1001;
  }
  #message-wrapper .aside-left.open {
    left: 0;
  }
}
#message-wrapper .center-content {
  flex: 1 0 100%;
  position: relative;
  border-left: 1px solid #eaf3fd;
  border-right: 1px solid #eaf3fd;
}
#message-wrapper .center-content #mobile-aside-menu-trigger {
  display: none;
  float: left;
}
#message-wrapper .center-content .toolbar {
  background: #fff;
  height: 78px;
}
#message-wrapper .center-content .toolbar .actions.top-left {
  left: 15px;
  top: 24px;
}
#message-wrapper .center-content .toolbar .actions.top-left .avatar-wrapper {
  width: auto;
  height: auto;
}
#message-wrapper .center-content .toolbar .actions.top-left .avatar-wrapper .badge {
  position: absolute;
  top: -2px;
  border: 1px solid #fff;
  width: 10px;
  height: 10px;
  padding: 1px;
}
#message-wrapper .center-content .chat-contents {
  height: 100%;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner {
  bottom: 158px;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats {
  padding: 30px 15px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat .chat-body {
  display: block;
  margin: 10px 0 0;
  overflow: hidden;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat .chat-body:first-child {
  margin-top: 0;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat.chat-right .chat-content {
  position: relative;
  display: block;
  float: right;
  padding: 8px 15px;
  margin: 0;
  clear: both;
  background: #413791;
  background: linear-gradient(135deg, #413791 1%, #ad63c9 100%);
  border-radius: 10px 2px 2px 10px;
  max-width: 60%;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat.chat-right .chat-content a,
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat.chat-right .chat-content p {
  color: #fff;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-bubble {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  font-size: 0.8125rem;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-bubble p {
  font-size: 0.8125rem;
}
#message-wrapper
  .center-content
  .chat-contents
  .chat-content-wrap
  .chat-wrap-inner
  .chats
  .chat.chat-right
  .chat-bubble:last-child
  .chat-content {
  border-bottom-right-radius: 10px;
}
#message-wrapper
  .center-content
  .chat-contents
  .chat-content-wrap
  .chat-wrap-inner
  .chats
  .chat.chat-right
  .chat-bubble:first-child
  .chat-content {
  border-top-right-radius: 10px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-content > p {
  margin-bottom: 0;
  word-wrap: break-word;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-meta .chat-file-name,
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-meta .chat-file-size {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6875rem;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-time {
  color: rgba(255, 255, 255, 0.5);
  display: block;
  font-size: 0.6875rem;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-right .chat-time {
  text-align: right;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-line {
  border-bottom: 1px solid #d3dce9;
  height: 12px;
  margin: 7px 0 20px;
  position: relative;
  text-align: center;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-date {
  background-color: #f0f6ff;
  color: #000;
  font-size: 0.6875rem;
  padding: 0 11px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-avatar {
  float: right;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-avatar .avatar {
  line-height: 30px;
  height: 30px;
  width: 30px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-left .chat-avatar {
  float: left;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-left .chat-body {
  margin-right: 0;
  margin-left: 30px;
  padding-right: 20px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-left .chat-content {
  background-color: #fff;
  border: 1px solid rgba(211, 220, 233, 0.6);
  float: left;
  position: relative;
  padding: 8px 15px;
  border-radius: 2px 10px 10px 2px;
  max-width: 60%;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .avatar:hover {
  color: #fff;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .avatar > img {
  width: 100%;
  display: block;
}
#message-wrapper
  .center-content
  .chat-contents
  .chat-content-wrap
  .chat-wrap-inner
  .chats
  .chat.chat-left
  .chat-bubble:first-child
  .chat-content {
  border-top-left-radius: 10px;
  margin-top: 0;
}
#message-wrapper
  .center-content
  .chat-contents
  .chat-content-wrap
  .chat-wrap-inner
  .chats
  .chat.chat-left
  .chat-bubble:last-child
  .chat-content {
  border-bottom-left-radius: 10px;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .chat-left .chat-time {
  color: #788db4;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .attach-list {
  color: #adb7be;
  padding: 5px 0 0;
  line-height: 24px;
  margin: 0;
  list-style: none;
}
#message-wrapper .center-content .chat-contents .chat-content-wrap .chat-wrap-inner .chats .attach-list i {
  margin-right: 3px;
  font-size: 1rem;
}
#message-wrapper .center-content .card-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 80px;
}
#message-wrapper .center-content .card-footer .actions.top-left {
  left: 15px;
  top: 24px;
}
#message-wrapper .aside-right {
  min-width: 240px;
  width: 240px;
  max-width: 240px;
}
@media (max-width: 1280px) {
  #message-wrapper .aside-right {
    position: absolute;
    width: 240px;
    right: -240px;
    min-height: 100vh;
    background: #fff;
  }
  #message-wrapper .aside-right.open {
    right: 0;
    z-index: 1001;
  }
}
@media (max-width: 670px) {
  #message-wrapper .center-content .toolbar .actions.top-left .avatar-wrapper {
    margin-left: 20px;
  }
  #message-wrapper .center-content .toolbar .actions.top-left #mobile-aside-menu-trigger {
    display: block;
  }
  #message-wrapper {
    padding: 0 !important;
  }
  #message-wrapper > .card {
    overflow: hidden;
    height: calc(100vh - 65px);
    margin: 0;
  }
}
.layout-vertical #message-wrapper > .card {
  overflow: hidden;
  height: calc(100vh - 200px);
}
#packages .card-header {
  font-size: 2.5rem;
  border: none;
}
#packages .card-body {
  padding-top: 0;
}
#packages .togglebutton label {
  cursor: pointer;
  font-weight: 400;
}
.pricing-wrapper {
  margin: 0 auto 120px;
}
.pricing-wrapper .card.pricing-card {
  border-radius: 3px;
  background-clip: padding-box;
}
.pricing-wrapper .card.pricing-card .feature-pricing-card {
  margin: 0;
  z-index: 5;
}
.pricing-wrapper .card.pricing-card .card-heading {
  text-align: center;
  padding-top: 35px;
  border-bottom: 1px solid rgba(210, 221, 234, 0.3);
}
.pricing-wrapper .card.pricing-card .card-heading .card-title .h1 {
  font-size: 40px;
  margin: 0;
  font-weight: 400;
  display: inline;
  letter-spacing: -3px;
}
.pricing-wrapper .card.pricing-card .card-heading .card-title .h1 sup {
  font-size: 37%;
  top: -1.4em;
  font-weight: 200;
  margin: 10px 4px 10px 10px;
}
.pricing-wrapper .card.pricing-card .card-heading .card-title .pricing-period {
  font-size: 0.75em;
}
.pricing-wrapper .card.pricing-card .card-heading .card-title .pricing-title {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.pricing-wrapper .card.pricing-card .card-body .pricing-feature-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.pricing-wrapper .card.pricing-card .card-body .pricing-feature-list .pricing-feature {
  padding: 0.7em 0;
}
.card-container {
  -webkit-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}
.card-container .list-group-flush {
  padding-bottom: 0;
}
.card-container .card.card-flip {
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}
.card-container .card.card-flip .card-back,
.card-container .card.card-flip .card-front {
  border: none;
  background: #fff;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 380px;
}
.card-container .card.card-flip .card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}
.card-container .card.card-flip .card-front {
  z-index: 2;
}
.card-back,
.card-container,
.card-front {
  width: 100%;
  height: 380px;
  border-radius: 4px;
}
.card-container.flipped .card {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 992px) {
  #packages h2 {
    font-size: 1.625em;
  }
  #packages p {
    font-size: 0.875em;
  }
  .pricing-wrapper .card.pricing-card .card-heading {
    padding-top: 25px;
  }
  .pricing-wrapper .card.pricing-card .card-body .pricing-feature-list {
    padding: 0;
  }
}
.sign-in-form {
  max-width: 450px;
  margin: 15% auto;
  padding: 30px;
}
.sign-in-form .sign-in-heading {
  font-size: 1rem;
}
.invoice-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}
.invoice-wrapper .invoice-summary {
  padding: 20px 0;
  margin: 55px 0 0;
  border-top: 1px solid #dee2e6;
}
.invoice-wrapper .invoice-summary .sub-title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 0 5px;
}
.invoice-wrapper .invoice-summary .summary {
  list-style: none;
  margin: 0;
  padding: 0;
}
.invoice-wrapper .invoice-summary .summary li {
  padding: 0 0 4px;
}
.invoice-wrapper .invoice-header {
  padding-top: 80px;
}
.invoice-wrapper .invoice-header h1 {
  font-size: 38px;
  font-weight: 600;
}
.invoice-wrapper .invoice-header .logo-wrapper .logo {
  vertical-align: middle;
  margin-top: -6px;
  margin-right: 3px;
}
.invoice-wrapper .invoice-header .logo-wrapper .brand-text {
  color: #4d5a68;
  font-size: 27px;
  font-weight: 400;
}
.invoice-wrapper .invoice-footer .invoice-total {
  padding-top: 30px;
  text-align: right;
}
.invoice-wrapper .invoice-footer .invoice-total p {
  margin: 0 0 4px;
}
.invoice-wrapper .invoice-footer .invoice-total .total {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -ms-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #e90f92;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
@-webkit-keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: 0.5;
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}
@keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: 0.5;
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}
@-webkit-keyframes hideSweetToast {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes hideSweetToast {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@-webkit-keyframes animate-toast-success-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes animate-toast-success-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@-webkit-keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
.hljs-doctag,
.hljs-keyword,
.hljs-literal,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-strong,
.hljs-title,
.hljs-type,
.token.bold,
.token.important {
  font-weight: 700;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 2px;
}
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  margin: 5px 0;
}
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  margin: 10px 0 0;
}
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: #d0e0f2;
}
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background: #7c99ba;
}
.demo-badge--preview .badge,
.demo-buttons--preview .btn {
  margin-bottom: 15px;
}
.icon-set .icon {
  text-align: center;
  margin: 15px auto;
}
.icon-set .icon a {
  display: block;
  color: #718eb1;
  padding: 10px 0;
  font-size: 0.6875rem !important;
  text-align: center;
}
.icon-set .icon a:hover {
  border-radius: 3px;
}
.icon-set .icon i {
  display: block;
  font-size: 1.625rem;
  width: 100%;
  color: #718eb1;
}
.icon-set .icon span {
  font-size: 0.6875rem;
  text-align: center;
}
.code-block ::-moz-selection {
  background: rgba(255, 241, 168, 0.1);
  color: #fff1a8;
}
.code-block ::selection {
  background: rgba(255, 241, 168, 0.1);
  color: #fff1a8;
}
.bd-clipboard {
  position: relative;
  display: none;
  float: right;
}
.btn-clipboard {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 75%;
  color: #ffe3af;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0.25rem;
}
.btn-clipboard:hover {
  color: #151726;
  background-color: #ffe3af;
}
.btn-clipboard:active,
.btn-clipboard:focus {
  outline: 0;
  box-shadow: none;
}
@media (min-width: 768px) {
  .bd-clipboard {
    display: block;
  }
}
.bd-example > .form-control + .form-control {
  margin-top: 0.5rem;
}
.btn-customizer {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #161931;
}
.hljs-keyword,
.hljs-link,
.hljs-literal,
.hljs-section,
.hljs-selector-tag {
  color: #fff;
}
.hljs,
.hljs-subst {
  color: #f0f6ff;
}
.hljs-addition,
.hljs-attribute,
.hljs-built_in,
.hljs-bullet,
.hljs-name,
.hljs-string,
.hljs-symbol,
.hljs-template-tag,
.hljs-template-variable,
.hljs-title,
.hljs-type,
.hljs-variable {
  color: #788db4;
}
.hljs-comment,
.hljs-deletion,
.hljs-meta,
.hljs-quote {
  color: #d0e0f2;
}
pre code {
  font-size: 11px;
}
code[class*='language-'],
pre[class*='language-'] {
  color: #657b83;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
code[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
pre[class*='language-']::-moz-selection {
  background: #073642;
}
code[class*='language-'] ::selection,
code[class*='language-']::selection,
pre[class*='language-'] ::selection,
pre[class*='language-']::selection {
  background: #073642;
}
pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background-color: #fdf6e3;
}
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #93a1a1;
}
.token.punctuation {
  color: #586e75;
}
.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #268bd2;
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string,
.token.url {
  color: #2aa198;
}
.token.entity {
  color: #657b83;
  background: #eee8d5;
  cursor: help;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #859900;
}
.token.function {
  color: #b58900;
}
.token.important,
.token.regex,
.token.variable {
  color: #cb4b16;
}
.mock {
  border-radius: 5px;
  position: relative;
}
.mock .action-bar {
  width: 100%;
  border-radius: 5px 5px 0 0;
  height: 25px;
  padding: 2px 10px;
}
.mock .action-bar .action {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin: 1px;
  display: inline-block;
}
.mock .modal-center span,
.mock .modal-corner-bottom-right span,
.mock .modal-frame-bottom span,
.mock .modal-side-right span {
  float: right;
  margin: 6px 10px;
  font-size: 12px;
}
.mock .action-bar .action.close-button {
  background-color: #ed5159;
}
.mock .action-bar .action.expand-button {
  background-color: #42af46;
}
.mock .action-bar .action.minimize-button {
  background-color: #ffbf37;
}
.mock.bg-dark {
  background-color: #15182b;
}
.mock.bg-dark .action-bar {
  background: #e0eaf6;
}
.mock.bg-light {
  background-color: #rgba 240, 246, 255, 0.6 !important;
}
.mock.bg-light .action-bar {
  background: #15182b;
}
.mock .modal-center,
.mock .modal-corner-bottom-right {
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: #7069ca;
}
.mock .modal-center {
  left: 34%;
  top: 35%;
}
.mock .modal-corner-bottom-right {
  right: 10px;
  bottom: 10px;
}
.mock .modal-frame-bottom,
.mock .modal-side-right {
  position: absolute;
  bottom: 0;
  background-color: #716aca;
}
.mock .modal-side-right {
  border-radius: 0 0 5px;
  width: 85px;
  right: 0;
  top: 25px;
}
.mock .modal-frame-bottom {
  width: 100%;
  height: 30px;
  border-radius: 0 0 5px 5px;
}
#sidebar-settings .color-option-check {
  padding: 10;
  text-align: center;
  background: #eef5ff;
}
#sidebar-settings .title {
  font-size: 12px;
  margin: 0;
  padding: 10px 0 0;
}
#sidebar-settings .color-option-check > label {
  display: block;
  position: relative;
  padding: 10px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
#sidebar-settings .color-option-check > label:first-child {
  margin-left: 0;
}
#sidebar-settings .color-option-check > label:last-child {
  margin-right: 0;
}
#sidebar-settings .color-option-check > label > .color {
  display: block;
  height: 18px;
}
#sidebar-settings .color-option-check > label > .split {
  display: block;
}
#sidebar-settings .color-option-check > label > .split:after,
#sidebar-settings .color-option-check > label > .split:before {
  content: ' ';
  display: table;
}
#sidebar-settings .color-option-check > label > .split:after {
  clear: both;
}
#sidebar-settings .color-option-check > label > .split > .color {
  display: block;
  height: 60px;
}
#sidebar-settings .color-option-check > label > .split > .color:first-child {
  float: left;
  width: 20%;
  border-radius: 3px 0 0 3px;
  display: inline-block;
}
#sidebar-settings .color-option-check > label > .split > .color:last-child {
  float: right;
  width: 80%;
  border-radius: 0 3px 3px 0;
  display: inline-block;
}
#sidebar-settings .color-option-check > label > .icon-check {
  position: absolute;
  display: block;
  left: 45px;
  top: 22px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  text-align: center;
  color: #fff;
  opacity: 0;
}
#sidebar-settings .color-option-check > label > .icon-check:before {
  content: '\f17d';
  font-family: LineAwesome;
  font-size: 30px;
  color: #fff;
}
#sidebar-settings .color-option-check > label > .icon-check.dark:before {
  color: #15182b;
}
#sidebar-settings .color-option-check > label > input[type='radio'] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
#sidebar-settings .color-option-check > label > input[type='radio']:checked + .icon-check {
  opacity: 1 !important;
}
#sidebar-settings .bg-menu-dark {
  background: #15182b;
}
#sidebar-settings .bg-menu-light,
#sidebar-settings .bg-theme-a {
  background: #fff;
}
#sidebar-settings .bg-theme-b,
#sidebar-settings .bg-theme-j {
  background: #736cc7;
}
#sidebar-settings .bg-theme-c,
#sidebar-settings .bg-theme-k {
  background: #399af2;
}
#sidebar-settings .bg-theme-d,
#sidebar-settings .bg-theme-l {
  background: #2fbfa0;
}
#sidebar-settings .bg-theme-e,
#sidebar-settings .bg-theme-m {
  background: #f2989d;
}
#sidebar-settings .bg-theme-f,
#sidebar-settings .bg-theme-n {
  background: #32c1db;
}
#sidebar-settings .bg-theme-g,
#sidebar-settings .bg-theme-o {
  background: #fbd087;
}
#sidebar-settings .bg-theme-h,
#sidebar-settings .bg-theme-p {
  background: #7041ab;
  background: linear-gradient(135deg, #7041ab 21%, #3190f0 100%, #3190f0 100%);
}
body.layout-horizontal #sidebar-settings .bg-theme-a {
  background: #222746;
}
body.layout-horizontal #sidebar-settings .color-option-check > label > .split > .color:first-child {
  float: none;
  width: 100%;
  border-radius: 3px 3px 0 0;
  display: block;
  height: 30px;
}
body.layout-horizontal #sidebar-settings .color-option-check > label > .split > .color:last-child {
  float: none;
  width: 100%;
  border-radius: 0 0 3px 3px;
  display: block;
  height: 15px;
}
body.layout-horizontal #sidebar-settings .color-option-check > label > .icon-check {
  left: 43px;
  top: 16px;
}
