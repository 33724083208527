.table {
    font-family: $font-family-dm-sans !important;
    font-size: 0.9rem;

    thead th {
        font-weight: 400;
        white-space: nowrap;
        color: #8DA0B4;

        // Apply color only when th contains app-sortable-label > div > img
        &:has(app-sortable-label > div > img) {
            color: #000;
        }
    }

    td {
        font-weight: 400;
    }

    td.action-icons {
        padding: 0 0.5rem;

        .icon-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            max-width: 100px;
        }

        .icon-link {
            display: flex;
            align-items: center;
            padding: 0.25rem;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    td,
    th {
        padding: 1.5rem;
        vertical-align: middle;
    }

    td:first-child,
    th:first-child {
        padding-left: 1.25rem;
    }

    .thead-dark th {
        background-color: $primary;
        border-color: $primary;
    }

    .tr-dark td {
        background-color: $primary-light;
        color: #fff;
    }

    tbody tr:hover {
        background-color: rgba(231, 241, 253, 0.2);
    }

    .table-field-actions,
    .table-field-status {
        text-align: center;

        .dropdown {
            position: absolute;
            margin-top: -12px;
            margin-left: 32px;
        }

        .dropdown-menu {
            width: 150px;
        }
    }

    .table-field-status {
        text-align: left;

        .dropdown {
            margin-left: 0px;
        }
    }

    .ng-select .ng-select-container {
        border: none !important;
        background-color: transparent !important;
        font-size: 14px !important;
    }

    .cuppa-dropdown .selected-list .c-btn {
        border: none !important;
    }

    .control.control-outline {
        margin-bottom: 0px !important;
    }

    .label:not(.label-default),
    .view-label {
        font-weight: normal;
        font-size: 12px;
        background-color: transparent !important;
        border: 1px solid #e4eef0;
        color: #8390a0 !important;
        border-radius: 50px;
        padding: 0.2em 0.6em 0.2em;
        white-space: nowrap;
    }

    .label:not(.label-default):before,
    .view-label:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #d2d6de;
        border-radius: 100%;
        display: inline-block;
        margin-right: 7px;
    }

    tbody tr:nth-of-type(even) {
        background-color: #fff;
    }

    tbody tr:nth-of-type(odd) {
        background-color: #F6FBFF;
    }

    thead tr {
        background-color: #fff;
    }

    thead {
        border: 2px solid #E4E9F0;
        border-radius: 4px;
    }

    img {
        filter: invert(67%) sepia(55%) saturate(380%) hue-rotate(164deg) brightness(94%) contrast(88%);
    }

    thead img {
        filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }

    img.no-filter {
        filter: none;
    }

    .grouping-column {
        background-color: #E2F0FE;
    }
}

.pagination .page-item.active .page-link {
    padding: 0px;
    line-height: 30px;
    background-color: transparent;
    border-color: #6DBCDB;
    color: #6DBCDB;
}

.pagination .page-number-item .page-link {
    margin: 0px 8px;
    border-radius: 100%;
    background-color: #F0F6FB;
    color: #000;
}

.fas.fa-sort {
    font-size: 0.8rem;
    margin-left: 0.25rem;
}

.table-responsive-md {
    overflow-x: scroll;
}
