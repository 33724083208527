.sidebar.sidebar-left .sidebar-content {
  width: 255px;
  transition: all 0.3s ease;
}
.sidebar.sidebar-left .sidebar-content .main-menu {
  width: inherit;
  overflow-y: auto;
  padding-bottom: 65px;
}
.sidebar.sidebar-left .sidebar-content .main-menu .sidebar-header {
  margin: 0;
  padding: 0.625rem 1.25rem;
  text-transform: uppercase;
  font-size: 0.6875rem;
  display: block;
  position: relative;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  padding: 0.625rem 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column;
  font-family: Poppins, sans-serif;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  display: block;
  position: relative;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  border-radius: 0 !important;
  font-size: 0.8125rem;
  font-weight: 300;
  border-left: solid 3px transparent;
  padding: 12px 15px 5px 20px;
  width: 100%;
  position: relative;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a i {
  margin-right: 10px;
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .open > a,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .open > a:focus,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .open > a:hover,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a:active,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a:hover {
  opacity: 1;
  width: 100%;
}
body.mini-sidebar .sidebar-left .sidebar-content .brand-text,
body.mini-sidebar .sidebar-left .sidebar-content .btn-menu,
body.mini-sidebar .sidebar-left .sidebar-content .has-arrow::after,
body.mini-sidebar .sidebar-left .sidebar-content .main-menu li a span,
body.mini-sidebar .sidebar-left .sidebar-content .main-menu li.sidebar-header span {
  opacity: 0;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:focus,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:hover {
  width: 100%;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li + li {
  margin-top: 0;
  margin-left: 0;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .nav-dropdown .nav-sub {
  list-style: none;
  padding: 0;
  position: relative;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .nav-dropdown .nav-sub li:last-child {
  padding: 0 0 10px;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .nav-sub > li > a {
  font-size: 0.8125rem;
  font-weight: 300;
  padding: 8px 0 8px 60px;
  display: block;
  position: relative;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .nav.metismenu .nav-dropdown.active > .nav-sub,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .nav.metismenu .nav-dropdown.active > a {
  border-left: solid 0.1875rem;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > .nav-sub > li > .nav-sub > li > a,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > .nav-sub > li > .nav-sub > li > div.radio {
  padding-left: 4.6875rem;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > .nav-sub > li > .nav-sub > li > div.radio label {
  font-weight: 300;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li .badge,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li .label {
  display: block;
  position: absolute;
  right: 1.25rem;
  bottom: 0.5625rem;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu .has-arrow::after {
  right: 25px;
}
@media (max-width: 992px) {
  .sidebar.sidebar-left .sidebar-content {
    position: fixed;
    min-height: 100vh;
    transition: all 0.3s ease;
    transform: translate3D(-100%, 0, 0);
  }
}
body.mini-sidebar .sidebar-left {
  min-height: 100vh;
  position: absolute;
  height: 100%;
  transition: all 0.3s ease;
}
body.mini-sidebar .sidebar-left .sidebar-content {
  white-space: nowrap;
  width: 60px;
}
body.mini-sidebar .sidebar-left .sidebar-content .main-menu li.sidebar-header:before {
  content: '\2f';
  font-family: dripicons-v2 !important;
  font-size: 1.125rem;
  position: absolute;
  left: 22px;
}
body.mini-sidebar .sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  max-width: 60px !important;
}
body.mini-sidebar .sidebar-left .sidebar-content .main-menu .nav.metismenu .collapse.in {
  display: none;
}
body.mini-sidebar .sidebar-left:hover .sidebar-content {
  width: 255px;
}
body.mini-sidebar .sidebar-left:hover .brand-text,
body.mini-sidebar .sidebar-left:hover .btn-menu,
body.mini-sidebar .sidebar-left:hover .main-menu li a span,
body.mini-sidebar .sidebar-left:hover .main-menu li.sidebar-header span {
  opacity: 1;
}
body.mini-sidebar .sidebar-left:hover .main-menu li.sidebar-header:before {
  content: '';
  opacity: 0;
}
body.mini-sidebar .sidebar-left:hover .main-menu li > a i {
  font-size: 1.125rem;
}
body.mini-sidebar .sidebar-left:hover .main-menu .nav.metismenu > li > a {
  max-width: 100% !important;
}
body.mini-sidebar .sidebar-left:hover .main-menu .nav.metismenu .collapse.in {
  display: block;
}
body.mini-sidebar .sidebar-left:hover .has-arrow::after {
  opacity: 1;
}
body.aside-left-open {
  overflow-y: hidden;
}
body.aside-left-open .sidebar.sidebar-left .sidebar-content {
  transform: translate3D(0, 0, 0);
  overflow-y: scroll;
  height: 100vh;
}
