$breadcrumb-divider: url(assets/icons/chevron-right.svg);


@import "bootstrap/scss/bootstrap";
@import './assets/css/theme/main.bundle.css';
@import './assets/css/theme/main.css';
@import './assets/css/theme/default.css';
@import '../node_modules/flatpickr/dist/flatpickr.min.css';

@import './assets/css/common/responsive.scss';
@import './assets/css/common/developer.scss';
@import '../node_modules/sweetalert2/src/sweetalert2.scss';

@import './assets/css/common-v2/colors.scss';
@import './assets/css/common-v2/main-custom.scss';
@import './assets/css/common/main-custom.scss';
@import './assets/css/common/table.scss';

div:where(.swal2-container) {
  z-index: 10000 !important;
}

.flag {
  width: 24px;
  height: 16px;
}

.import-campaign-list {
  max-height: 600px;
  overflow-y: auto;
}

.content-wrapper {
  background-color: #F6FBFF;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  height: 9px !important;
  width: 18px !important;
  border: none !important;
  top: 2px;
  margin-left: 0.5em;
}

.show>.btn-secondary.dropdown-toggle:focus {
  color: black !important;
}

.filter {
  .bulk-actions {
    margin-top: 1rem;
    row-gap: 1em;

    .button {
      margin-left: 0.5rem;
    }

    .btn {
      white-space: nowrap;
    }
  }

  .ng-select.fill .ng-select-container {
    border-radius: 4px;
    padding: 11px;
    text-transform: capitalize;
    font-family: $font-family-dm-sans;
  }

  .ng-select.fill:not(.ng-select-multiple) .ng-select-container .ng-value-container .ng-input {
    padding: 11px;
    min-width: 50px;
  }

  .action .ng-select.fill .ng-select-container {
    padding: 0.25rem 1rem 0.25rem 1rem
  }

  &.d-flex {
    justify-content: end;
    row-gap: 10px;
  }

  .btn {
    height: auto;
    padding: 0.8rem;
    line-height: 0;
    vertical-align: middle;
    display: flex;
    align-items: center;
    border-radius: 4px;
    text-transform: capitalize;
    font-family: $font-family-dm-sans;

    img {
      width: 18px;
      vertical-align: middle;
      margin-left: 0.5rem;
    }


    &:not(.btn-secondary) {
      img {
        filter: invert(1);
      }
    }

    &.btn-secondary {
      background: transparent !important;
      border: 1px solid black !important;
      color: black !important;

      &.show,
      &:hover {
        background-color: $primary !important;
        border: 1px solid $primary !important;
        color: white !important;

        img {
          filter: brightness(0) saturate(100%) invert(100%);
        }
      }
    }

    &.btn-primary:disabled {
      background: grey !important;
      border: 1px solid grey !important;
    }
  }

  .search-group-wrap {
    ng-select {
      cursor: pointer;
      background-color: white;
      border: 1px solid black !important;
      border-radius: 4px;

      .ng-select-container {
        border-bottom: 0 !important;
      }

      input {
        cursor: pointer;
      }

      &.ng-select-opened {
        background-color: $primary;
        border: 1px solid $primary !important;

        .ng-value-label {
          color: white;
        }

        .ng-arrow {
          background-image: url(assets/icons/arrow-down-white.svg) !important;
        }
      }
    }
  }
}

.ng-select.ng-select-filtered .ng-placeholder {
  visibility: hidden;
  display: block !important;
}

.filter .ng-select .ng-select-container {
  border-bottom: 0 !important;
}

.ng-select-disabled.filled-disable {
  border-radius: 4px;
}

.expanding-dropdown-label {
  margin-right: 10px;
}

.flex-wrap {
  flex-wrap: wrap !important;
  row-gap: 20px;
}

.ng-select:not(.ng-select-opened):not(.fill) .ng-placeholder,
input[type="text"]::placeholder {
  color: #868E96 !important;
}

.ng-select.ng-select-opened:not(.fill) .ng-placeholder {
  color: #fff !important;
}

.nav-pills .nav-link.active {
  color: $primary;
  background-color: #fff;
  border-left: 4px solid $primary;
  border-radius: 0;
}

.vertical-tab .nav-item a {
  padding-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 16px;
  line-height: 24px;
  margin: 1em;
}

@media (max-width: 767px) {
  app-new-offers,
  app-setting-sidebar {
    .settings-menu.vertical-tab {
      display: none;
    }

    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.settings-card .card:not(.tag-card) {
  border: 0 !important;
  height: 100%;
  padding: 2em;
}

@media (min-width: 768px) {
  .settings-card .card {
    border-left: 1px solid #E8E8E8 !important;
  }
}