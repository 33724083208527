body #app {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
}
body #app .content-wrapper {
  order: 2;
  width: 100%;
}
body #app .content-wrapper .content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease;
}
body.mini-sidebar #app .content-wrapper {
  padding-left: 60px;
}
body.mini-sidebar #app .content-wrapper.no-sidebar-padding {
  padding-left: 0px;
}
body.layout-fixed .sidebar-left {
  transition: all 0.3s ease;
  min-height: 100vh;
}
body.layout-fixed .sidebar-left .sidebar-content .aside-toolbar {
  transition: all 0.3s ease;
  position: fixed;
  width: 255px;
  top: 0;
  z-index: 99;
}
body.layout-fixed .sidebar-left .sidebar-content .main-menu {
  padding-top: 65px;
}
body.layout-fixed .content-wrapper .top-toolbar {
  right: 0;
  left: 255px;
  position: fixed;
}
body.layout-fixed .content-wrapper .content {
  padding-top: 65px;
  padding-left: 0;
}
@media (max-width: 992px) {
  body.layout-fixed .content-wrapper .top-toolbar {
    right: 0;
    left: 0;
  }
  body.layout-fixed .content-wrapper .content {
    padding-top: 65px;
    padding-left: 0;
  }
  .sidebar-content .aside-toolbar {
    display: none;
  }
  .sidebar-content .main-menu {
    padding-top: 0 !important;
  }
}
body.layout-fixed.mini-sidebar .sidebar-left .sidebar-content .aside-toolbar {
  width: 60px;
}
body.layout-fixed.mini-sidebar .sidebar-left:hover .sidebar-content .aside-toolbar {
  width: 255px;
}
body.layout-fixed.mini-sidebar .content-wrapper .top-toolbar {
  left: 60px;
}
.mini-sidebar .content-wrapper {
  padding-left: 30px;
}
.aside-toolbar {
  height: 65px;
  position: relative;
}
.aside-toolbar .header-controls {
  position: absolute;
  right: 0;
  width: 3.125rem;
  height: 4.0625rem;
  line-height: 4;
  margin: 0;
  padding: 0;
  list-style: none;
}
.aside-toolbar .header-controls .btn-menu {
  padding: 6px 17px 6px 9px;
}
.aside-toolbar .header-controls .btn-menu i {
  font-size: 1.125rem;
}
.site-logo {
  height: 4.0625rem;
  width: inherit;
  padding: 0;
  margin: 0;
  display: inline-block;
  float: left;
  list-style: none;
}
.site-logo li {
  display: inline-block;
  height: 65px;
}
.site-logo li .logo {
  position: absolute;
  top: 22px;
  left: 15px;
}
.site-logo li .logo svg {
  width: 23px;
  height: 23px;
}
.site-logo li .brand-text {
  font-size: 1.125rem;
  font-family: Poppins, sans-serif;
  padding: 20px 0 0 47px;
  font-weight: 400;
  display: block;
}
.site-logo li.app_menu-close {
  float: right;
  display: none;
  padding: 1.1875rem 1.25rem;
}
.site-logo li.app_menu-close a i {
  font-size: 1.875rem;
}
@media (max-width: 991.98px) {
  .site-logo li {
    display: inline-block;
  }
  .site-logo li .logo_mobile {
    position: absolute;
    top: 18px;
    left: -2px;
  }
  .site-logo li .logo_mobile #logo_mobile {
    width: 27px;
    height: 27px;
  }
  .site-logo li .brand-text {
    font-size: 1.3125rem;
    font-family: Poppins, sans-serif;
    padding: 17px 0 0 33px;
    font-weight: 400;
    display: block;
  }
}
.top-toolbar {
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.08);
  display: flex;
  flex-direction: row;
  height: 4.0625rem;
  max-height: 4.0625rem;
  margin: 0;
  padding: 0;
}
.top-toolbar .navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  height: 4.0625rem;
}
.top-toolbar .navbar-nav .nav-item {
  list-style: none;
  min-width: 4.0625rem;
  height: 4.0625rem;
}
.top-toolbar .navbar-nav .nav-item > a {
  text-align: center;
  line-height: 3.5;
  min-width: 3.9375rem;
  display: inline-block;
  height: 4.0625rem;
  position: relative;
}
.top-toolbar .navbar-nav .nav-item > a:hover,
.top-toolbar .navbar-nav .nav-item > a:hover i {
  color: #6760d5;
}
.content[data-layout='tabbed'] .page-header h1,
.top-toolbar .dropdown-menu-xl .dropdown-menu .promo h3,
.top-toolbar .dropdown-menu-xl .dropdown-menu .promo h4 {
  color: #fff;
}
.top-toolbar .navbar-nav .nav-item > a:hover i.icon:after {
  content: '';
  position: absolute;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: rgba(240, 246, 255, 0.7);
  left: -10px;
  top: 11px;
  z-index: -1;
}
.top-toolbar .navbar-nav .nav-item > a i {
  font-size: 1.5rem;
  position: relative;
  max-height: 65px;
}
.top-toolbar .navbar-nav .nav-item.nav-text {
  padding: 0 17px;
}
.top-toolbar .navbar-nav .nav-item.nav-text > a {
  line-height: 4.8;
  font-size: 0.875rem;
}
.top-toolbar .navbar-nav .nav-item.nav-text > a .menu-arrow-down {
  font-size: 0.8125rem;
}
.top-toolbar .navbar-nav > li > a {
  max-height: 4.0625rem;
}
.top-toolbar .nav-item > a > i {
  line-height: 3.2;
}
.top-toolbar .dropdown .dropdown-menu {
  top: 93% !important;
}
.top-toolbar .navbar-nav .nav-item .dropdown-menu-accout .dropdown-header .d-user .media-body span {
  font-size: 0.6875rem;
}
.top-toolbar .navbar-nav .nav-item .dropdown-menu-accout .dropdown-item {
  display: block;
  width: 100%;
  text-align: left;
  line-height: 1.7;
  height: auto;
  padding: 10px 0 10px 20px;
}
.top-toolbar .navbar-nav .nav-item .dropdown-menu-accout .dropdown-item i {
  font-size: 1rem;
  margin-right: 8px;
  vertical-align: top;
}
.top-toolbar .navbar-nav .nav-item .dropdown-menu-accout .badge {
  line-height: 1.6;
}
.top-toolbar .dropdown-menu .menu-header {
  font-size: 1.25rem;
}
.top-toolbar .dropdown-menu ul li a {
  display: block;
}
.top-toolbar .dropdown-menu.menu-icons i {
  font-size: 1.125rem;
  padding-right: 10px;
  vertical-align: top;
}
.navbar-form,
.page-header .actions > .dropdown .dropdown-menu .dropdown-item {
  vertical-align: middle;
}
.top-toolbar .card.card-notification .card-header {
  padding: 5px 20px;
  min-height: 45px;
  height: 45px;
  line-height: 0;
}
.top-toolbar .card.card-notification .card-header .card-title {
  display: inline-block;
}
.top-toolbar .card.card-notification .card-header .actions.top-right {
  right: 15px;
  top: 7px;
}
.top-toolbar .dropdown-menu-md .dropdown-menu.dropdown-menu-left {
  min-width: 220px;
  left: 10px;
}
.top-toolbar .dropdown-menu-md .dropdown-menu.dropdown-menu-right {
  min-width: 220px;
  right: 10px;
}
.top-toolbar .dropdown-menu-xl {
  position: static !important;
}
.top-toolbar .dropdown-menu-xl .dropdown-menu {
  width: 98.5%;
  left: 10px;
  padding: 20px;
}
.top-toolbar .dropdown-menu-xl .dropdown-menu ul.list-items {
  column-count: 2;
  line-height: 2.5;
  margin: 0;
  padding: 0;
  list-style: none;
}
.top-toolbar .dropdown-menu-xl .dropdown-menu img {
  min-width: 100%;
}
.top-toolbar .dropdown-menu-xl .dropdown-menu .promo {
  position: absolute;
  z-index: 5;
  text-align: center;
  padding: 8% 0 0;
  width: 100%;
  height: 100%;
}
.top-toolbar.navbar-desktop {
  z-index: 50;
  display: flex;
  flex-direction: row;
}
.top-toolbar.navbar-desktop .navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  height: 4.0625rem;
}
.top-toolbar.navbar-desktop .navbar-nav.nav-left {
  order: 1;
}
.top-toolbar.navbar-desktop .navbar-nav.nav-right {
  margin: 0 0 0 auto;
  padding: 0;
  order: 2;
  position: relative;
}
.top-toolbar.navbar-mobile.navbar-tablet {
  display: none;
}
@media (max-width: 992px) {
  .top-toolbar.navbar-desktop {
    top: 0;
    transition: all 0.3s ease;
    position: absolute;
    width: 100%;
  }
  .mobile-topbar-toggle .top-toolbar.navbar-desktop {
    top: 65px;
  }
  .mobile-topbar-toggle .content-wrapper .content {
    transition: all 0.3s ease;
    margin-top: 65px;
  }
  .top-toolbar.navbar-mobile.navbar-tablet {
    z-index: 99;
    display: flex;
    flex-direction: row;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    height: 4.0625rem;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav.nav-left {
    order: 1;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav.nav-center {
    order: 2;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav.nav-center.site-logo {
    position: relative;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav.nav-right {
    padding: 0;
    order: 3;
    position: relative;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a {
    line-height: 2.3;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a i {
    font-size: 30px;
    line-height: 2.6;
    max-height: 65px;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a:active i:after,
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a:focus i:after,
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a:hover i:after {
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .top-toolbar.navbar.navbar-desktop .navbar-nav.nav-left {
    display: none;
  }
}
@media (max-width: 576px) {
  .top-toolbar .dropdown {
    position: static;
  }
  .top-toolbar .dropdown .dropdown-menu {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
  }
}
.header-controls .btn-menu {
  height: 65px;
}
.header-controls .btn-menu i {
  transition: all 0.3s ease;
}
.navbar-form {
  position: absolute;
  top: -150%;
  right: 0;
  margin: 0;
  padding: 0;
  height: 65px;
  z-index: 20;
  border: 0;
  left: 0;
  background: #fff;
  transition: all 0.3s ease;
}
.navbar-form.open {
  top: 0;
}
.navbar-form .form-group {
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding-bottom: 0;
}
.navbar-form .form-group .navbar-search:focus {
  border: none;
  outline: 0;
}
.navbar-form .form-group i.close-search {
  height: 30px;
  cursor: pointer;
  top: 50%;
  margin-top: -15px;
  line-height: 30px;
  margin-right: 10px;
  font-size: 1.375em;
  position: absolute;
  right: 30px;
}
.customizer-panel-wrapper,
.sidebar-right {
  display: block !important;
  top: 0;
  bottom: 0;
  backface-visibility: hidden;
  transition: all 0.3s ease;
}
.navbar-form .form-control {
  height: 100%;
  border: 0;
  border-radius: 0;
  width: 100%;
  background-image: none !important;
  padding-left: 20px;
}
.sidebar-left {
  z-index: 999;
  order: 1;
  transition: all 0.3s ease;
}
body.aside-left-open .sidebar-left {
  z-index: 1005;
}
.sidebar-right {
  order: 3;
  z-index: 1005;
  position: fixed;
  overflow: hidden;
  width: 450px !important;
  right: -455px;
  padding: 20px;
  background-color: #fff;
}
body.aside-right-open {
  overflow: hidden;
}
body.aside-right-open .sidebar-right {
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  right: 0;
}
@media (max-width: 576px) {
  .sidebar-right {
    width: 320px !important;
    right: -325px;
  }
}
.sidebar-content #sidebar-tabs .nav-link {
  font-size: 0.9375rem;
}
.sidebar-content #sidebar-tabs .tab-content {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
}
.sidebar-content #sidebar-tabs .list-view-group-header {
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 15px;
  position: absolute;
  left: 15px;
  z-index: 5;
}
.sidebar-content #sidebar-tabs .list-group .list-group-item {
  padding: 0.75rem 0 0.75rem 15px;
  border: none;
  margin-left: 40px;
  margin-right: 10px;
}
.sidebar-content #sidebar-tabs .list-group .list-group-item:hover {
  background-color: #eaf3fd;
  cursor: pointer;
}
.sidebar-content #sidebar-tabs .qt-scroll {
  max-height: 100%;
  height: calc(100vh - 200px);
  position: absolute;
  width: 420px;
}
.customizer-panel-wrapper {
  z-index: 1105;
  position: fixed;
  overflow: hidden;
  width: 450px !important;
  right: -455px;
  padding: 20px;
  background-color: #fff;
}
.customizer-panel-wrapper:after,
.customizer-panel-wrapper:before {
  position: absolute;
  top: 25%;
  content: '';
  height: 50px;
  background-color: #fff;
}
.customizer-panel-wrapper:before {
  left: -47px;
  width: 48px;
  -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 4px 4px 0;
}
.customizer-panel-wrapper:after {
  left: 0;
  width: 5px;
}
.aside-overlay-absolute,
.aside-overlay-fixed {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
}
.aside-overlay-fixed {
  position: fixed;
}
.aside-overlay-absolute {
  position: absolute;
}
.page-header {
  padding: 30px 30px 0;
  position: relative;
}
.page-header h1 {
  margin: 0;
  display: inline-block;
  padding: 7px 20px 7px 0;
}
.page-header h1.separator {
  border-right: solid thin rgba(148, 170, 197, 0.2);
}
.page-header .actions {
  z-index: 10;
}
.page-header .actions.top-right {
  right: 30px;
  top: 30px;
  bottom: 0;
}
.page-header .actions.top-left {
  left: 20px;
  top: 30px;
  bottom: 0;
}
.page-header .actions > .dropdown .dropdown-menu {
  padding: 0;
}
.page-header .actions > .dropdown .dropdown-menu .dropdown-header {
  display: block;
  padding: 15px 20px 10px;
  margin-bottom: 0;
  font-size: 0.8125rem;
  white-space: nowrap;
  border-radius: 3px 3px 0 0;
  text-transform: uppercase;
  font-weight: 400;
}
.page-header .actions > .dropdown .dropdown-menu .dropdown-item i {
  font-size: 18px;
  width: 22px;
  vertical-align: middle;
  margin-right: 7px;
}
.page-header .actions > .dropdown > a.btn.btn-fab,
.page-header .actions > li > a.btn.btn-fab {
  box-shadow: 0 3px 20px 0 rgba(18, 106, 211, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 0;
  border-color: transparent !important;
}
.page-header .actions > .dropdown.show > a.btn.btn-fab {
  box-shadow: none;
}
.page-header .actions > a.btn-fab > i,
.page-header .actions > li > a.btn-fab > i {
  width: 40px;
  height: 40px;
  margin: 0 0 0 -1px;
  line-height: 1.7;
  font-size: 1.4375rem;
}
.page-header .actions.mobile-menu {
  display: none;
}
@media (max-width: 992px) {
  .page-header .actions.mobile-menu {
    display: block;
  }
}
@media (max-width: 768px) {
  .page-header h1.separator {
    border-right: none;
  }
  .page-header .actions.mobile-menu {
    display: block;
  }
  .page-content.container,
  .page-header.container {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .page-header.fit-mobile .actions {
    top: 120px !important;
  }
  .page-header.fit-mobile + .page-content {
    padding: 85px 20px 20px;
  }
}
.page-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 30px;
}
@media (max-width: 576px) {
  .page-content {
    padding: 15px !important;
  }
}
.page-aside-left .aside-left .card .card-body,
.page-aside-left .aside-right .card .card-body,
.page-aside-right .aside-left .card .card-body,
.page-aside-right .aside-right .card .card-body {
  padding: 0;
}
.page-aside-left,
.page-aside-right {
  display: flex;
  flex-direction: row !important;
  height: calc(100vh + 240px);
}
.page-aside-left .aside-left,
.page-aside-right .aside-left {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  background: #fff;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
}
.page-aside-left .aside-left .card,
.page-aside-right .aside-left .card {
  box-shadow: none;
  border-radius: 0;
  background: tranparent;
}
.page-aside-left .aside-right,
.page-aside-right .aside-right {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  background: #fff;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07), 0 2px 9px 0 rgba(18, 106, 211, 0.06);
}
.page-aside-left .aside-right .card,
.page-aside-right .aside-right .card {
  box-shadow: none;
  border-radius: 0;
  background: tranparent;
}
.page-aside-left .main-content,
.page-aside-right .main-content {
  flex: 1 1 auto;
  max-width: 100%;
  overflow: auto;
}
@media (max-width: 992px) {
  .page-aside-left .aside-left,
  .page-aside-right .aside-right {
    position: absolute;
    z-index: 1001;
    height: 100%;
    transition: all 0.3s ease;
  }
  .page-aside-left {
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column !important;
  }
  .page-aside-left .page-header h1 {
    padding-left: 60px;
  }
  .page-aside-left .aside-left {
    left: -240px;
  }
  .page-aside-right {
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column !important;
  }
  .page-aside-right .aside-right {
    right: -240px;
  }
  .page-aside-left .aside-left.open {
    left: 0;
  }
  .page-aside-right .aside-right.open {
    right: 0;
  }
}
.content[data-layout='tabbed'] .page-header {
  padding: 30px 30px 80px;
  position: relative;
  background-color: #6760d5;
}
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper li,
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper li a {
  color: rgba(255, 255, 255, 0.7);
}
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper li a:active,
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper li a:hover {
  color: #fff;
}
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item > a > i {
  color: rgba(255, 255, 255, 0.7);
}
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item > a > i:active,
.content[data-layout='tabbed'] .page-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item > a > i:hover {
  color: #fff;
}
.content[data-layout='tabbed'] .page-header .nav.nav-tabs {
  position: absolute;
  bottom: 0;
  margin: 0;
  border-bottom: none;
}
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item {
  margin-bottom: 0;
}
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item .nav-link.active.show,
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item .nav-link:hover,
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background: 0 0;
}
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item .nav-link {
  border: none;
  font-size: 0.9375rem;
  border-bottom: 3px solid transparent;
  color: rgba(255, 255, 255, 0.7);
}
.content[data-layout='tabbed'] .page-header .nav.nav-tabs .nav-item .nav-link.active {
  border-bottom: solid 3px #fff;
}

/* ng-select styling */
div.ng-dropdown-panel-items {
  z-index: 10000;
}

ng-select.width-limited div.ng-select-container {
  min-width: 160px;
}

ng-select.width-limited div.ng-input {
  max-width: 30px;
}

ng-select.ng-select-disabled div.ng-value {
  color: #d6d9dc;
}

.overflow-x {
  overflow-x: scroll;
}

.file-select {
  height: 80px;
  border: 1px solid #3d89a8 !important;
  border-radius: 0px !important;
  cursor: pointer;
}
