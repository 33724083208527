.ng-select.is-invalid .ng-select-container {
  border-bottom: 1px solid #ff5c75 !important;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none !important;
}
.form-control.no-border {
  border-bottom: none !important;
}
.ngx-file-drop__drop-zone {
  position: relative;
  height: 80px !important;
  margin: 0px !important;
  border: 1px solid #3d89a8 !important;
  border-radius: 0px !important;
}
.ngx-file-drop__content {
  height: 80px !important;
  color: #3d89a8 !important;
}
.import-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ngselect-custom-label {
  padding: 15px;
  margin: 0;
  pointer-events: none !important;
}
.ng-dropdown-panel.ng-select-multiple::before {
  content: '';
  z-index: -1;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  background: #fff;
  position: absolute;
  left: 50%;
  top: 0;
  width: 25px;
  height: 25px;
}
.ng-dropdown-panel {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  width: 240px;
}
.is-invalid .selected-list .c-btn {
  border-radius: 0px;
  border-bottom: 1px solid #ff5c75;
}

.image-preview {
  max-height: 40px;
  object-fit: contain;
  border-radius: 2px;
}

.action-icon {
  max-width: unset;
}

.expanding-dropdown-panel {
  width: auto !important;
  max-width: 600px !important;
}

.force-text-center {
  text-align: center !important;
}

.no-padding {
  padding: 0px !important;
}

.normal-text {
  text-transform: none;
}

.hidden {
  display: none;
}

.disabled {
  color: #d6d9dc !important;
  border-color: #d6d9dc !important;
  cursor: not-allowed;
}

.w-full {
  width: 100% !important;
}

.clickable {
  cursor: pointer;
}
