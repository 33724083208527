/* You can add global styles to this file, and also import other style files */

/*****************************************************************************
    Styles
******************************************************************************/
body {
  background-color: #f5f5f5;
  color: $black;
  font-size: 14px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
body,
html,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  font-family: $font-family-dm-sans;
  font-weight: 400;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}
h2 {
  font-size: 1.5rem;
}
a,
.pointer {
  cursor: pointer !important;
}
a:link,
a:visited {
  color: $primary;
}
.mini-sidebar .content-wrapper {
  padding-left: 0px;
}
.text-primary {
  color: $primary !important;
}
.text-black {
  color: $black !important;
}
.text-light {
  color: $light !important;
}
.text-dark {
  color: $dark !important;
}
.font-cabin {
  font-family: $font-family-dm-sans !important;
}
.font-raleway {
  font-family: $font-family-dm-sans !important;
}
.text-primary-light {
  color: $primary-light !important;
}
.text-accent {
  color: $accent;
}
a.text-primary:focus,
a.text-primary:hover {
  color: $black !important;
}
a.default-color:active,
a.default-color:hover,
a:active,
a:hover {
  color: $primary !important;
}
.control.control-outline {
  color: $black !important;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-aqua {
  background: #f0f8ff !important;
}
.text-underline {
  text-decoration: underline !important;
}
/*****************************************************************************
    Button
******************************************************************************/
.btn {
  font-family: $font-family-dm-sans;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 0px;
  height: 35px;
  font-weight: 400;
  text-transform: uppercase;
}
.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}
.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
  background-color: darken($primary, 6%) !important;
  border: 1px solid darken($primary, 6%) !important;
  box-shadow: none !important;
  color: $white;
}
.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
  background-color: darken($primary, 6%) !important;
  border: 1px solid darken($primary, 6%) !important;
  color: $white !important;
  box-shadow: none !important;
}
.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
  background-color: darken($primary, 6%);
  border: 1px solid darken($primary, 6%);
  color: $white !important;
  box-shadow: none !important;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  color: #fff;
  background-color: $primary !important;
  border-color: $primary !important;
  box-shadow: none !important;
}
.btn-primary:disabled {
  cursor: not-allowed !important;
}
.btn-secondary,
a:link.btn-secondary {
  background-color: white !important;
  border: 1px solid $primary !important;
  color: #000 !important;
}
.btn-secondary:hover,
a:link.btn-secondary:hover {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  box-shadow: none !important;
  color: $white !important;
}
.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $primary !important;
  border-color: $primary !important;
  color: $white !important;
  box-shadow: none;
}
.btn-secondary:focus,
a:link.btn-secondary:focus {
  background-color: $white !important;
  border: 1px solid $primary !important;
  color: $primary !important;
}
.btn-secondary:active,
a:link.btn-secondary:active {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  color: $white !important;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  color: $white !important;
}
.btn-primary2 {
  background-color: #6e85af !important;
  border-color: #6e85af;
  color: #fff;
}
.btn-secondary:hover i {
  color: $white !important;
}
.btn-secondary:focus i {
  color: $primary !important;
}
.btn-secondary:active i,
a:link.btn-secondary:active i {
  color: $white !important;
}
.btn-link {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}
.btn-icon-only {
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  padding: 0px !important;
  border: 1px solid $light;
  border-radius: 100%;
  background-color: $white;
}

/*****************************************************************************
    Form
******************************************************************************/
.form-group {
  margin-bottom: 25px;
}
.form-control {
  font-family: $font-family-dm-sans, sans-serif;
  border-width: 0;
  border-color: $primary;
  border-bottom-width: 1px;
  border-radius: 0px;
  font-size: 18px;
  padding: 0.313rem 0.75rem;
  padding-left: 0px;
  background-color: transparent;
  &:disabled,
  &[readonly] {
    background-color: transparent;
    color: $light;
    cursor: not-allowed;
    border-color: $light;
  }
  &.full {
    border-width: 1px;
    padding: 0.313rem 0.75rem;
  }
}
.form-control.disabled {
  border-color: $light;
}
.form-control:focus {
  border-color: $primary;
  background-color: transparent;
}
.custom-file-label,
.custom-file-label:after,
.form-control,
.flatpickr-input {
  color: $black;
}
label {
  font-family: $font-family-dm-sans, sans-serif;
  color: $primary;
  margin-bottom: 0.3rem;
}
.disabled label {
  color: $light;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-file-label,
.custom-file-label:after,
.form-control,
.form-control-plaintext,
.form-control:focus {
  font-size: 16px;
}
::-webkit-input-placeholder {
  color: #d6d9dc !important;
  font-size: 17px;
  font-weight: 400 !important;
}
::-moz-placeholder {
  color: #d6d9dc !important;
  font-size: 17px;
  font-weight: 400 !important;
}
:-ms-input-placeholder {
  color: #d6d9dc !important;
  font-size: 17px;
  font-weight: 400 !important;
}
:-moz-placeholder {
  color: #d6d9dc;
  font-size: 17px;
  font-weight: 400 !important;
}
.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
  .col,
  [class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
label em,
.control-label em {
  font-style: normal;
  color: $red;
  font-weight: normal;
  font-family: arial;
}

.has-error {
  .ng-select .ng-select-container {
    border-color: $danger;
  }
}

/* NgSelect */
.ng-select .ng-select-container {
  border: none;
  background-color: transparent;
  color: $black;
  position: initial !important;
  padding: 0.375rem 0.4rem 0.375rem 0rem;
  line-height: 1.6;
  font-size: 16px;
  font-weight: normal;
  border-radius: 0px;
  font-family: $font-family-dm-sans;
}
.ng-select-disabled .ng-select-container {
  border-bottom: 1px solid $light !important;
}
.ng-select-disabled .ng-select-container,
.ng-select-disabled input {
  background-color: transparent !important;
  color: $light !important;
  cursor: not-allowed !important;
  border-color: $light !important;
}
.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}
.ng-select-disabled.filled-disable {
  background: $light !important;
}
.ng-select:not(.ng-select-multiple) .ng-select-container .ng-value-container .ng-input {
  top: 0;
  padding: 0.375rem 1.3rem 0.375rem 0rem;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  vertical-align: 1px;
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 0.875rem;
  padding: 6px 0.75rem;
  font-family: $font-family-dm-sans;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 6px !important;
  width: 12px !important;
  border: none !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: $black;
  background-color: $primary-light;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $primary;
  color: #fff;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  // border-top-right-radius: 4px;
  // border-top-left-radius: 4px;
  // border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}
.ng-select .ng-clear-wrapper {
  display: none;
}
.ng-select .ng-clear-wrapper .ng-clear {
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: -2px;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-placeholder {
  font-size: 16px;
  color: #d6d9dc;
  font-weight: 400;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border: 1px solid #3d89a8;
  background-color: #fff;
  padding: 0 6px 1px 5px;
  margin: 2.5px 5px 2.5px 0;
  color: #3d89a8;
  line-height: 17px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  margin-left: 5px;
  float: right;
  font-size: 18px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 12px;
  color: #3d89a8;
}
.input-group > .ng-select {
  position: relative;
  width: 1%;
  margin-bottom: 0;
}
.input-group .ng-select:not(:first-child) .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.input-group .ng-select:not(:last-child) .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.ngselect2-modal .ng-dropdown-panel {
  position: relative;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  font-family: $font-family-dm-sans;
}
.ng-dropdown-panel-items .ng-optgroup {
  padding: 6px 0.75rem;
  color: $grey;
}
/* Switch */
.tgl + .tgl-btn {
  width: 2.5em;
  height: 1.3em;
}

/* Styles for toggle-label directive */
.tgl-label-on,
.tgl-label-off {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  color: white; /* Set text color to white */
  width: 50%; /* Take up the full width of the container */
  text-align: center; /* Center-align the text */
  display: none; /* Hide both labels by default */
}

/* Style for the "ON" label when the toggle is checked */
.tgl:checked + .tgl-btn .tgl-label-on {
  left: 0; /* Reset left position */
  display: inline; /* Display the "ON" label when checked */
  font-size: 8px;
}

/* Style for the "OFF" label when the toggle is unchecked */
.tgl:not(:checked) + .tgl-btn .tgl-label-off {
  right: 0; /* Reset right position */
  display: inline; /* Display the "OFF" label when unchecked */
  font-size: 8px;
}

/* Dropdown */
.log-arrow-up,
.nav.metismenu .log-arrow-up {
  z-index: -1;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  background: #fff;
  position: absolute;
  right: 10%;
  top: -3px;
  width: 25px;
  height: 25px;
}
.nav.metismenu .log-arrow-up {
  left: -12px;
  top: 50px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-filter: drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.05));
}
.top-toolbar .dropdown .dropdown-menu {
  top: 102% !important;
}
.dropdown-menu {
  color: $black;
  border-radius: 0px;
}
.dropdown .dropdown-menu,
.dropleft .dropdown-menu,
.dropright .dropdown-menu,
.dropup .dropdown-menu,
.input-group .dropdown-menu {
  padding: 22px 20px;
  width: 240px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}
.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item {
  font-size: 17px;
  color: $black;
  white-space: normal;
}
.top-toolbar .navbar-nav .nav-item .dropdown-menu-account .dropdown-item {
  padding: 13px 15px 13px 15px;
}
.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
  background-color: rgba(245, 245, 245, 0.5);
  color: #3d89a8 !important;
}
.nav.metismenu .dropdown .dropdown-menu,
.nav.metismenu .dropleft .dropdown-menu,
.nav.metismenu .dropright .dropdown-menu,
.nav.metismenu .dropup .dropdown-menu,
.nav.metismenu .input-group .dropdown-menu {
  top: -25% !important;
}
.nav.metismenu .dropdown .dropdown-menu.dropdown-menu-right {
  left: 100% !important;
}
.nav.metismenu .dropdown .dropdown-menu {
  transform: inherit !important;
  -webkit-animation-name: inherit;
  animation-name: inherit;
}
.dropdown-header-title {
  font-size: 20px !important;
  color: $primary-light !important;
}
.nav.metismenu .dropdown .dropdown-menu .dropdown-header-title:hover {
  background: none !important;
  color: #6dbcdb !important;
}
.nav.metismenu .dropdown-menu-account {
  display: none;
}
.nav.metismenu .dropdown:hover .dropdown-menu-account {
  display: block;
}
.nav.metismenu .dropdown .dropdown-menu .dropdown-item {
  padding: 10px 12px;
}
.dropdown .filter-dropdown {
  top: 140% !important;
  padding: 25px !important;
  width: 370px !important;
  .dropdown-header-title.text-dark {
    color: $dark !important;
  }
  li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

/*****************************************************************************
    Tabs
******************************************************************************/
.card.card-tabs .nav.nav-tabs li a {
  font-size: 0.875rem;
  font-weight: normal;
  height: 60px;
  padding: 1.4rem 1.5rem 1.3rem;
}
.nav-tabs {
  margin-bottom: 0px;
}
/*****************************************************************************
    Dropdown
******************************************************************************/
.dropdown-toggle {
  &::after {
    display: none;
  }
}
.dropdown.filters .dropdown-menu,
.dropdown300 {
  width: 300px !important;
}

.card.card-notification .card-body {
  overflow-y: auto;
}

.input-group ngb-datepicker.dropdown-menu {
  min-width: 10rem !important;
  top: auto !important;
  width: auto !important;
}

ngb-datepicker {
  .ngb-dp-weekdays,
  .ngb-dp-header {
    background-color: $light;
    border-color: rgb(223, 231, 243);
  }
  .ngb-dp-weekday {
    color: $primary;
  }
  .btn-light {
    &:hover,
    &:active {
      box-shadow: none !important;
    }
  }
  .bg-primary:not(:hover) {
    color: #fff !important;
  }
  .ngb-dp-today .btn-light:not(:hover) {
    color: #fff !important;
    background-color: $accent !important;
  }
}

span.flatpickr-weekday {
  color: $black;
}
.flatpickr-input {
  border: none;
  width: 100%;
  padding: 0.313rem 0.75rem;
  padding-left: 0px;
  background-color: transparent;
}
.flatpickr-calendar {
  font-family: $font-family-dm-sans;
  border-radius: 0px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 325.875px;
}
.flatpickr-months {
  position: relative;
  padding-bottom: 10px;
  .flatpickr-prev-month,
  .flatpickr-next-month {
    line-height: 14px;
  }
}
.flatpickr-day {
  color: $black;
  &.today {
    border-color: $primary-light;
    background: $primary-light;
    color: #fff;
    &:hover,
    &:focus {
      border-color: $primary;
      background: $primary;
    }
  }
  &.nextMonthDay,
  &.prevMonthDay {
    color: $muted;
  }
  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background: $light;
    border-color: $light;
  }
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    border-color: $primary;
    background: $primary;
  }
  &.selected.startRange + .endRange,
  &.startRange.startRange + .endRange,
  &.endRange.startRange + .endRange {
    box-shadow: -10px 0 0 $primary;
  }
  &.inRange {
    box-shadow: -10px 0 0 $light;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover {
    color: $muted !important;
  }
}

/*****************************************************************************
    Page Header
******************************************************************************/

.page-header h1 {
  font-size: 28px;
  color: $primary;
  padding: 0px;
}
.page-header .btn,
a:link.btn,
.removebtn,
.button-with-textbox {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}
.page-header .input-group .input-group-text {
  line-height: 0.9;
}
.page-header {
  padding: 30px 40px 0;
}
.page-content {
  padding: 40px;
}
.page-header ~ .page-content {
  padding-top: 20px;
}
.site-logo li .logo {
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}
.site-logo {
  height: 5rem;
  li {
    height: 80px;
  }
}
/* ********************************************************************************************
    Login
*********************************************************************************************** */

.login-box,
.register-box {
  width: 100%;
  max-width: 428px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 0px !important;
  margin: 5% auto;
  background: #fff;
}
.login-logo,
.register-logo {
  background: $white;
  margin-bottom: 0;
  padding-top: 40px;
  text-align: center;
}
.login-box-msg,
.register-box-msg {
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  text-align: center;
}
.login-box-body,
.register-box-body {
  color: inherit;
  padding: 35px 25px;
  background: #fff;
}
.login-box-footer {
  background-color: $grey;
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
}
.login-box-bg {
  background-color: $black;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.custom-control-label {
  cursor: pointer;
}
.main-container {
  position: relative;
  min-height: 100vh;
}
.auth-page-title {
  text-align: center;
  padding-top: 30px;
}

/*****************************************************************************
    Header & Sidebar
******************************************************************************/

body .sidebar-left {
  position: relative;
}
body .sidebar-left,
body .sidebar-left .sidebar-content {
  background: #fff;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-size: 18px;
}
.sidebar.sidebar-left .sidebar-content .main-menu {
  overflow-y: inherit !important;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}
body .top-toolbar.navbar-desktop {
  background: $black;
}
body .aside-toolbar,
body .top-toolbar.navbar-mobile.navbar-tablet {
  background-color: $black;
}
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a i {
  color: #fff;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-weight: 600;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.nav-dropdown > ul > li.nav-dropdown > ul > li > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.nav-dropdown > ul > li.nav-dropdown > ul > li > a > i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a > i {
  color: $black;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a:focus,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a > i,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown.active
  > ul
  > li.nav-dropdown.active
  > ul
  > li.active
  > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.nav-dropdown > ul > li.nav-dropdown > ul > li > a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a:active,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a:hover {
  color: $black;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li a:hover i {
  color: $primary !important;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:focus,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:hover {
  color: $black !important;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:focus,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active > a:hover {
  border-left: none !important;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active .sidebar-img-inactive {
  display: none;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li.active .sidebar-img-active {
  display: inline-block;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  padding: 25px 15px 25px 15px;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a span {
  vertical-align: middle;
}
body.mini-sidebar .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a span {
  display: none;
}
body.layout-fixed .top-toolbar {
  right: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}
body.layout-fixed .sidebar-left {
  position: fixed;
}
body.layout-fixed .content-wrapper .content,
body.layout-fixed .sidebar-left .sidebar-content .main-menu {
  padding-top: 100px;
}
.top-toolbar {
  height: 5rem;
  max-height: 5rem;
}
.top-toolbar .navbar-nav .nav-item,
.top-toolbar .navbar-nav .nav-item > a,
.top-toolbar .navbar-nav > li > a {
  max-height: 5rem;
}
.top-toolbar .navbar-nav .nav-item,
.top-toolbar .navbar-nav .nav-item > a,
.top-toolbar.navbar-desktop .navbar-nav {
  height: 5rem;
}
.top-toolbar .navbar-nav .nav-item > a {
  line-height: 4.5;
}
.top-toolbar .navbar-nav .nav-item > a:hover,
.top-toolbar .navbar-nav .nav-item > a:hover i,
.top-toolbar .navbar-nav .nav-item > a i {
  color: #fff;
}
.sidebar-img-active,
.sidebar-img-inactive {
  width: 24px !important;
  height: 24px !important;
  display: inline-block;
  vertical-align: middle;
  margin-right: 22px;
}
.sidebar-img-active {
  display: none;
}
.user-avatar .user-img {
  height: 54px;
  width: 54px;
}
.navbar-nav .nav-item a:hover {
  color: #fff !important;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-family: $font-family-dm-sans;
  font-weight: 400;
}
.sidebar.sidebar-left .sidebar-content .main-menu {
  padding-bottom: 80px;
}
.sidebar.sidebar-left .sidebar-content {
  width: 350px;
}
body.mini-sidebar .sidebar-left:hover .sidebar-content {
  width: 60px;
}
body {
  .collapse-menu {
    position: absolute;
    bottom: 0px;
    padding: 10px;
    color: $primary;
    left: 0;
    right: 10px;
    text-align: right;
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }
  &.mini-sidebar {
    .collapse-menu {
      text-align: center;
    }

    .collapse-menu span {
      display: none;
    }
  }
}

/*****************************************************************************
    Chart
******************************************************************************/

.pie-chart-legend {
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-family: $font-family-dm-sans;
      margin-bottom: 25px;
      font-size: 1rem;
      white-space: nowrap;
      display: inline-block;
      margin-right: 25px;
      span {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .table {
    font-size: 16px;
    margin: 0px auto;
    max-width: 95%;
    white-space: nowrap;
    th {
      padding-top: 0px;
      white-space: normal;
      font-size: 0.875rem;
    }
    th:first-child,
    td:first-child {
      padding-left: 0px;
    }
    th:last-child,
    td:last-child {
      padding-right: 0px;
    }
    .value {
      font-weight: 600;
    }
    .colorbox {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: -1px;
      margin-right: 6px;
    }
  }
}

.chart-filter {
  .ng-select {
    .ng-select-container {
      border-bottom: none !important;
      .ng-value {
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 100%;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    &.red {
      .ng-select-container {
        .ng-value {
          &::before {
            background-color: #e74747;
          }
        }
      }
    }
    &.blue {
      .ng-select-container {
        .ng-value {
          &::before {
            background-color: #3d89a8;
          }
        }
      }
    }
  }
}

/* **************************************************************************************
    Others
*************************************************************************************** */
.noti-count {
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: center;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  background: #f33737;
  color: #fff;
}
.actions .btn {
  height: 35px;
  padding: 6px 20px;
}
.actions.top-right {
  top: 13px;
}
audio {
  width: 250px;
  height: 33px;
}
.user-list li .user-name {
  margin-left: 15px;
  font-weight: 500;
}
.m-t-2 {
  margin-top: 2px;
}
.rtl {
  text-align: right;
}
.video-bg-img {
  position: relative;
  display: inline-block;
  background: rgba(0, 0, 0, 0.35);
}
.video-bg-img img {
  height: 40px;
  width: 40px;
}
.video-bg-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.o-cover {
  object-fit: cover;
}
.icon-group .fileinput-exists img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.sign-in-form {
  margin: 10% auto;
}
.modal {
  z-index: 9999;
}
.modal-dialog-scrollable .component-host-scrollable > form {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 3.5rem);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 3.5rem);
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-title {
  font-size: 24px;
}
.modal-backdrop {
  background: $black;
  backdrop-filter: blur(4px);
}
.modal-backdrop.show {
  opacity: 0.42;
}
.w-180 {
  width: 180px;
}
.custom-my-8 {
  margin: 8px 0px;
}
.h-9 {
  height: 9px;
}
.fileinput .thumbnail > img {
  height: 150px;
  width: 150px;
}
.progress {
  height: 9px;
}
.card-deck .card {
  min-height: 100%;
  margin: auto 0px;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.44rem 0.5rem;
}
.label {
  color: $primary;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fancybox-slide--iframe .fancybox-content {
  width: 800px;
  height: 600px;
  max-width: 80%;
  max-height: 80%;
  margin: 0;
}
.accordion > .card > .card-header .btn-link:after {
  top: 50%;
  right: 5.3em;
}
.btn.btn-sm,
a:link.btn.btn-sm {
  height: 35px;
}
.btn.btn-xs,
a:link.btn.btn-xs {
  height: 25px;
  padding: 2px 10px;
}
.mt-custom {
  margin-top: 35px;
}
.custom-ad-padding {
  padding: 20px;
}
/* **************************************************************************************
    Pages
*************************************************************************************** */
.title {
  text-transform: uppercase;
  margin-bottom: 25px;
}
.title-small {
  font-family: $font-family-dm-sans;
  color: $primary-light;
}
.custom-border {
  border-right: 1px solid #d6d9dc !important;
}
.p-50.pb-20 {
  padding-bottom: 20px !important;
}
.user-media-body {
  line-height: normal;
  h5 {
    font-size: 18px !important;
    color: $white;
  }
}
.breadcrumb-position {
  position: absolute;
  left: 390px;
}
.user-avatar:hover .user-media-body h5 {
  color: $primary-light !important;
}
.has-password-icon {
  position: relative;
  .form-control {
    padding-right: 42.5px;
  }
  .form-control-feedback {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 37px;
    height: 37px;
    line-height: 37px;
    cursor: pointer;
    font-size: 12px;
    color: $primary;
  }
}
.input-group-text {
  font-family: $font-family-dm-sans, sans-serif;
  background-color: transparent;
  font-size: 16px;
  color: #bfbfbf;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid $primary !important;
}
.form-control:disabled ~ .input-group-append > .input-group-text {
  border-color: $light !important;
}
.border-left-primary {
  border-left: 1px solid $primary !important;
}
.pure-checkbox label {
  color: $black;
  cursor: pointer;
}
.pure-checkbox input[type='checkbox'] + label:before {
  color: $primary;
  border: 1px solid $primary;
  border-radius: 0px !important;
}
.pure-checkbox input[type='checkbox']:checked + label:before {
  background: $white;
}
.pure-checkbox input[type='checkbox'] + label:after {
  border-color: $primary;
}

.card {
  border-radius: 0px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  .card-footer {
    padding: 50px 0px 0px 0px;
    border: 0px;
  }
}
.filter {
  font-family: $font-family-dm-sans;
  padding-left: 0px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #707070;
  .btn {
    height: 26px;
    font-size: 14px;
    padding: 2px 16px;
    img {
      width: 12px;
      vertical-align: 1%;
      margin-left: 0.5rem;
    }
  }
  .form-control,
  .input-group-text {
    border-bottom: 1px solid $light !important;
    background-color: transparent;
    font-size: 16px;
  }
  .ng-select {
    .ng-select-container {
      border-bottom: 1px solid $light !important;
      background: transparent;
    }
    &.fill {
      .ng-select-container {
        background: $primary;
        padding: 0.25rem 1rem 0.25rem 1rem;
        font-size: 14px;
        color: $white;
        border: none !important;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-dm-sans;
        .ng-placeholder {
          color: $white;
        }
      }
      &:not(.ng-select-multiple) .ng-select-container .ng-value-container .ng-input {
        padding: 0.25rem 1.3rem 0.25rem 1rem;
        > input {
          color: $white;
        }
      }
      .ng-placeholder {
        font-size: 14px;
      }
    }
  }
}
.filter-responsive {
  width: 100%;
}

.tab-content .card {
  padding: 30px 30px 20px;
  box-shadow: none;
  border: 0;
  margin: 0;
  .card-header {
    font-family: $font-family-dm-sans;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #707070;
    .btn {
      height: 26px;
      font-size: 14px;
      padding: 2px 16px;
    }
    .ng-select .ng-select-container {
      border-bottom: 1px solid $light !important;
    }
  }
}
.v-none {
  visibility: hidden;
}
.modal {
  .modal-content {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 0px;
  }
  .modal-header {
    padding: 30px 40px 10px 40px;
    border: 0px;
  }
  .modal-body {
    padding: 20px 40px 0px 40px;
  }
  .modal-footer {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 40px;
    border: 0px;
  }
}
.drop-zone {
  border: 1px solid $primary;
  height: 80px;
}
.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect,
.tgl-flat.tgl-flat-primary:checked + .tgl-btn:after,
.tgl-primary:checked + .tgl-btn {
  background-color: $primary;
}
.dropdown .control.control-outline {
  margin-bottom: 0px !important;
}
.noti-list {
  li {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  h4 {
    font-family: $font-family-dm-sans;
    color: $primary;
    margin: 0;
  }
}
.password-list {
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-family: $font-family-dm-sans;
    color: $primary;
    margin: 0;
  }
}
.card.tag-card {
  font-family: $font-family-dm-sans;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  padding: 0px !important;
  border-radius: 0px;
  .card-body {
    padding: 15px;
  }
  .tag-img {
    margin-right: 40px;
  }
}
// Multiselect Checkbox
.cuppa-dropdown {
  .selected-list .c-btn {
    font-family: $font-family-dm-sans;
    padding-left: 0px !important;
    min-height: 35px;
    color: $black;
    border: none;
    background: transparent;
    border-bottom: 1px solid $primary;
    border-radius: 0px;
    font-size: 16px;
    padding-right: 45px;
    span {
      color: $light;
    }
  }
  .selected-list .c-angle-down svg,
  .selected-list .c-angle-up svg {
    display: none;
  }
  .selected-list .c-angle-down,
  .selected-list .c-angle-up {
    background-image: url(./../../icons/plus.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .selected-list .c-list .c-token {
    background: #fff;
    color: $primary !important;
    border: 1px solid $primary;
    padding: 1px 20px 2px 6px;
    margin-top: 3px;
    border-radius: 0px;
    font-size: 12px;
  }
  .selected-list .c-list .c-token .c-remove {
    width: 6px;
    margin-top: -1px;
    svg {
      fill: $primary;
    }
  }
  .selected-list .c-list .c-token .c-label {
    color: $primary;
    .flag {
      width: 16px;
      border: 1px solid #efefef;
      margin-right: 5px;
    }
  }
  .lazyContainer {
    height: auto !important;
  }
  .list-area {
    border: 1px solid $light;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  }
  .arrow-2 {
    border-bottom: 15px solid $light;
  }
  .pure-checkbox.select-all,
  .list-filter {
    border-bottom: 1px solid $light;
  }
  .list-filter {
    height: 45px;
    padding-left: 38px;
    input {
      font-family: $font-family-dm-sans;
    }
    .c-search {
      top: 11px;
      svg {
        fill: $primary;
      }
    }
  }
  .pure-checkbox.selected-item {
    background: #e4f3f8;
  }
  .pure-checkbox .flag {
    width: 20px;
    border: 1px solid #efefef;
    margin-right: 10px;
  }
}

.clear-all {
  right: 32px !important;
  width: 10px !important;
  margin-top: -1px;
}

/* Offers */
.more-text:not(.show) {
  position: relative;
  max-height: 92px;
  overflow: hidden;
  &::before {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 87.5%);
    content: '';
    display: block;
    height: 46px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.more-text:not(.show) ~ .view-more {
  margin-top: -20px;
}

.link-code-box {
  border: 1px solid $primary;
  display: block;
  position: relative;
  .form-control {
    border: none !important;
    resize: none;
  }
  .nav {
    background-color: #eeeeee;
    font-family: $font-family-dm-sans;
    padding: 0.5rem 0.75rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .nav-item {
      margin-right: 1rem;
      .nav-link {
        color: $primary-light;
        background-color: transparent;
        padding: 0px;
        &.active,
        &:hover {
          color: $primary !important;
          background-color: transparent;
        }
      }
    }
  }
  .tab-content {
    padding-bottom: 37px;
  }
}

.steps {
  li {
    padding: 0 25px;
    text-align: center;
    position: relative;
    &::before,
    &::after {
      content: '';
      border-bottom: 1px solid #3d89a8;
      position: absolute;
      top: 15px;
      width: 100%;
      display: block;
      transform: translateX(50%);
      right: 0;
    }
    &::before {
      right: auto;
      left: 0;
      transform: translateX(-50%);
    }
    &:first-child::before,
    &:last-child:after {
      display: none;
    }
    .number {
      font-size: 1.125rem;
      color: $primary;
      width: 30px;
      height: 30px;
      background-color: $white;
      border: 1px solid $primary;
      display: inline-block;
      text-align: center;
      line-height: 28px;
      border-radius: 100%;
      font-family: $font-family-dm-sans;
      position: relative;
      z-index: 1;
    }
    &.active .number {
      background-color: $primary;
      color: $white;
    }
    label {
      display: block;
      margin-top: 5px;
    }
  }
}

.product-feed-tags {
  position: relative;
  &::before {
    content: '';
    border-bottom: 1px solid $light;
    position: absolute;
    left: calc(41.666667% - 15px);
    right: calc(41.666667% - 15px);
    top: 20px;
  }
  .card.tag-card {
    margin-bottom: 20px;
    .card-body {
      padding: 8px 15px 9px;
    }
    &.editable .card-body {
      padding: 2px 0px 2px;
      .ng-select .ng-select-container {
        border: none !important;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
        .ng-placeholder {
          font-size: 14px;
        }
        .ng-value-container .ng-input > input {
          padding-left: 15px;
        }
      }
    }
  }
}

.filter-tab.nav-pills {
  &::before {
    content: 'Display:';
    font-family: $font-family-dm-sans;
    margin-right: 0.5rem !important;
    line-height: 27px;
  }
  .nav-link {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 0px;
    margin-right: -1px;
    padding: 2px 16px;
    text-transform: uppercase;
    &.active,
    &:hover {
      background-color: $primary;
      color: $white !important;
    }
  }
}

/* Reports */
.dropdown .reorder-dropdown {
  li {
    margin-bottom: 10px;
  }
  .control.control-outline {
    padding-right: 30px;
    padding-left: 0px;
    color: $primary !important;
    .control__indicator {
      left: auto;
      right: 0;
    }
  }
  .order-list {
    .order-icon {
      cursor: move;
    }
    .control.control-outline {
      padding-left: 10px;
    }
  }
  .select-list {
    &::before {
      content: '';
      display: block;
      border-top: 1px solid $primary-light;
      margin: 15px -25px;
    }
  }
}

.mdc-image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  .mdc-image-list__item {
    margin-bottom: 16px;
    break-inside: avoid-column;
    list-style-type: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    padding: 10px;
  }
  &.mdc-image-list--masonry {
    display: block;
  }
  &.masonry-image-list {
    column-count: 3;
    column-gap: 16px;
  }
}

.field-underline {
  border-bottom: 1px solid $primary !important;
}
