/*****************************************************************************
    Variables
******************************************************************************/

$default: #617182;
$secondary: #5a6268;
$red: #ff0026;
$primary: #6DBCDB;
$primary-light: #6dbcdb;
$black: #2d3e50;
$accent: #e6464d;
$danger: #ff5c75;
$light: #d6d9dc;
$grey: #c4c4c4;
$white: #ffffff;
$muted: #839bb3;
$dark: #2d3e50;
