/* ********************************************************************************************
    Responsive
*********************************************************************************************** */

@media only screen and (min-width: 992px) {
  // .responsive-accordion {
  //   &.accordion > .card {
  //     margin-bottom: 0;
  //     box-shadow: none;
  //     background-color: transparent;
  //     border-radius: 0px !important;
  //     > .card-header {
  //       display: none;
  //     }
  //     .collapse {
  //       display: block !important;
  //       > .card-body {
  //         padding: 0px;
  //       }
  //     }
  //   }
  // }
}
@media only screen and (max-width: 992px) {
  .top-toolbar {
    height: 4.0625rem;
    max-height: 4.0625rem;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav {
    height: 4.0625rem;
    line-height: 4.2;
  }
  .top-toolbar .navbar-nav .nav-item > a {
    min-width: 4.0625rem;
  }
  .top-toolbar.navbar-mobile.navbar-tablet {
    z-index: 1001;
  }
  .site-logo li {
    height: 65px;
  }
  .top-toolbar .navbar-nav .nav-item,
  .top-toolbar .navbar-nav .nav-item > a,
  .top-toolbar.navbar-desktop .navbar-nav {
    height: 4.0625rem;
  }
  .top-toolbar .navbar-nav .nav-item,
  .top-toolbar .navbar-nav .nav-item > a,
  .top-toolbar .navbar-nav > li > a {
    max-height: 4.0625rem;
  }
  .logo-custom {
    line-height: 4.6;
  }
  body .collapse-menu {
    display: none;
  }
  body.mini-sidebar .sidebar-left .sidebar-content {
    width: 300px !important;
  }
  body.mini-sidebar .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a span {
    display: inline !important;
  }
  body.mini-sidebar .sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
    max-width: none !important;
  }
  body.mini-sidebar .sidebar-left .sidebar-content .brand-text,
  body.mini-sidebar .sidebar-left .sidebar-content .btn-menu,
  body.mini-sidebar .sidebar-left .sidebar-content .has-arrow::after,
  body.mini-sidebar .sidebar-left .sidebar-content .main-menu li a span,
  body.mini-sidebar .sidebar-left .sidebar-content .main-menu li.sidebar-header span {
    opacity: 1;
  }
  .nav.metismenu .dropdown .dropdown-menu.dropdown-menu-right {
    left: 0px !important;
    position: relative !important;
    right: 15px !important;
    width: 100%;
    box-shadow: none;
    padding: 0px 0 0 50px;
  }
  body.mini-sidebar #app .content-wrapper {
    padding-left: 0px;
  }
  body.layout-fixed .content-wrapper .content {
    padding-top: 65px;
  }
  .settings-menu.vertical-tab > .nav .nav-item:last-child a.nav-link {
    border-bottom: 10px solid #3d89a8 !important;
  }

  .settings-menu.vertical-tab > .nav a.nav-link.custom-bottom-line {
    border-bottom: 10px solid #3d89a8 !important;
  }
}
@media only screen and (max-width: 991px) {
  // .responsive-accordion {
  //   &.accordion > .card {
  //     margin-bottom: 0;
  //     box-shadow: none;
  //     background-color: transparent;
  //     border-radius: 0px !important;
  //     .collapse > .card-body {
  //       padding: 0px;
  //     }
  //     > .card-header {
  //       display: block;
  //       padding: 17px 15px 17px 30px !important;
  //       position: relative;
  //       border-radius: 0px !important;
  //       border-bottom: 1px solid $light !important;
  //       font-weight: normal;
  //       .btn-link {
  //         color: $primary !important;
  //         font-size: 18px !important;
  //         font-family: $font-family-cabin;
  //         padding: 15px 15px 15px 30px !important;
  //         text-transform: capitalize;
  //       }
  //     }
  //   }
  // }
  body.layout-fixed .content-wrapper .content {
    padding-top: 130px !important;
  }
  .top-toolbar.navbar-desktop {
    top: 65px;
  }
  .top-toolbar.navbar-desktop {
    .site-logo.nav-left,
    .navbar-nav.nav-right {
      display: none;
    }
  }
  .breadcrumb-position {
    // display: none !important;
    position: relative;
    left: 0;
    .breadcrumb-wrapper {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .page-header {
    padding: 30px 25px 0;
  }
  .page-content {
    padding: 25px;
  }
  .page-header h1 {
    font-size: 22px;
  }
  .font-size-45 {
    font-size: 2rem !important;
  }
  .font-size-45.mt-4 {
    margin-top: 16px !important;
  }
  .font-size-45 {
    font-size: 2rem !important;
  }
  .card {
    margin-bottom: 1.125rem;
  }
  .chartjs-render-monitor {
    height: 300px !important;
  }
  .tab-content .card {
    padding: 20px;
  }
  .btn {
    font-size: 14px;
  }
  .title {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-b-35 {
    margin-bottom: 20px !important;
  }
  .settings-menu a {
    padding: 13px 15px 13px 20px !important;
    font-size: 16px !important;
  }
  .form-group {
    margin-bottom: 16px;
  }

  h3,
  h4 {
    font-size: 16px !important;
  }
  label {
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    font-size: 13px;
  }
  ::-moz-placeholder {
    font-size: 13px;
  }
  :-ms-input-placeholder {
    font-size: 13px;
  }
  :-moz-placeholder {
    font-size: 13px;
  }
  .ng-placeholder {
    font-size: 13px !important;
  }
  .ng-select .ng-select-container {
    padding: 0.375rem 0.75rem 0.3rem 0rem;
  }
  .tab-content .card .card-header {
    padding-right: 0px;
    margin-bottom: 15px;
  }
  .tab-content .card .card-footer {
    padding: 20px 0px 0px 0px;
  }
  .settings-menu.vertical-tab > .tab-content {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .input-group.search-group {
    width: 180px !important;
  }
  .card-header .mr-auto,
  .card-header .m-r-10,
  .card-header .m-r-20,
  .card-header .m-r-0 {
    margin-bottom: 10px;
  }

  .filter .mr-auto,
  .filter .m-r-10,
  .filter .m-r-20,
  .filter .m-r-0 {
    margin-bottom: 10px;
  }
  .modal {
    .modal-header {
      padding: 30px 20px 10px 20px;
    }
    .modal-body {
      padding: 20px 20px 0px 20px;
    }
    .modal-footer {
      padding: 15px 20px;
      border: 0px;
    }
  }
  .noti-list li {
    margin-bottom: 20px;
  }
  .card.tag-card .tag-img {
    margin-right: 20px;
  }
  .settings-menu.vertical-tab > .nav a.nav-link {
    padding: 13px 15px 13px 20px !important;
    font-size: 16px !important;
  }
  .dropdown .dropdown-menu,
  .dropleft .dropdown-menu,
  .dropright .dropdown-menu,
  .dropup .dropdown-menu,
  .input-group .dropdown-menu {
    padding: 15px 14px;
    width: 220px;
  }
  .table .ng-select {
    width: 130px;
  }
  .display-4 {
    font-size: 2.2rem;
  }
  .user-avatar .user-img {
    height: 40px;
    width: 40px;
    margin-left: 12px;
  }
  .dropdown .filter-dropdown {
    padding: 15px !important;
    width: 320px !important;
  }

  .filter-dropdown .log-arrow-up {
    left: 79%;
    top: -1px;
  }

  .term-text-area {
    width: 100% !important;
  }

  .filter-tab .nav-pills .nav-link {
    padding: 10px 15px 10px 15px !important;
    font-size: 15px !important;
    // text-transform: uppercase;
  }
  .filter-tab .nav-pills .nav-item {
    width: 100%;
  }

  .justify-content-start.nav.nav-pills {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .media {
    flex-wrap: wrap !important;
  }
  .pagination {
    flex-wrap: wrap !important;
    margin-left: -13px !important;
    margin-top: 10px !important;
  }
  .page-header {
    padding: 30px 15px 0;
  }
  .login-box-footer {
    font-size: 12px;
  }
  .login-box,
  .register-box {
    margin: 5%;
  }
  .login-logo,
  .register-logo {
    padding-top: 20px;
  }
  .login-box-body,
  .register-box-body {
    padding: 25px 20px;
  }

  .filter-responsive {
    & > div:not(.dropdown):not(.button) {
      width: 100%;
      white-space: nowrap;
      .ng-select,
      .custom-class {
        width: 100% !important;
      }
      &.m-r-20 {
        margin-right: 0px !important;
      }
    }
  }
  .search-group-wrap .ng-select {
    width: 100% !important;
  }
  .search-group-wrap,
  .input-group.search-group {
    width: 100% !important;
  }
  .filter {
    .dropdown {
      position: initial;
      .filter-dropdown.dropdown-menu {
        left: 15px !important;
        right: 15px !important;
        width: auto !important;
        top: auto !important;
        .log-arrow-up {
          display: none;
        }
      }
    }
  }
  .mdc-image-list.masonry-image-list {
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
  .mdc-image-list .mdc-image-list__item {
    margin-bottom: 10px;
  }
  .chartjs-render-monitor {
    height: 200px !important;
  }
}

@media only screen and (max-width: 380px) {
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a i {
    line-height: 2.3;
  }
  .logo-custom img {
    width: 150px;
  }
  .login-box-body,
  .register-box-body {
    padding: 25px 15px;
  }
  .login-box-footer span a img {
    margin-left: 0px !important;
  }
  .mdc-image-list.masonry-image-list {
    -moz-column-count: 1;
    column-count: 1;
    -moz-column-gap: 0px;
    column-gap: 0px;
  }
}
